import React, { useState } from "react";

const CardTestimoni = ({ data, clicks }) => (
  <div className="parent-card-testimoni-subs" key={data?.id}>
    <img
      src={data?.imgUrl}
      className="img-testimoni-subs"
      alt="img-testimoni"
    />
    <div className="section-testimoni-subs">
      <div>
        <p className="text-name-testimoni-subs">
          <span className="text-tes-subs-desktop">{data?.name}</span>
          <span className="text-tes-subs-mobile">
            {data?.name.length > 15
              ? data?.name?.slice(0, 15) + "..."
              : data?.name}
          </span>
        </p>
        <div className="wraps-about-tes">
          <p className="text-about-testimoni-subs">{data?.about}</p>
        </div>
        <div className="wraps-about-tes-mob">
          <p className="text-about-testimoni-subs">
            {data?.about.length > 20
              ? data.about.slice(0, 20) + "..."
              : data.about}
          </p>
        </div>
        <div className="wrapper-content-testimoni-subs">
          <p className="text-content-testimoni-subs">
            {data?.testimoni.length > 85
              ? data?.testimoni.slice(0, 85) + "..."
              : data?.testimoni}
          </p>
        </div>
      </div>
      <a className="link-testimoni-subs" onClick={() => clicks(data?.id)}>
        Selengkapnya
      </a>
    </div>
  </div>
);

const data = [
  {
    id: 1,
    name: "Karimashita Hadini, S.Ikom",
    about: "Narasumber Fammi, Fulltime Home Educator and Partime MC & Speaker",
    imgUrl: "https://i.ibb.co/3fyS1Vb/Mask-Group-1.png",
    testimoni:
      "Mengilhami kebaikan yang Allah berikan melalui pandemi salah satu nya adalah dengan mendekatkan diri dengan ilmu. Keterbatasan gerak, membuat kita berinovasi untuk banyak belajar melalui teknologi. <br/> <br/>Bagi saya, bisa terus belajar dan berbagi ilmu melampaui jarak dan waktu adalah suatu berkah Allah yg luarbiasa. Fammi sangat paham bagaimana cara untuk menghadirkan kemudahan mencari dan mengalirkan ilmu dalam satu wadah yang lengkap. <br/><br/>Saya bangga menjadi bagian dari fammi. Semoga Fammi akan terus mampu mengalirkan banyak kebaikan bagi keluarga indonesia dimanapun berada.",
  },
  {
    id: 2,
    name: "Aisya Yuhanida Noor, M.Psi",
    about: "Narasumber Fammi, Psikolog, Professional Coach dan Penulis Buku",
    imgUrl: "https://i.ibb.co/sqfZfnx/Mask-Group-2.png",
    testimoni:
      "Sebagai orang tua kita tahu kalau menyengaja belajar dan meluangkan waktu untuk belajar itu wajib. Namun pada prakteknya, menemukan kesesuaian waktu belajar dengan jadwal kelas yang ada, dan menemukan materi serta metode belajar yang cocok bukan perkara mudah. <br/>Fammi original series menawarkan solusi atas persoalan ini. Orang tua bisa memilih kelas yang paling dibutuhkan (materi dan pemateri) dan menyesuaikan jadwal belajarnya masing-masing. <br/>  Kapanpun merasa perlu, pengulangan bisa dilakukan sesuai kecepatan dan keluangan waktu belajar masing-masing. Alhamdulillah, tidak ada lagi kendala untuk bisa terus belajar dan mengupgrade diri.",
  },
  {
    id: 3,
    name: "Nilam Qisthia",
    about: "Orangtua Pembelajar di Fammi",
    imgUrl: "https://i.ibb.co/dgdyJ86/image-13.png",
    testimoni:
      "Sangat membantu untuk para bunda mendapatkan gambaran serta langkah langkah untuk memahami emosi anak sehingga ketika anak sedang merasakan emosi negatif, justru dapat memperkuat hubungan ibu dan anak semakin baik",
  },
  {
    id: 4,
    name: "Reisha Ranty",
    about: "Orangtua Pembelajar di Fammi",
    imgUrl: "https://i.ibb.co/S3XDyPT/Mask-Group-3.png",
    testimoni:
      "Baru ini nemu aplikasi yang menyediakan kelas kelas online Ilmu Parenting yang temanya sesuai dan sangat bagus Aplikasi sederhana tapi user friendly, Keren!",
  },
  {
    id: 5,
    name: "Rani Nurhayati",
    about: "Orangtua Pembelajar di Fammi",
    imgUrl: "  https://i.ibb.co/z7qMc6W/Mask-Group-4.png",
    testimoni:
      "Aplikasi yg sangat bermanfaat. Saya sangat senang dgn aplikasi ini, dengan open kelas bisa di akses kapanpun dan bisa d akses selamanya dapat memudahkan saya dalam belajar dgn narasumber yg bagus2. Terimakasih fammi 😍",
  },
];

function TestimoniSection({ clicks }) {
  const [indexed, setIndexed] = useState(0);
  return (
    <>
      <div className="container-subscription" id="testimoni-subscription">
        <div className="head-testimoni-subs">
          <p className="text-title-testimoni-subs">
            Mengapa Perlu Belajar di Fammi?
          </p>
          <div className="parent-button-testimoni-subs">
            <button
              onClick={() => {
                if (indexed <= 0) {
                  setIndexed(0);
                } else {
                  setIndexed(indexed - 1);
                }
              }}
              className="btn-arrow-testimoni"
            >
              <img src="/assets/images/arrow-left.svg" alt="arrow-left" />
            </button>
            <button
              onClick={() => {
                if (indexed < Math.ceil(data?.length / 3)) {
                  setIndexed(indexed + 1);
                }
              }}
              className="btn-arrow-testimoni"
            >
              <img src="/assets/images/arrow-right.svg" alt="arrow-right" />
            </button>
          </div>
        </div>

        <div className="wrapper-testimoni-subs">
          <div
            style={{
              marginLeft: indexed !== 0 ? indexed * -400 : 0,
              transition: "all 0.5s ease-in-out",
            }}
          />
          {data?.map((items) => (
            <CardTestimoni data={items} key={items.id} clicks={clicks} />
          ))}
          <div className="dummy-box-tes" />
        </div>
      </div>
    </>
  );
}
export default TestimoniSection;
