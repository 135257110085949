import React from "react";
import "./LoginPopup.css";

export const LoginPopup = ({ onLogin, onRegister, onCancel }) => {
  return (
    <div>
      <div className="otb-login">
        <button className="login-pijar" onClick={onLogin}>
          <img src="/assets/images/login.svg" alt="login" />
          <p>Masuk ke akun Pijar Belajar</p>
        </button>
        <button className="register" onClick={onRegister}>
          <img src="/assets/images/user-plus.svg" alt="reg" />
          <p>Belum punya akun? Daftar</p>
        </button>
        <div className="divider" />
        <button className="register" onClick={onCancel}>
          <img src="/assets/images/e_cross.svg" alt="cross" />
          <p>Nanti Aja</p>
        </button>
      </div>
    </div>
  );
};
