import React, { useState } from "react";
import { withRouter } from "react-router";
import "./CardLoginPartner.css";

function CardLoginPartner(props) {
  const {
    partner,
    formData,
    setFormData,
    handleLogin,
    loginError,
    handleGoogleAuth,
    handleFacebookAuth,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [validMail, setValidMail] = useState(false);
  return (
    <React.Fragment>
      <div id="logins" className="card-register-company">
        <div className="parent-sidesect">
          <div className="menuz">
            <img
              src={"https://fammi.ly/uploads/schools/images/" + partner.cover}
              alt={partner?.name}
            />
            <div>
              <h1>{partner?.name}</h1>

              <p>
                <b>PERHATIAN!</b> Anda akan masuk ke Fammi sebagai pengguna dari<br/>
                {partner?.name}
              </p>
            </div>
          </div>
        </div>
        <div className="parent-formsect">
          <p className="title-register-company">Masuk</p>
          <div className="parent-form-company">
            <div className="list-register-company">
              <span>Email</span>
              <input
                value={formData?.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                onFocus={() => setValidMail(false)}
                style={{
                  borderColor: loginError
                    ? "#E85050"
                    : validMail &&
                      !new RegExp(
                        /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
                      ).test(formData?.email) &&
                      "#E85050",
                }}
                placeholder="Masukkan Email"
                className="input-register-company"
              />
              {loginError ? (
                <p
                  style={{
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: 12,
                    lineHeight: "14px",
                    color: "#E85050",
                    margin: "unset",
                    marginTop: 8,
                  }}
                >
                  Email atau password tidak sesuai
                </p>
              ) : (
                validMail &&
                !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
                  formData?.email
                ) && (
                  <p
                    style={{
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: 12,
                      lineHeight: "14px",
                      color: "#E85050",
                      margin: "unset",
                      marginTop: 8,
                    }}
                  >
                    Format email tidak sesuai
                  </p>
                )
              )}
            </div>
            <div className="list-register-company">
              <span>Password</span>
              <input
                style={{ borderColor: loginError && "#E85050" }}
                value={formData?.password}
                onFocus={() => setValidMail(true)}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                placeholder="Masukkan Password"
                className="input-register-company"
                type={showPassword ? "text" : "password"}
              />
              <div className="parent-eye-ind">
                <img
                  style={{ opacity: showPassword ? 1 : 0.5 }}
                  src="/assets/images/eye-off.svg"
                  alt="eye-indicator"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
              {loginError && (
                <p
                  style={{
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: 12,
                    lineHeight: "14px",
                    color: "#E85050",
                    margin: "unset",
                    marginTop: 8,
                  }}
                >
                  Email atau password tidak sesuai
                </p>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <p className="kebijakan-register-company">
              <b> Lupa Password?</b>
            </p>
          </div>
          <button
            disabled={
              !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
                formData?.email
              )
                ? true
                : formData.password.length < 1
                ? true
                : false
            }
            style={{
              background: !new RegExp(
                /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
              ).test(formData?.email)
                ? "#999"
                : formData.password.length < 1 && "#999",
            }}
            onClick={handleLogin}
            className="btn-register-company"
          >
            Masuk
          </button>

          <div className="or-section-company">
            <div className="divider-or-company" />
            <p className="text-or-company">atau masuk dengan</p>
            <div className="divider-or-company" />
          </div>
          <div className="parent-btn-social">
            <button className="btn-social-auth" onClick={handleGoogleAuth}>
              <img src="/assets/images/google.svg" alt="google" />
              <p>Google</p>
            </button>
            <button className="btn-social-auth" onClick={handleFacebookAuth}>
              <img src="/assets/images/facebook.svg" alt="google" />
              <p>Facebook</p>
            </button>
          </div>
          <p className="text-belum-acc">Belum Punya Akun?</p>
          <button
            onClick={() =>
              props.history.push("/register" + "/" + partner?.name)
            }
            className="btn-daftar-partner"
          >
            Daftar
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
export default withRouter(CardLoginPartner);
