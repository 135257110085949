import moment from "moment";
import React from "react";
import WAIcon from "../../images/wa-white.svg";
import "./PremiumDialog.css";

export const PremiumDialog = ({ onClose, endDate, expired, history, eventCode }) => {
  return (
    <div id="premium-dialog-box">
      <div id="premium-dialog">
        <div className="pd-head">
          <img src="/assets/images/premium_badge.svg" alt="premium" />
          {
            expired &&
            <h1>Hai, Member Premium kamu sudah habis</h1>
          }
          {
            !expired &&
            <h1>Hai, kamu menemukan Fitur Premium Fammi</h1>
          }
        </div>
        <div className="pd-info">
          {
            expired &&
            <p>
              Status kamu sebagai member premium Fammi sudah habis.
            </p>
          }
          {
            !expired &&
            <p>
              Saat ini sekolah kamu sudah tidak berlangganan fitur Fammi Premium ~
              habis per tanggal {moment(endDate).format("DD MMM YYYY")}
            </p>
          }
        </div>
        <div className="pd-features">
          {
            !expired &&
            <React.Fragment>
              <p>
                Berikut ini fitur Fammi Premium yang bisa kamu dapatkan jika sekolah
                kamu berlangganan:
              </p>
              <div style={{ marginTop: 16 }}>
                <FeatureList label="Raport Digital" />
                <FeatureList label="Modul Belajar & Praktek" />
                <FeatureList label="Tanya Ahli Setiap Hari" />
                <FeatureList label="Akses Ribuan Audio Materi Tanpa Batas" />
              </div>
            </React.Fragment>
          }
          {
            expired &&
            <React.Fragment>
              <p>
                Berikut ini fitur Fammi Premium yang bisa kamu dapatkan jika kamu melakukan pembelian lagi:
              </p>
              <div style={{ marginTop: 16 }}>
                <FeatureList label="Tanya Ahlinya Fammi" />
                <FeatureList label="Modul Panduan Materi Praktis" />
                <FeatureList label="Ribuan Inspirasi Parenting" />
              </div>
            </React.Fragment>
          }
        </div>
        {
          !expired &&
          <div className="pd-footer">
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href="https://wa.me/6282121006788" target="_blank">
              <button className="pd-call-admin">
                <img src={WAIcon} alt="wa" />
                Hubungi Admin Fammi
              </button>
            </a>
            <button className="pd-close" onClick={onClose}>
              <img src="/assets/images/e_cross.svg" alt="cross" />
              Nanti Aja
            </button>
          </div>
        }
        {
          expired &&
          <div className="pd-footer">
            <button 
              className="pd-call-admin" 
              style={{justifyContent: "center"}}
              onClick={() => { 
                history.push(`/e/${eventCode}/payment-package`);
              }}
            >
              <img src={`/assets/images/shield_check.svg`} alt="shield-check" style={{marginRight: "6px"}}/>
              Beli Lagi
            </button>
            <button className="pd-close" style={{justifyContent: "center"}} onClick={onClose}>
              Tutup
            </button>
          </div>
        }
      </div>
    </div>
  );
};

const FeatureList = ({ label }) => {
  return (
    <div className="pd-feature">
      <img
        src="/assets/images/checked-colored.svg"
        alt="check"
        style={{ marginRight: 12 }}
      />
      <p>{label}</p>
    </div>
  );
};
