import React, { useState } from "react";
import { withRouter } from "react-router";
import "./Carousels.css";

function Carousels(props) {
  const { children, showDots, title, numberOfShow, path, id, dataLength } =
    props;
  const [indexed, setIndexed] = useState(0);
  const totalDots = Array.from(
    Array(
      Math.ceil(
        (dataLength ? dataLength : children?.length) > 0 &&
          (dataLength ? dataLength : children?.length) /
            (numberOfShow ? numberOfShow : 3)
      )
    ).keys()
  );
  return (
    <div className="parent-carousels">
      <div className="parent-heads-carousels">
        <p className="text-title-carousels">{title}</p>
        <p
          onClick={() => props.history.push(path)}
          className="text-link-carousels"
        >
          Lihat Semua
        </p>
      </div>
      <div id={id} className="wrapper-carousels">
        <div
          style={{
            marginLeft: indexed !== 0 ? indexed * -1194 : 0,
            transition: "all 0.5s ease-in-out",
          }}
        />
        {children}
        <div className="dummy-carousels-box" />
      </div>
      <div className="parent-indicator-carousels">
        {showDots &&
          indexed <
            Math.ceil(
              (dataLength ? dataLength : children?.length) /
                (numberOfShow ? numberOfShow : 3)
            ) && (
            <div className="parent-dots-carousels">
              {totalDots.map((_item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    if (indexed !== index) {
                      setIndexed(index);
                    }
                  }}
                  className={
                    indexed === index
                      ? "dots-carousels-active"
                      : "dots-carousels"
                  }
                />
              ))}
            </div>
          )}
        <div className="parent-btn-carousels">
          <button
            onClick={() => {
              if (indexed <= 0) {
                setIndexed(0);
              } else {
                setIndexed(indexed - 1);
              }
            }}
            style={{ cursor: indexed === 0 && "default" }}
            className="btn-arrow-carousels"
          >
            <img src="/assets/images/arrow-left.svg" alt="arrow-left" />
          </button>
          <button
            onClick={() => {
              if (indexed < totalDots.length - 1) {
                setIndexed(indexed + 1);
              }
            }}
            style={{
              cursor: indexed === totalDots.length - 1 && "default",
            }}
            className="btn-arrow-carousels"
          >
            <img src="/assets/images/arrow-right.svg" alt="arrow-right" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Carousels);
