import React, {Component} from "react";

class Loading extends Component {
    render() {
        return (
            <div className="wrap-load" style={{zIndex:'99'}}>
                        <div className="content-load">
                            <div className="loader">&nbsp;</div>
                            <p>Harap Tunggu</p>
                        </div>
                    </div>
        )
    }
}

export default Loading