import React from "react";
import RegisterForm from "../Form/RegisterForm";
import Images from "../ImagesComponent/Images";
import PinInput from "react-pin-input";
import "../css/custom-style.css";
import "../css/AuthNew.css";
import arrow from "../images/arrow-back.svg";
import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";

export const handleGoogleAuth = async () => {
  var provider = new firebase.auth.GoogleAuthProvider();

  let googleResult = false;
   await firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (res) => {
        if (res) {
          const payload = JSON.stringify({
            provider: "google",
            email: res?.user?.email,
            name: res?.user?.displayName,
            token: res?.credential.accessToken,
          });
          const url = process.env.REACT_APP_FETCH_URL + "/socmed_auth";

          await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: payload,
          })
            .then((result) => result.json())
            .then((result) => {
              if (result && result?.isAuthenticate) {
                localStorage.clear();
                localStorage.setItem("token", result.token);
                localStorage.setItem("name", result.name);
                localStorage.removeItem("answers");
                googleResult = true;
              }
            });
        }
      })
      .catch((error) => {
        googleResult = false;
        alert(error);
      });

      return googleResult;
}

export const handleFacebookAuth = async (setState) => {
  var provider = new firebase.auth.FacebookAuthProvider();

  let facebookResult = false;
   await firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (res) => {
        if (res) {
          const payload = JSON.stringify({
            provider: "facebook",
            email: res?.user?.email,
            name: res?.user?.displayName,
            token: res?.user?.refreshToken,
          });
          const url = process.env.REACT_APP_FETCH_URL + "/socmed_auth";

          await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: payload,
          })
            .then((result) => result.json())
            .then((result) => {
              if (result && result?.isAuthenticate) {
                localStorage.clear();
                localStorage.setItem("token", result.token);
                localStorage.setItem("name", result.name);
                facebookResult = true;
              }
            });
        }
      })
      .catch((error) => {
        facebookResult = false;
        alert(error);
      });

  return facebookResult;
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otpSent: null,
      otpMessage: null,
      theSecond: null,
      profile: null,
      theSecond: 60,
      loading: false,
      password: "",
      type: props.type,
      profile: {},
      passwordType: true,
      email: "",
      loginError: false,
      emailError: false,
    };
  }
  // handleGoogleAuth() {
  //   var provider = new firebase.auth.GoogleAuthProvider();
  //   firebase
  //     .auth()
  //     .signInWithPopup(provider)
  //     .then((res) => {
  //       if (res) {
  //         const payload = JSON.stringify({
  //           provider: "google",
  //           email: res?.user?.email,
  //           name: res?.user?.displayName,
  //           token: res?.credential.accessToken,
  //         });
  //         const url = process.env.REACT_APP_FETCH_URL + "/socmed_auth";

  //         fetch(url, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: payload,
  //         })
  //           .then((result) => result.json())
  //           .then((result) => {
  //             if (result && result?.isAuthenticate) {
  //               localStorage.clear();
  //               localStorage.setItem("token", result.token);
  //               localStorage.setItem("name", result.name);
  //               localStorage.removeItem("answers");
  //               this.setState({ lengkapiVisible: false, loginSuccess: true });
  //               window.location.href = "/";
  //             }
  //           });
  //       }
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // }

  // handleFacebookAuth() {
  //   var provider = new firebase.auth.FacebookAuthProvider();
  //   firebase
  //     .auth()
  //     .signInWithPopup(provider)
  //     .then((res) => {
  //       if (res) {
  //         const payload = JSON.stringify({
  //           provider: "facebook",
  //           email: res?.user?.email,
  //           name: res?.user?.displayName,
  //           token: res?.user?.refreshToken,
  //         });
  //         const url = process.env.REACT_APP_FETCH_URL + "/socmed_auth";

  //         fetch(url, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: payload,
  //         })
  //           .then((result) => result.json())
  //           .then((result) => {
  //             if (result && result?.isAuthenticate) {
  //               localStorage.clear();
  //               localStorage.setItem("token", result.token);
  //               localStorage.setItem("name", result.name);
  //               this.setState({ lengkapiVisible: false, loginSuccess: true });
  //               window.location.href = "/";
  //             }
  //           });
  //       }
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // }

  decreaseTimer = () => {
    let time = this.state.theSecond;
    // console.log(time);
    if (time > -1) {
      setTimeout(() => {
        time -= 1;
        this.setState({
          theSecond: time,
        });

        this.decreaseTimer();
      }, 1000);
    }
  };

  hideThepassword() {
    this.setState({
      passwordType: true,
    });
  }

  revealThePassword = () => {
    const { passwordType } = this.state;
    this.setState({
      passwordType: false,
    });

    setTimeout(() => {
      this.hideThepassword();
    }, 2000);
  };

  showWarning = () => {
    const { showWarning } = this.state;
    this.setState({
      showWarning: !showWarning,
    });
  };

  handlePassword = (e) => {
    const profile = this.state["profile"];
    profile.password = e.target.value;
    this.setState({
      profile: profile,
      password: e.target.value,
    });
  };

  handleEmailLogin = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleEmail = (e) => {
    const profile = this.state["profile"];
    profile.email = e.target.value;
    this.setState({
      profile: profile,
    });
  };

  handleLogin = async () => {
    const validMail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(this.state?.email);

    if (!validMail) {
      this.setState({ emailError: true });
    } else {
      const url = process.env.REACT_APP_FETCH_URL + "/login";
      const postedBody = JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      });
      this.setState({
        theSecond: 60,
        loading: true,
      });
      setTimeout(() => {
        this.decreaseTimer();
      }, 200);

      await axios
        .post(url, postedBody, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(
          (result) => {
            // console.log(result.data);
            if (result !== null && typeof result.data !== "undefined" && result.data.isAuthenticate) {
              localStorage.setItem("token", result.data.token);
              localStorage.setItem("name", result.data.name);
              this.setState({ lengkapiVisible: false, loginSuccess: true });
              window.location.href = "/";
            } else {
              // alert("Akun tidak ditemukan !");
              this.setState({
                loading: false,
                loginError: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  handleOTP = () => {
    const url = process.env.REACT_APP_FETCH_URL + "/sendotp";
    const postedBody = JSON.stringify({
      phone: this.state.email,
    });

    this.setState({
      theSecond: 60,
      loading: true,
    });
    setTimeout(() => {
      this.decreaseTimer();
    }, 200);

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: postedBody,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result && result.isAuthenticate) {
            localStorage.setItem("token", result.token);
            localStorage.setItem("name", result.name);
            this.setState({ lengkapiVisible: false, loginSuccess: true });
            window.location.href = "/";
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  setOtp = (e) => {
    this.setState({
      otp: e,
    });
  };

  checkOtp = () => {
    const url = process.env.REACT_APP_FETCH_URL + "/checkotp";
    const postedBody = JSON.stringify({
      otpCode: this.state.otp,
    });

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: postedBody,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result && result.isAuthenticate) {
            localStorage.setItem("token", result.token);
            localStorage.setItem("name", result.name);
            this.setState({ lengkapiVisible: false, loginSuccess: true });
            window.location.href = "/";
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  // componentDidMount() {
  //   setTimeout(() => {
  //     window.addIdOnBody("login");
  //   }, 500);
  //   return;
  // }

  render() {
    const {
      otpSent,
      otpMessage,
      theSecond,
      profile,
      email,
      loading,
      passwordType,
    } = this.state;

    return (
      <div id="signup-page" className="login-page">
        {loading && (
          <div className="wrap-load" style={{ zIndex: "99" }}>
            <div className="content-load">
              <div className="loader">&nbsp;</div>
              <p>Harap Tunggu</p>
            </div>
          </div>
        )}
        <div className="signup-table">
          <div id="bar-login" className="signup-middle">
            <div id="navigator-utama">
              <div id="menu-header">
                <div className="container menuutama">
                  <div className="row loginheader">
                    <div>
                      <a href="/">
                        <Images
                          id="fammi-logo-header"
                          src="images/fammi-logo.svg"
                          alt=""
                          style={{
                            width: "108px",
                            height: "32px",
                            marginLeft: 16,
                          }}
                        />
                      </a>
                      <div>
                        <a href="/">
                          <img
                            id="arrow-back-header"
                            src={arrow}
                            alt="wk"
                            style={{
                              paddingTop: 10,
                              paddingLeft: 21,
                            }}
                          />
                        </a>
                      </div>
                    </div>
                    <div
                      id="headerlogin"
                      style={{ fontFamily: "Rubik", fontWeight: "500" }}
                    >
                      <p>
                        <span>Belum mempunyai akun Fammi? </span>{" "}
                        <a href="/register">Daftar</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="formlogin">
              <div
                className="daftar-page"
                id="form-utama"
                style={{ borderRadius: "12px" }}
              >
                <div className="form-daftar-email text-left">
                  {!otpSent && (
                    <div className="wrap-login TESTING-APP">
                      <div className="show">
                        <h4>Masuk</h4>
                        <div className="form-groups">
                          <span>Email</span>
                          <input
                            style={{
                              borderColor: this.state?.loginError
                                ? "#E85050"
                                : this.state?.validMail &&
                                  !new RegExp(
                                    /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
                                  ).test(this.state?.email) &&
                                  "#E85050",
                            }}
                            placeholder="Masukkan email"
                            name="fname"
                            required
                            onChange={(e) => {
                              this.setState({
                                emailError: false,
                              });
                              this.handleEmailLogin(e);
                            }}
                          />
                          {this.state?.validMail &&
                            !new RegExp(
                              /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
                            ).test(this.state?.email) && (
                              <p
                                style={{
                                  fontFamily: "Lato",
                                  fontStyle: "normal",
                                  fontWeight: "normal",
                                  fontSize: 12,
                                  lineHeight: "14px",
                                  color: "#E85050",
                                  margin: "unset",
                                  marginTop: 8,
                                }}
                              >
                                Format email tidak sesuai
                              </p>
                            )}
                        </div>
                        <div className="form-groups rel-pos">
                          <span>Password</span>
                          <input
                            type={passwordType ? "password" : "text"}
                            placeholder="Masukkan password"
                            name="fname"
                            required
                            onChange={(e) => {
                              this.setState({
                                loginError: false,
                              });
                              this.handlePassword(e);
                            }}
                            onFocus={() => this.setState({ validMail: true })}
                            style={{
                              borderColor: this.state.emailError
                                ? "#E85050"
                                : this.state.loginError && "#E85050",
                            }}
                          />

                          <div
                            className="eye-password"
                            onClick={() => this.revealThePassword()}
                            style={{ pointer: "cursor" }}
                          >
                            <img
                              src="/images-delip/icon-mata.svg"
                              alt="arrow"
                              className={passwordType ? "" : "active-eye"}
                            />
                          </div>
                          <div className="forget-password-wrapper">
                            {this.state.loginError && (
                              <p
                                style={{
                                  fontSize: 12,
                                  marginTop: 8,
                                  marginBottom: -27,
                                  color: "#E85050",
                                }}
                              >
                                Email atau password tidak sesuai
                              </p>
                            )}
                            {/* {this.state?.emailError && (
                              <p
                                style={{
                                  fontFamily: "Lato",
                                  fontStyle: "normal",
                                  fontWeight: "normal",
                                  fontSize: 12,
                                  lineHeight: "14px",
                                  color: "#E85050",
                                  margin: "unset",
                                  marginTop: 8,
                                }}
                              >
                                Email atau password tidak sesuai
                              </p>
                            )} */}
                            <a
                              className="forget-password"
                              href="https://fammi.ly/forgot-password"
                            >
                              Lupa Password?
                            </a>
                          </div>
                          {this.state.email === "" ||
                          this.state.password === "" ||
                          (this.state.validMail &&
                            !new RegExp(
                              /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
                            ).test(this.state?.email)) ? (
                            <div
                              className={"fammibuttonalt colorbg6  col-12"}
                              style={{ color: "#FFF", cursor: "pointer" }}
                              id="bgabung"
                            >
                              Masuk
                            </div>
                          ) : (
                            <div
                              className={"fammibuttonalt colorbg2  col-12"}
                              style={{ color: "#FFF", cursor: "pointer" }}
                              onClick={this.handleLogin}
                              id="bgabung"
                            >
                              Masuk
                            </div>
                          )}
                        </div>
                        <div className="wrapper-or-social">
                          <div className="divide-or" />
                          <p className="text-or" style={{ fontSize: 12 }}>
                            atau masuk dengan
                          </p>
                          <div className="divide-or" />
                        </div>
                        <div
                          className="wrapper-or-social"
                          style={{ marginTop: 24 }}
                        >
                          <button
                            onClick={async () => {
                              const theGoogle = await handleGoogleAuth();
                              this.setState({ lengkapiVisible: false, loginSuccess: theGoogle });
                              if (theGoogle) {
                                const redirectUrl = localStorage.getItem("c892072");
                                if (redirectUrl) {
                                  window.location.href = redirectUrl;
                                  return;
                                }
                                window.location.href = "/";
                              }
                            }}
                            className="btn-socials"
                          >
                            <img src="/assets/images/google.svg" alt="google" />
                            <p className="text-socials">Google</p>
                          </button>
                          <button
                            onClick={async () => {
                              const theFacebbok = await handleFacebookAuth();
                              this.setState({ lengkapiVisible: false, loginSuccess: theFacebbok });
                              if(theFacebbok) {
                                const redirectUrl = localStorage.getItem("c892072");
                                if (redirectUrl) {
                                  window.location.href = redirectUrl;
                                  return;
                                }
                                window.location.href = "/";
                              }
                            }}
                            className="btn-socials"
                          >
                            <img
                              src="/assets/images/facebook.svg"
                              alt="google"
                            />
                            <p className="text-socials">Facebook</p>
                          </button>
                        </div>
                        <div className="form">
                          <p
                            className="goto-register"
                            style={{
                              fontWeight: "500 !important",
                              color: "#000",
                              marginBottom: 10,
                            }}
                          >
                            Belum Punya Akun?
                          </p>
                          <button
                            className="btn-daftar"
                            style={{
                              width: "100%",
                              maxWidth: "768px",
                              height: "41px",
                              display: "flex",
                              justifyContent: "center",
                              marginLeft: -1,
                              border: "1px solid #7D61D6",
                              backgroundColor: "transparent",
                            }}
                            onClick={() => {
                              window.location.href = "/register";
                            }}
                          >
                            <a
                              className="daftar-in-btn"
                              style={{
                                color: "#7D61D6",
                                display: "flex",
                                alignItems: "center",
                                margin: "unset",
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            >
                              Daftar
                            </a>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {otpSent && (
                    <div className="wrap-login">
                      <Images src="images/verified.svg" />
                      <h4 className="text-center">Masukkan Kode Verifikasi</h4>
                      <p className="otp-style">
                        Kode verifikasi telah dikirimkan melalui {otpMessage}
                      </p>
                      <h5>Kode verifikasi</h5>
                      <div
                        style={{
                          position: "relative",
                          margin: "24px auto 50px auto",
                          textAlign: "center",
                        }}
                      >
                        <PinInput
                          length={6}
                          type="numeric"
                          inputStyle={{
                            width: "31px",
                            height: "44px",
                            marginRight: "8px",
                            padding: "10px",
                            background: "#EAEAEA",
                            borderRadius: "6px",
                            border: "none",
                          }}
                          onComplete={(value, index) => this.setOtp(value)}
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-default btn-lg btn-block"
                        style={{
                          backgroundColor: "#7D61D6",
                          color: "#fff",
                          borderRadius: "12px",
                          marginLeft: "0px",
                        }}
                        onClick={this.checkOtp}
                      >
                        Verifikasi
                      </button>
                      {theSecond > -1 && (
                        <p
                          className="counting text-center"
                          style={{ marginTop: "24px" }}
                        >
                          Mohon menunggu <b>{theSecond} detik</b> untuk mengirim
                          ulang
                        </p>
                      )}

                      <p
                        className="counting text-center"
                        style={{
                          marginTop: "24px",
                          display: theSecond < 0 ? "block" : "none",
                        }}
                      >
                        Tidak menerima kode verifikasi?
                        <br />
                        <a
                          href="#"
                          style={{
                            color: "#7D61D6",
                            cursor: "pointer",
                            fontFamily: "Rubik",
                            fontWeight: "500",
                          }}
                          onClick={() => this.handleOTP()}
                        >
                          Kirim Ulang
                        </a>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
