import React from "react";

export const SpeakerSection = (props) => {
  const { speakerData, setAskQuestion, setOpenLogin, t } = props;
  return (
    <div className="speaker-section">
      <div style={{ maxWidth: "1170px", margin: "auto" }}>
        <h1>{t("ask-anytime")}</h1>
        <h2>{t("get-answer")}</h2>
        <div className="flex justify-center mt-9 mo-mt-6">
          <button
            className="bg-main rounded border-none text-white font-bold text-center btn-look mo-w-full"
            onClick={() => {
              if (localStorage.getItem("token") === null) {
                setOpenLogin(true);
                return;
              }
              setAskQuestion(true);
            }}
          >
            {t("send-inquiry")}
          </button>
        </div>
        <div className="flex justify-between flex-wrap w-auto mt-13">
          {speakerData.map((d) => (
            <div
              className="w-13-percent h-speaker flex flex-col items-center mb-9 mo-w-30-percent mo-mb-6"
              key={d}
            >
              <img
                src={d.photo}
                alt="img-speaker"
                className="rounded-full mb-4 mo-mb-2 h-img-speaker"
                style={{
                  maxWidth: "180px",
                  width: "100%",
                  maxHeight: "180px",
                  height: "auto",
                }}
              />
              <p>{d.name}</p>
            </div>
          ))}
          {[1, 2, 3, 4].map((d) => (
            <div className="w-13-percent" key={d}></div>
          ))}
        </div>
      </div>
    </div>
  );
};
