import React from "react";

export const AdvantageSection = (props) => {
  const { t } = props;
  const DATALIST = [
    {
      id: 0,
      name: "trusted",
      title: t("trusted"),
      description: t("supported-dozens"),
    },
    {
      id: 1,
      name: "friendly",
      title: "Friendly",
      description: t("issues-raised"),
    },
    {
      id: 2,
      name: "uniq",
      title: t("uniq"),
      description: t("programs-content"),
    },
    {
      id: 3,
      name: "konkret",
      title: t("concrete"),
      description: t("solutions-packaged"),
    },
    {
      id: 4,
      name: "helpful",
      title: "Helpful",
      description: t("providing-inspiration"),
    },
  ];
  return (
    <div className="advantage-section">
      <div style={{ maxWidth: "1170px", margin: "auto" }}>
        <div className="flex flex-col items-center">
          <h1>{t("why-parents")}</h1>
          <h1>{t("satisfied-with")}</h1>
        </div>
        <div className="mt-13 flex justify-between mo-flex-col mo-mt-6 mo-justify-start">
          {DATALIST.map((i) => (
            <div key={i.id} className="w-18-percent mo-w-full mo-mb-4">
              <div className="flex justify-center">
                <img
                  src={`/assets/new-home/${i.name}.svg`}
                  alt="icon-vec"
                  className="icon-vec"
                />
              </div>
              <div className="bg-white rounded-md p-8 border-card pt-16 h-card mo-bg-none">
                <h5>{i.title}</h5>
                <p>{i.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
