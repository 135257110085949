import React from "react";
import { withRouter } from "react-router";

function CardNewEpisode(props) {
  const { data, style, mode, push } = props;
  if (data) {
    return (
      <div onClick={push} className="parent-card-news" style={style}>
        <img src={data?.cover} alt="img-news" />
      </div>
    );
  } else {
    return (
      <div className="parent-card-news" style={style}>
        <img src="https://i.ibb.co/vXPBSKh/Rectangle-16.png" alt="img-news" />
      </div>
    );
  }
}

export default withRouter(CardNewEpisode);
