import axios from "axios";
import md5 from "md5";
import React from "react";
import FillQna from "../EventComponent/FillQna/FillQna";
import "./HomeQnaContainer.css";

export const HomeQnaContainer = ({
  profileRules,
  qnaFormStatus,
  onClose,
  title,
  isImportant,
  questionData
}) => {
  const token = localStorage.getItem("token");
  const [qna, setQna] = React.useState({});
  const [profile, setProfile] = React.useState({});
  const [profileMember, setProfileMember] = React.useState({});

  React.useEffect(() => {
    async function checkProfile() {
      await axios
        .get(process.env.REACT_APP_FETCH_URL + `/profile/${token}`)
        .then((res) => {
          const profileJson = res?.data[0]?.profileData
            ? JSON.parse(res?.data[0]?.profileData)
            : {};

          setProfileMember(profileJson);
          setProfile(profileJson);
        });
    }
    checkProfile();
  }, [token]);

  const sendQuestion = async () => {
    let isValid = true;

    if (qna?.question === "" || !qna?.question) {
      isValid = false;
    }

    if (!qna?.categories || !qna?.categories) {
      isValid = false;
    }

    if (qna?.categories && qna?.categories.join("") === "") {
      isValid = false;
    }

    if (!isValid) {
      onClose();
      return;
    }

    await axios
      .post(process.env.REACT_APP_FETCH_URL + "/questions", {
        questionId: md5(new Date().getTime()),
        categories: qna.categories?.join(",")+" ",
        memberId: localStorage.getItem("token"),
        scheduleId: null,
        question: qna.question,
        ...questionData
      })
      .then(() => {
        onClose();
        setQna({});
      });
  };

  return (
    <div className="home_ask_container">
      <div className="hac_content">
        <div className="hac_header">
          <h4>{title ? title : "Silakan isi pertanyaan di bawah ini"}</h4>
          <img
            src="/assets/images/e_close.svg"
            onClick={onClose}
            style={{ cursor: "pointer", width: 20, height: 20 }}
            alt="close-icon"
          />
        </div>
        <div className="hac_form">
          <FillQna
            setQna={setQna}
            qna={qna}
            profileRules={profileRules}
            qnaFormStatus={qnaFormStatus}
            profile={profile}
            profileMember={profileMember}
            setProfile={setProfile}
            isImportant={!isImportant}
          />
        </div>
        <div className="hac_button">
          <button
            className="button-submit-question"
            onClick={sendQuestion}
            style={{ margin: 0 }}
          >
            Kirim Pertanyaan
          </button>
        </div>
      </div>
    </div>
  );
};
