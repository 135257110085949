import React from 'react'
import LengkapiProfil from './LengkapiProfil'
import Images from '../ImagesComponent/Images'
import PinInput from "react-pin-input";
import { Redirect } from 'react-router-dom'

class RegisterForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lengkapiVisible: false,
            loginSuccess: false,
            regisSuccess: false,
            type: props.type,
            email: '',
            password: '',
            otpSent: false,
            otpMessage: '',
            theSecond: 60,
            profile: {},
            registerType: "mitra"
        }
    }

    handleEmailLogin = (e) => {
        this.setState({
            email: e.target.value
        });
    }
    handlePassword = (e) => {
        this.setState({
            password: e.target.value
        });
    }

    decreaseTimer = () => {
        let time = this.state.theSecond;
        if (time > -1) {
            setTimeout(() => {
                time -= 1;
                this.setState({
                    theSecond: time
                });

                this.decreaseTimer();
            }, 1000);
        }

    }

    handleOTP = () => {
        const url = process.env.REACT_APP_FETCH_URL + '/sendotp';
        const postedBody = JSON.stringify({
            phone: this.state.email
        });

        this.setState({
            theSecond: 60
        });
        this.decreaseTimer();

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: postedBody
        })
            .then(res => res.text())
            .then(
                (result) => {
                    if (result !== "false") {
                        this.setState({
                            otpSent: true,
                            otpMessage: result,
                        });
                    }
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    setOtp = (e) => {
        this.setState({
            otp: e
        });
    }

    checkOtp = () => {
        const url = process.env.REACT_APP_FETCH_URL + '/checkotp';
        const postedBody = JSON.stringify({
            otpCode: this.state.otp
        });

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: postedBody
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result && result.isAuthenticate) {
                        localStorage.setItem('token', result.token);
                        localStorage.setItem('name', result.name);
                        this.setState({ lengkapiVisible: false, loginSuccess: true });
                        window.location.href = '/';
                    }
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    handleSubmit = () => {
        const url = process.env.REACT_APP_FETCH_URL + '/sendotp';
        const postedBody = JSON.stringify({
            phone: this.state.email,
            password: this.state.password
        });

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: postedBody
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result && result.isAuthenticate) {
                        localStorage.setItem('token', result.token);
                        localStorage.setItem('name', result.name);
                        this.setState({ lengkapiVisible: false, loginSuccess: true })
                    }
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    saveTheName = () => {
        const lengkapiVisible = this.state.lengkapiVisible;
        this.setState({
            lengkapiVisible: !lengkapiVisible
        });
    }

    handleEmail = (e) => {
        const profile = this.state['profile'];
        profile.email = e.target.value;
        this.setState({
            profile: profile
        });
    }
    handleName = (e) => {
        const profile = this.state['profile'];
        profile.name = e.target.value;
        this.setState({
            profile: profile
        });
    }
    handlePassword = (e) => {
        const profile = this.state['profile'];
        profile.password = e.target.value;
        this.setState({
            profile: profile
        });
    }

    componentDidMount() {
        if (this.props.type == "login") {
            setTimeout(() => {
                window.addIdOnBody('login');
            }, 500);
            return;
        }

        window.addIdOnBody('register');
    }

    handleRegis = () => this.setState({ lengkapiVisible: false, regisSuccess: true })

    render() {
        const { type } = this.props
        const { registerType, lengkapiVisible, loginSuccess, regisSuccess, otpSent, otpMessage, theSecond, profile } = this.state
        let title = type === "register" ? "Daftar" : "Masuk"

        if (loginSuccess) return <Redirect to={{ pathname: "/", user: true }} ></Redirect>
        if (regisSuccess) return <Redirect to={{ pathname: "/login" }} ></Redirect>

        return (
            <div>
                {lengkapiVisible ? <LengkapiProfil onClose={this.handleRegis} profile={profile} /> :
                    <div className="container-fluid" id="formlogin">
                        <div className="daftar-page" id="form-utama" style={{ borderRadius: '12px' }}>
                            <div className="form-daftar-email text-left">
                                <form action="#" method="post">
                                    {
                                        type === "register" &&
                                        <div>
                                            <h4>Daftar Sekarang</h4>
                                            <div className="form-groups">
                                                <span>Nama Lengkap</span>
                                                <input placeholder="Masukkan nama lengkap" name="fname" onChange={(e) => this.handleName(e)} />
                                            </div>
                                            <div className="form-groups">
                                                <span>Email</span>
                                                <input placeholder="Masukkan email" name="fname" required onChange={(e) => this.handleEmail(e)} />
                                            </div>
                                            <div className="form-groups rel-pos">
                                                <span>Password</span>
                                                <input type="password" placeholder="Masukkan password" name="fname" required onChange={(e) => this.handlePassword(e)} />
                                                <div className="eye-password">
                                                    <img src='/images-delip/eye.png' alt="arrow" /></div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        type === "login" &&
                                        !otpSent &&
                                        <div className="wrap-login TESTING-APP">
                                            <div className="hide">
                                                <h4>Daftar Sekarang</h4>
                                                <div className="form-groups">
                                                    <span>Nama Lengkap</span>
                                                    <input placeholder="Masukkan nama lengkap" name="fname" onChange={(e) => this.handleName(e)} />
                                                </div>
                                                <div className="form-groups">
                                                    <span>Email</span>
                                                    <input placeholder="Masukkan email" name="fname" required onChange={(e) => this.handleEmail(e)} />
                                                </div>
                                                <div className="form-groups">
                                                    <span>Password</span>
                                                    <input type="password" placeholder="Masukkan password" name="fname" required onChange={(e) => this.handlePassword(e)} />
                                                    <div className="eye-password">
                                                        <img src='/images-delip/eye.png' alt="arrow" /></div>
                                                </div>
                                                <div className="info-layanan">
                                                    <p>Dengan membuat akun FAMMI, Anda telah menyetujui &nbsp;
                                                        <span>
                                                            <a href="/#">Ketentuan Layanan</a>
                                                        </span>
                                                        &nbsp; dan &nbsp;
                                                        <span>
                                                            <a href="#/">Kebijakan Data</a>
                                                        </span>
                                                    </p>
                                                    <button type="button" className="btn btn-default btn-lg btn-block" onClick={type === "register" ? () => this.setState({ lengkapiVisible: !lengkapiVisible }) : this.handleOTP}>Daftar</button>
                                                </div>
                                            </div>

                                            <div className="show">
                                                <h4>Masuk</h4>
                                                <label>
                                                    Nomor WhatsApp atau Email
                                                </label>
                                                <input placeholder="Masukkan nomor WhatsApp atau Email" name="phone" type="phone" required onChange={(e) => this.handleEmailLogin(e)} />
                                                <div className="caption-label">Contoh: 0812xxxx0000 atau email@fammi.ly</div>
                                                <button type="button" className="btn btn-default btn-lg btn-block" onClick={type === "register" ? () => this.setState({ lengkapiVisible: !lengkapiVisible }) : this.handleOTP}>{title}</button>
                                            </div>

                                            <div className="kerjasama show mar-top-24">
                                                <h4>Pilih Jenis Kerja Sama</h4>
                                                <p>
                                                    Bergabunglah bersama FAMMI untuk memberikan kontribusi terbaik bagi keluarga
                                                </p>
                                                <div className={registerType === "mitra" ? "box-top-mitra active" : "box-top-mitra"} style={{ cursor: "pointer" }} onClick={() => this.setState({ registerType: "mitra" })}>
                                                    <div class="img-active">
                                                        <img src="/images-delip/sub-active.png" alt="arrow" />
                                                    </div>
                                                    <div class="img-mitra">
                                                        <img src="/images-delip/mitra.png" alt="arrow" />
                                                    </div>
                                                    <div class="text-mitra">
                                                        <h2>Mitra</h2>
                                                        <p>Mari bergabung menjadi Mitra FAMMI</p>
                                                    </div>
                                                </div>
                                                <div className={registerType === "narasumber" ? "box-top-mitra active" : "box-top-mitra"} style={{ cursor: "pointer" }} onClick={() => this.setState({ registerType: "narasumber" })}>
                                                    <div class="img-active">
                                                        <img src="/images-delip/sub-active.png" alt="arrow" />
                                                    </div>
                                                    <div class="img-mitra">
                                                        <img src="/images-delip/narasumbers.png" alt="arrow" />
                                                    </div>
                                                    <div class="text-mitra">
                                                        <h2>Narasumber</h2>
                                                        <p>Mari bergabung untuk mengedukasi Keluarga</p>
                                                    </div>
                                                </div>
                                                <button type="button" className="btn btn-default btn-lg btn-block" onClick={(e) => window.location.href = `/register-${registerType}`}>Selanjutnya</button>
                                            </div>

                                            <div className="syarat show mar-top-24">
                                                <h4>Syarat & Ketentuan</h4>
                                                <i>Ketentuan Penggunaan ("Ketentuan") ini terakhir diperbarui pada Agustus 12, 2020.</i>
                                                <div className="box-terms">
                                                    <h3>Ketentuan Penggunaan</h3>
                                                    <p>Syarat dan Ketentuan ini merupakan perjanjian antara penggguna (“Anda”) dan Fammi (“Kami”), yaitu sebuah Platform yag didirikan dan beroperasi secara sah berdasarkan hukum Negara Republik Indonesia dan berdomisili di Jawa Barat, Indonesia. </p>
                                                    <p>Misi fammi adalah memfasilitasi seluruh kebutuhan edukasi keluarga Anda agar memiliki bekal dan keterampilan dalam #BelajarBerkeluarga. Kami memungkinkan semua orang dimanapun untuk membuat dan membagikan keahliannya (Narasumber) dan untuk mendaftar di kursus edukasi ini untuk belajar (peserta). Kami menganggap model marketplace kami sebagai cara terbaik untuk menawarkan konten edukasi keluarga yang berharga bagi para pengguna kami. Kami membutuhkan aturan untuk menjaga keamanan platform dan layanan kami bagi Anda, kami, serta komunitas peserta dan instruktur kami. Ketentuan ini berlaku saat anda mengakses dan menggunakan situs web Fammi (www.fammi.ly), Aplikasi seluler Fammi, Aplikasi Konsultasi Fammi, API kami, fitur dan dan layanan terkait lainnya <b>("Platform")</b></p>
                                                    <p>Jika Anda menerbitkan kelas atau akademi di platform Fammi, Anda juga harus menyetujui Ketentuan Narasumber. Kami juga menyediakan detail tentang pemrosesan data pribadi peserta dan mitra ahli kami di Kebijakan Privasi kami. Jika Anda menggunakan Fammi sebagai bagian dari program pembelajaran dan pengembangan Fammi for Business perusahaan Anda, Anda dapat membaca Pernyataan Privasi Fammi for Business kami</p>
                                                    <p>Harap membaca syarat dan ketentuan dengan seksama sebelum anda mulai menggunakan platform kami. Dengan mengakses dan menggunakan serta mendaftar menjadi peserta di fammi, berarti Anda telah memahami dan menyetujui untuk terikat dan tunduk dengan semua peraturan yang berlaku di Fammi.</p>
                                                    <div className="mar-top-48">
                                                        <h3>Daftar Isi</h3>
                                                        <ul>
                                                            <li>Pembukaan Akun </li>
                                                            <li>Penggunaan Informasi</li>
                                                            <li>Sertifikat </li>
                                                            <li>Pembayaran </li>
                                                            <li>Penggunaan yang Dilarang </li>
                                                            <li>Hak Kekayaan Intelektual </li>
                                                            <li>Laporan Kemungkinan Pelanggaran </li>
                                                            <li>Tanggung Jawab Anda </li>
                                                            <li>Batasan Tanggung Jawab Kami </li>
                                                            <li>Keadaan Kahar (Force Majeure) </li>
                                                            <li>Ketentuan Lainnya </li>
                                                            <li>Cara Menghubungi Kami </li>
                                                        </ul>
                                                    </div>
                                                    <div className="mar-top-48">
                                                        <h3>1. Pembukaan Akun </h3>
                                                        <p>Anda memerlukan akun untuk sebagian besar aktivitas di platform kami. Anda harus sudah mencapai usia persetujuan untuk layanan online di negara Anda untuk menggunakan Fammi.
                                                        Sistem kami akan membuatkan akun pada platform (“akun”) untuk anda yang dapat digunakan untuk menggunakan platform dan memesan layanan melalui platform. Saat anda membuka akun di Fammi, kami akan meminta anda untuk memberikan nama lengkap, alamat surat elektronik dan nomor telepon genggam pribadi Anda. Simpan kata sandi Anda di tempat yang aman, karena Anda bertanggung jawab atas semua aktivitas yang terkait dengan akun Anda. Kami juga dapat menghentikan penggunaan platform apabila dikemudian hari data-data yang anda berikan kepada kami terbukti tidak benar.
                                                        Dalam hal Anda telah keluar dari akun Anda maka Anda perlu memasukan no telpon genggam pribadi yang telah didaftarkan dan memasukan password.
                                                        Akun Anda hanya dapat digunakan oleh Anda, sehingga anda tidak dapat mengalihkannya kepada orang lain dengan alasan apapun. Kami berhak menolak untuk memfasilitasi layanan jika kami mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Anda telah mengalihkan atau membiarkan akun Anda digunakan oleh orang lain.
                                                        Keamanan dan kerahasiaan akun Anda, termasuk nama terdaftar, alamat surat elektronik terdaftar, nomor telepon genggam terdaftar sepenuhnya merupakan tanggung jawab pribadi Anda. Segala kerugian dan risiko yang ada akibat kelalaian anda dalam menjaga keamanan dan kerahasiaan sebagaimana disebutkan ditanggung oleh Anda. Dengan demikian, kami akan menganggap setiap penggunaan atau pesanan yang dilakukan melalui akun Anda sebagai permintaan yang sah dari Anda.
                                                        Harap segera memberitahukan kepada Kami dengan menghubungi Tim Dukungan (direct ke email care@fammi.ly) kami jika anda mengetahui atau menduga bahwa akun Anda telah digunakan tanpa sepengetahuan dan persetujuan Anda. Kami akan melakukan tindakan yang kami anggap perlu dan dapat Kami lakukan terhadap penggunaan tanpa persetujuan tersebut.
                                                        </p>
                                                        <p>
                                                            Peserta dan Mitra Ahli harus berusia minimal 18 tahun atau sudah menikah atau tidak dibawah perwalian untuk membuat akun di Fammi dan menggunakan Layanan. Jika usia Anda di bawah 18 tahun, Anda tidak boleh membuat akun, tetapi kami mendorong Anda untuk mengundang orang tua atau wali untuk membukakan akun dan membantu Anda mendaftar di kelas yang sesuai untuk Anda. Jika usia Anda di bawah usia yang diperbolehkan untuk menggunakan layanan online, Anda tidak boleh membuat akun Fammi. Jika kami mendapati bahwa Anda telah membuat akun yang melanggar aturan ini, kami akan mengakhiri akun Anda. Berdasarkan <b>Ketentuan Mitra Ahli</b> kami, Anda mungkin diminta untuk memverifikasi identitas Anda sebelum Anda diizinkan untuk mengirimkan kursus untuk diterbitkan di Fammi.
                                                        <br />Anda dapat mengakhiri akun Anda kapan saja dengan mengikuti langkah-langkah di sini. Buka Kebijakan Privasi kami untuk melihat apa yang terjadi ketika Anda mengakhiri akun.
                        </p>
                                                    </div>

                                                    <div className="mar-top-48">
                                                        <h3>2. Penggunaan Informasi</h3>
                                                        <p>Penggunaan Informasi akun peserta untuk kepentingan Fammi.ly diperbolehkan dengan syarat tetap oleh persetujuan pihak Fammi.ly tanpa melalui perantara dan segala hal yang berkaitan informasi adalah hak Fammi.ly sepenuhnya.</p>
                                                    </div>

                                                    <div className="mar-top-48">
                                                        <h3>3. Sertifikat</h3>
                                                        <p>Fammi menyediakan sertifikat bagi member yang sudah menyelesaikan kelas. Pada sertifikat tersebut tercantum nama jelas anda dan kelas yang anda ambil. Sertifikat di atas merupakan bentuk penghargaan atau apresiasi yang Kami berikan apabila Anda mengikuti seluruh rangkaian kelas dengan menyeluruh dan sebagai tanda bahwa Anda telah menguasai materi yang diberikan dalam kelas tersebut.</p>
                                                    </div>

                                                    <div className="mar-top-48">
                                                        <h3>4. Pembayaran</h3>
                                                        <p>Ketika Anda melakukan pembayaran atas kelas, materi, dan/atau layanan konsultasi pada platform Kami, maka Anda setuju untuk menggunakan alat pembayaran yang sah dengan metode yang tersedia pada platform Anda setuju untuk membayar harga pembelian akses terhadap kelas, materi, dan/atau konsultasi yang anda beli dan anda memberi wewenang kepada kami untuk melakukan proses transaksi jual beli atas fasilitas yang Anda beli dengan kartu debit atau alat pembayaran lainnya (mobile banking atau mobile wallet) yang Anda gunakan pada saat pembelian di platform Kami. Ketika Anda melakukan pembelian, Anda setuju untuk tidak menggunakan metode pembayaran yang tidak valid atau tidak sah. Kami dapat memberikan atau menawarkan promo dan/atau diskon untuk kelas-kelas pada Platform Kami, yang hanya memungkinkan Anda untuk mendapatkan potongan harga pada jangka wajtu tertentu. Jika metode pembayaran Anda gagal, mohon untuk dapat segera memberitahukan kepada Kami melalui detail kontak yang tercantum pada bagian syarat dan ketentuan ini.  </p>
                                                    </div>

                                                    <div className="mar-top-48">
                                                        <h3>5. Penggunaan yang Dilarang</h3>
                                                        <p>Anda hanya diperbolehkan menggunakan Platform kami untuk tujuan - tujuan yang sah menurut hukum. Anda tidak boleh menggunakan Platform Kami : </p>
                                                        <ol type="a">
                                                            <li>Dengan cara-cara yang melanggar hukum dan peraturan lokal, nasional maupun internasional yang berlaku. </li>
                                                            <li>Dengan cara-cara yang melanggar hukum atau menipu, atau memiliki tujuan atau dampak yang melanggar hukum atau menipu. </li>
                                                            <li>Untuk tujuan membahayakan atau mencoba mencelakakan anak di bawah umur dengan cara apapun.</li>
                                                            <li>Mengirim, secara sadar menerima, mengunggah, mengunduh atau menggunakan atau menggunakan kembali materi yang tidak sesuai dengan standar konten kami. </li>
                                                            <li>Menyebarkan atau mengirimkan materi iklan atau promosi yang tidak diinginkan atau tidak sah, serta bentuk permintaan serupa lainnya (seperti spam)</li>
                                                            <li>Dengan sengaja meneruskan data, mengirim atau mengunggah materi yang mengandung virus, trojan, worm, logic bomb, keystroke loggers, spyware, adware, maupun program berbahaya lainnya atau kode komputer sejenis yang dirancang untuk memberikan efek kerugian terhadap pengoperasian perangkat lunak atau perangkat keras apapun. </li>
                                                        </ol>
                                                    </div>

                                                    <div className="mar-top-48">
                                                        <h3>6. Hak Kekayaan Intelektual</h3>
                                                        <p>Kami memiliki seluruh hak dan kepentingan ats Platform, termasuk seluruh hak kekayaan intelektual terkait dengan seluruh fitur yag terdapat di dalamnya dan hak kekayaan intelektual terkait. Dengan demikian, Anda setuju dan menyatakan : </p>
                                                        <ol type="a">
                                                            <li>Anda mengakui dan menyetujui bahwa Layanan membuat konten atau fitur yang dilindungi oleh hak cipta, paten, merek dagang, rahasia dagang, atau hak kepemilikan lainnya dan undang-undang.</li>
                                                            <li>Anda setuju untuk tidak mengubah, menyalin, membingkai, mengikis, menyewakan, meminjamkan, menjual, mendistribusikan, atau membuat karya turunan berdasarkan Layanan atau Konten Layanan, secara keseluruhan atau sebagian.</li>
                                                            <li>Nama dan logo Fammi adalah merek dagang dan merek layanan dari Fammi (secara kolektif disebut “ Merek Dagang Fammi ”). Nama dan logo Kelas.com, produk, dan layanan lain yang digunakan dan ditampilkan melalui Layanan dapat berupa merek dagang atau merek layanan dari pemiliknya masing-masing yang mendukung atau berafiliasi dengan terhubung ke Fammi. lisensi atau hak untuk menggunakan Merek Dagang Kelas.com yang ditampilkan di Layanan. Dilarang untuk mengerjakan, melisensikan, melakukan, menerbitkan, mereproduksi (meniru), menggandakan, menyalin, tanpa izin tertulis sebelumnya dari kami dalam hal apapun yang berkaitan dengan Nama dan Logo Fammi.. Semua kebijakan baik ini merupakan hak dari penggunaan Merek Dagang Fammi akan diberlakukan untuk keuntungan eksklusif kami.</li>
                                                            <li>Semua materi dan hak kekayaan intelektual dalam semua materi yang terdapat pada situs www.fammi.ly atau aplikasi fammi adalah milik Fammi. Ketika Anda diberi akses ke Fammi, Anda diberikan lisensi non-eksklusif dan tidak dapat dipindahtangankan.</li>
                                                            <li>Setiap penggunaan seperti itu sangat dilarang dan akan merupakan pelanggaran terhadap hak kekayaan intelektual Fammi.</li>
                                                        </ol>
                                                    </div>

                                                    <div className="mar-top-48">
                                                        <h3>7. Laporan Kemungkinan Pelanggaran</h3>
                                                        <p>
                                                            Pada saat Anda menemukan konten pada Platform Kami yang Anda yakini melanggar hak cipta, menyalahi hak lainnya, merukan nama baik, bersifat pornografis atau tidak senonoh, bersifat diskriminatif, berbau SARA, atau dengan cara-cara lain menyebabkanpelanggaran secara luas, atau yang merupakan peniruan identitas, spam, atau sebaliknya menyalahi Persyaratan Penggunaan serta Kebijaka Data maupun peraturan yang berlaku lainnya, silahkan laporkan ini kepada kami melalui surat elektronik ke care@fammi.ly.
                                                        </p>
                                                        <p>
                                                            Pastikan bahwa Anda menyertakan, dalam laporan tersebut (“Laporan”), informasi sebagai berikut :
                                                        </p>
                                                        <ol type="a">
                                                            <li>Deskripsi dari pelanggaran atau konten ilegal serta link dimana konten tersebut berada;</li>
                                                            <li>Screenshot dari konten yang melanggar atau bersifat ilegal; </li>
                                                            <li>Nama lengkap, alamat dan nomor telepon Anda, alamat surat elektronik dimana Anda dapat dihubungi, serta nama pengguna (Username) akun Anda jika Anda memilikinya.</li>
                                                        </ol>
                                                    </div>
                                                    <div className="mar-top-48">
                                                        <h3>8. Tanggung Jawab Anda</h3>
                                                        <p>Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk menggunakan atau mengakses Platform. Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau klaim yang timbul dari penggunaan Platform melalui akun Anda, baik oleh Anda atau pihak lain yang tidak bertanggung jawab dan menggunakan akun Anda, dengan cara yang bertentangan dengan Syarat dan Ketentuan ini, Kebijaka Data atau peraturan perundang-undangan yang berlaku. </p>
                                                        <p> Kami tidak menjamin bahwa Platform Kami akan aman atau terbebas dari bug atau virus. Anda bertanggung jawab untuk mengatur teknologi informasi, serta Platform yang Anda gunakan untuk mengakses Platform Kami. Anda harus menggunakan perangkat lunak anti virus Anda sendiri.</p>
                                                    </div>
                                                    <div className="mar-top-48">
                                                        <h3>9. Batasan Tanggung Jawab Kami</h3>
                                                        <p>Kami menyediakan Platform sebagaimana adanya dan Kami tidak menyatakan atau menjamin bahwa kehandalan, ketepatan waktu, kualitas, kesesuaian, ketersediaan, akurasi, kelengkapan atau keamanan dari Platform dapat memenuhi kebutuhan dan akan sesuai dengan harapan Anda.</p>
                                                        <p>Kami hanya menyediakan Platform untuk penggunaan domestik dan pribadi. Anda setuju untuk tidak menggunakan Platform Kami untuk tujuan komersial atau bisnis apa pun, dan Kami tidak bertanggung jawab kepada Anda atas kerugian, kehilangan, gangguan, maupun hilangnya kesempatan usaha atau bisnis.</p>
                                                        <p>Kami tidak bertanggung jawab atas kerugian atau kerusakan yang disebabkan oleh virus, serangan Penolakan Layanan secara Terdistribusi, maupun materi teknologi berbahaya lainnya yang dapat menginfeksi perangkat yang Anda gunakan dalam mengakses Platform Kami.</p>
                                                        <p>Kami tidak berkewajiban untuk mengawasi akses atau penggunaan Anda terhadap Platform. Akan tetapi, Kami akan tetap melakukan pengawasan untuk memastikan kelancaran penggunaan Platform dan untuk memastikan kepatuhan Anda terhadap Syarat dan Ketentuan ini dan peraturan perundang-undangan yang berlaku. </p>
                                                    </div>
                                                    <div className="mar-top-48">
                                                        <h3>10. Keadaan Kahar (Force Majeure)</h3>
                                                        <p>Platform Kami dapat diinterupsi oleh kejadian di luar kewenangan atau kontrol Kami (“Keadaan Kahar”), termasuk namun tidak terbatas pada bencana alam, gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah, dan lain-lain. Anda setuju untuk membebaskan Kami dari setiap tuntutan dan tanggung jawab, jika Kami tidak dapat memfasilitasi Layanan, termasuk memenuhi instruksi yang Anda berikan melalui Platform. Baik sebagian maupun seluruhnya karena suatu Keadaan Kahar.</p>
                                                    </div>
                                                    <div className="mar-top-48">
                                                        <h3>11. Ketentuan Lainnya</h3>
                                                        <p>Dengan ini Anda mengerti dan setuju bahwa Syaran dan Ketentuan ini merupakan perjanjian dalam bentuk elektronik dan tindakan Anda saat pembukaan Akun atau saat akan mengakses Akun Anda merupakan persetujuan aktif Anda untuk mengikatkan diri dalam perjanjian dengan Kami sehingga Syarat dan Ketentuan ini dan Kebijakan Data bersifat sah dan mengikat secara hukum dan akan terus berlaku selama Anda menggunakan Platform Kami.</p>
                                                        <p>Syarat & ketentuan mungkin diubah dan/atau diperbaharui dari waktu ke waktu tanpa pemberitahuan sebelumnya. Fammi menyarankan agar anda membaca secara seksama dan memeriksa halaman Syarat & ketentuan ini dari waktu ke waktu untuk mengetahui perubahan apapun. Dengan tetap mengakses dan menggunakan layanan Fammi, maka pengguna dianggap menyetujui perubahan-perubahan dalam Syarat & ketentuan. </p>
                                                    </div>
                                                    <div className="mar-top-48">
                                                        <h3>12. Cara Menghubungi Kami</h3>
                                                        <p>Untuk menghubungi kami, silahkan kirim surat elektronik ke care@fammi.ly</p>
                                                    </div>
                                                    <br />
                                                    <hr />
                                                    <br />

                                                    <h3>Ketentuan Narasumber</h3>
                                                    <p>Ketika Anda mendaftar untuk menjadi narasumber di platform Fammi, Anda setuju untuk mematuhi Ketentuan Instruktur ini ("Ketentuan"). Ketentuan ini mencakup detail tentang aspek platform Fammi yang relevan dengan Fammi dan dimasukkan dengan merujuk pada Ketentuan Penggunaan kami, ketentuan umum yang mengatur penggunaan Anda atas Layanan kami.</p>
                                                    <p>Sebagai seorang Instruktur, Anda terikat kontrak langsung dengan PT.Fammi (suatu perseroan di Bandung, Indonesia).</p>
                                                    <div className="mar-top-48">
                                                        <h3>Daftar Isi</h3>
                                                        <ul className="list-legal">
                                                            <li>Layanan </li>
                                                            <li>Kewajiban Narasumber </li>
                                                            <li>Penangguhan Narasumber </li>
                                                            <li>Hubungan dengan Pengguna Lain </li>
                                                            <li>Pembayaran </li>
                                                            <li>Memperbaharui Ketentuan </li>
                                                            <li>Cara Menghubungi Kami</li>
                                                        </ul>
                                                    </div>
                                                    <div className="mar-top-48">
                                                        <h3>1. Layanan</h3>
                                                        <p>Sebagai narasumber anda dapat memilih layanan fammi terdiri dari beberapa kategori sebagai berikut :</p>
                                                        <ul type="a">
                                                            <li>Kelas One Shot yaitu kelas yang diadakan dalam satu waktu tentu dan hanya satu kali pelaksanaannya baik secara interaksi narasumber atau tersedianya materi.</li>
                                                            <li>Konsultasi yaitu kelas yang diarahkan untuk peserta dapat berkonsultasi 1on1 dengan narasumber, sesuai dengan jadwal yang ditentukan oleh narasumber.</li>
                                                            <li>Kelas akademi yaitu kelas dalam kurun waktu berturut-turut, dengan materi yang serial dan bisa diakses kapan saja.</li>
                                                        </ul>
                                                        <p>Narasumber juga bersedia mematuhi segala bentuk ketentuan layanan fammi tersebut.</p>
                                                    </div>
                                                    <div className="mar-top-48">
                                                        <h3>2. Kewajiban Narasumber</h3>
                                                        <p>Sebagai Narasumber, Anda bertanggung jawab atas semua konten yang anda kirimkan kepada Fammi, termasuk pelajaran, kuis, latihan, ujian praktik, tugas, sumber daya, jawaban ("Konten yang Dikirim").</p>
                                                        <p>Anda menyatakan dan menjamin bahwa:</p>
                                                        <ul type="a">
                                                            <li>Anda akan memberikan dan menjaga keakuratan informasi akun;</li>
                                                            <li>Anda memiliki lisensi, hak, persetujuan, izin, dan otorisasi yang diperlukan untuk mengotorisasi Fammi menggunakan Konten yang Dikirim oleh Anda sebagaimana ditentukan dalam Ketentuan dan Ketentuan Penggunaan ini;</li>
                                                            <li>Konten yang Dikirimkan oleh Anda tidak akan melanggar atau menyalahgunakan hak kekayaan intelektual pihak ketiga mana pun;</li>
                                                            <li>Anda memiliki kualifikasi, kredensial, dan keahlian yang diperlukan (termasuk pendidikan, pelatihan, pengetahuan, dan keahlian) untuk mengajar dan menawarkan layanan yang Anda tawarkan melalui Konten yang Dikirimkan dan penggunaan Layanan seputar kebutuhan edukasi keluarga; serta</li>
                                                        </ul>
                                                        <p>Anda menjamin bahwa Anda tidak akan:</p>
                                                        <ul type="a">
                                                            <li>Memposting atau memberikan konten atau informasi yang tidak pantas, menyinggung, rasis, bernuansa kebencian, seksis, porno, palsu, menyesatkan, salah, melanggar, mencemarkan nama baik, atau memfitnah;</li>
                                                            <li>Memposting atau mengirimkan iklan, materi promosi, surat sampah, spam, atau bentuk ajakan lainnya (komersial atau lainnya) yang tidak diminta atau tidak sah melalui Layanan atau kepada pengguna mana pun;</li>
                                                            <li>Menggunakan Layanan untuk bisnis selain dari memberikan layanan tutorial, pengajaran, dan instruksi kepada peserta;</li>
                                                            <li>Menyamar sebagai orang lain atau mendapatkan akses tidak sah ke akun orang lain;</li>
                                                            <li>Mengganggu atau dengan cara lain menghalangi narasumber lain untuk menyediakan layanan atau kelas mereka; atau</li>
                                                            <li>Menyalahgunakan sumber daya Fammi, termasuk layanan dukungan.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="mar-top-48">
                                                        <h3>3. Penangguhan Narasumber</h3>
                                                        <p>Kami berhak untuk menghapus layanan, menangguhkan pembayaran, dan/atau melarang narasumber dengan alasan apa pun kapan pun, tanpa pemberitahuan sebelumnya, termasuk dalam kasus dimana:</p>
                                                        <ul type="a">
                                                            <li>Narasumber atau layanan tidak mematuhi kebijakan atau ketentuan hukum kami (termasuk Ketentuan Penggunaan);</li>
                                                            <li>Layanan berada di bawah standar kualitas kami atau memiliki dampak negatif pada pengalaman peserta;</li>
                                                            <li>Seorang Narasumber terlibat dalam perilaku yang mungkin mencerminkan Fammi secara tidak menguntungkan atau memunculkan nama buruk, penghinaan, skandal, atau ejekan publik terhadap Fammi;</li>
                                                            <li>Seorang Fammi menggunakan jasa pemasar atau mitra bisnis lain yang melanggar kebijakan Fammi; atau </li>
                                                            <li>Sebagaimana ditentukan oleh Fammi atas kebijakannya sendiri.</li>
                                                        </ul>
                                                        <p>Narasumber juga bersedia mematuhi segala bentuk ketentuan layanan fammi tersebut.</p>
                                                    </div>
                                                    <div className="mar-top-48">
                                                        <h3>4. Hubungan dengan Pengguna Lain</h3>
                                                        <p>Narasumber tidak memiliki hubungan kontrak langsung dengan peserta, sehingga satu-satunya informasi yang akan Anda terima tentang peserta adalah apa yang diberikan kepada Anda melalui Layanan. Anda setuju bahwa Anda tidak akan menggunakan data yang Anda terima untuk tujuan apa pun selain memberikan layanan kepada peserta di platform Fammi, dan bahwa Anda tidak akan mengumpulkan data pribadi tambahan atau menyimpan data pribadi peserta di luar platform Fammi. Anda akan mengganti kerugian Fammi untuk setiap segala klaim yang timbul dari penggunaan data pribadi peserta oleh Anda.</p>
                                                    </div>
                                                    <div className="mar-top-48">
                                                        <h3>5. Pembayaran</h3>
                                                        <p>Narasumber akan mendapatkan profit sharing sebesar 50% dari keseluruhan pembeli layanan di Fammi. Pembayaran kelas one shot akan dibayarkan maksimal H+3 Pelaksanaan Kelas, untuk Layanan Konsultasi dan Akademi akan dibayarkan setiap tanggal 25 di setiap bulannya.</p>
                                                    </div>
                                                    <div className="mar-top-48">
                                                        <h3>6. Memperbaharui Ketentuan</h3>
                                                        <p>Dari waktu ke waktu, kami dapat memperbarui Ketentuan ini untuk memperjelas layanan kami atau untuk mencerminkan layanan yang baru atau berbeda (seperti ketika kami menambahkan fitur baru), dan Fammi berhak, atas kebijakannya sendiri, untuk memodifikasi dan/atau membuat perubahan pada Ketentuan ini kapan saja. Jika kami melakukan perubahan material apa pun, kami akan memberi tahu Anda menggunakan sarana yang menonjol seperti melalui pemberitahuan email yang dikirim ke alamat email yang ditentukan dalam akun Anda atau dengan mengirimkan pemberitahuan melalui Layanan kami. Perubahan akan berlaku efektif pada hari perubahan itu diposting, kecuali jika dinyatakan lain.
                        <br />Dengan terus menggunakan layanan kami setelah perubahan berlaku efektif, Anda menerima perubahan tersebut. Setiap Ketentuan yang direvisi akan menggantikan semua Ketentuan sebelumnya.
                        </p>
                                                    </div>
                                                    <div className="mar-top-48">
                                                        <h3>7. Cara Menghubungi Kami</h3>
                                                        <p>Cara terbaik untuk menghubungi kami adalah dengan menghubungi Tim Dukungan Mitra (Arahkan ke email mitra@fammi.ly atau kontak layanan mitra) kami. Kami ingin mendengar pertanyaan, kekhawatiran, dan umpan balik Anda tentang Layanan kami.</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <label class="checkit">
                                                    <div className="text-checkit mar-btm-15">
                                                        Dengan ini Anda telah menyetujui Ketentuan Layanan dan Kebijakan FAMMI
                                                    </div>
                                                    <input type="checkbox" checked="checked" />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <button type="button" className="btn btn-default btn-lg btn-block" onClick={type === "register" ? () => this.setState({ lengkapiVisible: !lengkapiVisible }) : this.handleOTP}>Selanjutnya</button>
                                            </div>


                                        </div>
                                    }

                                    {
                                        type === "login" &&
                                        otpSent &&
                                        <div className="wrap-login">
                                            <Images src="images/verified.svg" />
                                            <h4 className="text-center">Masukkan Kode Verifikasi</h4>
                                            <p className="otp-style">
                                                Kode verifikasi telah dikirimkan melalui {otpMessage}
                                            </p>
                                            <h5>
                                                Kode verifikasi
                                            </h5>
                                            <div style={{ position: 'relative', margin: '24px auto 50px auto', textAlign: 'center' }}>
                                                <PinInput length={6} type="numeric" inputStyle={{ width: '31px', height: '44px', marginRight: '8px', padding: '10px', background: '#EAEAEA', borderRadius: '6px', border: 'none' }} onComplete={(value, index) => this.setOtp(value)} />
                                            </div>
                                            <button type="button" className="btn btn-default btn-lg btn-block" style={{ backgroundColor: "#7D61D6", color: "#fff", borderRadius: "12px", marginLeft: '0px' }} onClick={this.checkOtp}>Verifikasi</button>
                                            {
                                                theSecond > -1 &&
                                                <p className="counting text-center" style={{ marginTop: '24px' }}>
                                                    Mohon menunggu <b>{theSecond} detik</b> untuk mengirim ulang
                                                </p>
                                            }

                                            <p className="counting text-center" style={{ marginTop: '24px', display: (theSecond < 0) ? 'block' : 'none' }}>
                                                Tidak menerima kode verifikasi?<br />
                                                <a href="#" style={{ color: '#7D61D6', cursor: 'pointer', fontFamily: 'Rubik', fontWeight: '500' }} onClick={() => this.handleOTP()}>Kirim Ulang</a>
                                            </p>
                                        </div>
                                    }

                                    {
                                        type === "register" &&


                                        <div className="info-layanan">
                                            <p>Dengan membuat akun FAMMI, Anda telah menyetujui &nbsp;
                                                <span>
                                                    <a href="/#">Ketentuan Layanan</a>
                                                </span>
                                                &nbsp; dan &nbsp;
                                                <span>
                                                    <a href="#/">Kebijakan Data</a>
                                                </span>
                                            </p>
                                        </div>
                                    }
                                    {
                                        type === "register" &&
                                        <button type="button" className="btn btn-default btn-lg btn-block" style={{ backgroundColor: "#7D61D6", color: "#fff", borderRadius: "12px" }} onClick={type === "register" ? () => this.saveTheName() : this.handleSubmit}>{title}</button>
                                    }
                                </form>
                            </div>
                            <div className="atau-line" style={{ display: 'none' }}>
                                <hr />
                                <p> <span>atau</span> </p>
                            </div>
                            <div style={{ display: 'none' }}>
                                <a href="/#" className="fammibuttonalt colorbg3" id="daftar-google">Daftar dengan Google</a>
                                <a href="/#" className="fammibuttonalt colorbg3" id="daftar-facebook">Daftar dengan Facebook</a>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default RegisterForm