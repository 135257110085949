import React from "react";
import moment from "moment";
import {
  DetailSection,
  PaymentFixedButton,
  PaymentInfo,
  SectionContent,
} from "./DetailSection";
import "./PaymentSuccess.css";

export const PaymentSuccess = (props) => {
  const {product, transaction} = props;
  const bankName = {
    "mandiri": "VA Bank Mandiri",
    "bca": "VA Bank BCA",
    "bri": "VA Bank BRI",
    "permata": "VA Bank Permata",
    "bni": "VA Bank BNI",
    "gopay": "Gopay",
    "shopeepay": "Shopeepay",
  }
  return (
    <div id="payment_success">
      <PaymentInfo isSuccess />
      <DetailSection title="Detail Pembelian">
        <div className="ps_program">
          <img src="/assets/images/sahabat-sekolah-masked.svg" alt="fee" />
          <div style={{ marginLeft: 12 }}>
            <p>Program Fammi Sahabat Sekolah</p>
            <p style={{ color: "#6C60E0", marginTop: 6 }}>Rp.{(product?.price).toLocaleString("id-ID")},-</p>
          </div>
        </div>
        <hr />
        <SectionContent label="Metode Pembayaran" value={bankName[transaction?.paymentMethod]} />
        <hr />
        <SectionContent
          label="Waktu Pembayaran"
          value={`${moment(transaction?.created).add(1, "days").format("DD MMM YYYY HH:mm:SS")} WIB`}
          method={transaction?.paymentMethod}
        />
      </DetailSection>

      <DetailSection title="Catatan untuk Pembeli">
        <p className="ps_notes">
        Terima kasih telah mengisi formulir ini. Kami akan segera menghubungi Anda dengan informasi lebih lanjut mengenai Program Fammi Sahabat Sekolah. Jika Anda memiliki pertanyaan lebih lanjut, jangan ragu untuk menghubungi kami di nomor WhatsApp berikut: 0812-2255-4838 (Nadya) atau melalui akun Instagram @fammiforschool.
        </p>
      </DetailSection>
      <PaymentFixedButton
        label="Masuk ke Grup WhatsApp Khusus Program"
        onClick={() => {
          window.location.href = "https://chat.whatsapp.com/HJ06OFCjvt4J7fOVu33efH";
        }}
      />
    </div>
  );
};
