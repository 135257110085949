import React from "react";

export const ProgramSection = (props) => {
  const { t } = props;
  const desc = [
    t("created-dedicated"),
    t("online-clinic"),
    t("guided-practical"),
    t("every-report"),
  ];
  return (
    <div style={{ background: "#FAF9FF" }}>
      <div
        className="program-section flex justify-between mo-flex-col-reverse"
        style={{ maxWidth: "1170px", margin: "auto" }}
      >
        <div className="w-50-percent mo-w-full mo-mt-6">
          <h1>{t("digital-program")}</h1>
          <h2>{t("parent-solving")}</h2>
          <div className="mt-9">
            {desc.map((d, idx) => (
              <div className="flex items-center mb-6 mo-mb-4" key={idx}>
                <div
                  className="bg-main flex items-center justify-center rounded-full numbering mr-4"
                  style={{ width: "36px", height: "36px" }}
                >
                  <p>{idx + 1 * 1}</p>
                </div>
                <div
                  style={{
                    maxWidth: idx === 0 ? "100%" : "75%",
                  }}
                >
                  <p>{d}</p>
                </div>
              </div>
            ))}
          </div>
          <button
            className="bg-main rounded border-none text-white font-bold text-center mt-10 btn-look reverse mo-mt-6 mo-w-full"
            onClick={() => {
              window.open("http://sekolah.fammi.ly/", "_blank");
            }}
          >
            {t("view-more")}
          </button>
        </div>
        <div className="none mo-flex justify-center">
          <img
            src="/assets/new-home/mob-vector-programs.png"
            alt="vector-programs"
          />
        </div>
        <img
          src="/assets/new-home/vector-programs.png"
          alt="vector-programs"
          className="mo-none"
          style={{ width: "46%", height: "fit-content" }}
        />
      </div>
    </div>
  );
};
