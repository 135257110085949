/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CardAds from "../Card/CardAds";
import {
  getAdditionalContent,
  getAdditionalProducts,
} from "../services/products";
import { PremiumDialog } from "./PremiumDialog/PremiumDialog";

function DesktopLeft(props) {
  const {
    eventData,
    token,
    setIdl,
    setOpenLogin,
    names,
    memberType,
    tenants,
    activePackages,
    eventCode,
    visibleAds,
    dataAds,
    getAdvertisingLog,
    email,
    setLoginMode,
    expired,
  } = props;
  const [premiumDialog, setPremiumDialog] = useState(false);
  const [content, setContent] = useState(false);
  const [products, setProducts] = useState(false);
  const [isEventPaid, setIsEventPaid] = useState(false);
  const isPaid = memberType?.indexOf("paid-member") > -1;
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!eventData || !eventData?.eventId) {
      return;
    }

    if (eventData?.isPaid === "true") {
      setIsEventPaid(true);
    }

    getAdditionalContent(eventData.eventId).then((d) => {
      setContent(d.data[0]);
    });

    getAdditionalProducts(eventData.eventId).then((d) => {
      const products = d.data?.sort((a, b) => {
        return a.normalPrice > b.normalPrice;
      });

      if (products) {
        setProducts(products[0]);
      }
    });
  }, [eventData]);

  const NewMenuList = [
    {
      id: 1,
      name: "Webinar",
      listMenu: [
        {
          id: 11,
          name: t("schedules"),
          imgKey: "menu-schedule",
          path: "/schedule",
          action: null,
        },
        {
          id: 12,
          name: t("record"),
          imgKey: "menu-video",
          path: "/record",
          action: null,
        },
        {
          id: 13,
          name: t("certificate"),
          imgKey: "menu-certificate",
          path: "/certificate",
          action: null,
        },
      ],
    },

    {
      id: 2,
      name: t("services"),
      listMenu: [
        {
          id: 22,
          name: "Survey",
          imgKey: "menu-survey",
          path: "/profile",
          action: null,
        },
        {
          id: 23,
          name: t("quiz"),
          imgKey: "menu-quiz",
          path: "/quiz",
          action: null,
        },
      ],
    },
    {
      id: 3,
      name: t("materials"),
      listMenu: [
        {
          id: 31,
          name: t("video-materials"),
          imgKey: "menu-video",
          path: "/material/video",
          action: null,
        },
        {
          id: 32,
          name: t("modules-materials"),
          imgKey: "menu-modul",
          path: "/material/modul",
          action: null,
        },
        {
          id: 33,
          name: t("articles-materials"),
          imgKey: "menu-artikel",
          path: "/article",
          action: null,
        },
      ],
    },
  ];

  if (eventData?.isScreening === "true") {
    NewMenuList[1].listMenu.unshift({
      id: 21,
      name: t("screening-test"),
      imgKey: "menu-screening",
      path: "/screening",
      action: null,
    });
  }

  return (
    <React.Fragment>
      <div className="one">
        {eventData.miniCover && (
          <img
            src={`https://fammi.ly/uploads/events/images/${
              eventData.cover
            }?v=${Math.random()}`}
            alt="cover"
            className="cover-img"
          />
        )}
        <div
          style={{
            border: "solid 1px #EAEAEA",
            borderRadius: eventData?.miniCover ? "0px 0px 12px 12px " : "12px",
          }}
        >
          <div className="contents" style={{ borderBottom: token && "none" }}>
            <div
              className="the-content"
              style={{
                marginBottom: token && 8,
              }}
            >
              {eventData?.logo && (
                <img
                  src={`https://fammi.ly/uploads/events/images/${eventData?.logo}`}
                  alt="buildings"
                />
              )}
              {!eventData?.logo && (
                <img src="/assets/images/e_buildings.svg" alt="buildings" />
              )}
              <div>
                <h4 style={{ fontSize: "16px" }}>{eventData?.name}</h4>
              </div>
            </div>
          </div>
          {isEventPaid && !token && (
            <div className="microsite-paids">
              <div className="prime">
                <h1>{content?.title}</h1>
                <p>{content?.descriptions}</p>
                <div className="flexed">
                  <div>
                    <h5>Biaya Program</h5>
                    <h4>
                      Rp
                      {parseInt(products?.normalPrice).toLocaleString("ID-id")}
                      ,-
                    </h4>
                  </div>
                  <button
                    onClick={() => {
                      localStorage.setItem("c892072", `/e/${eventCode}/payment-package`);
                      setOpenLogin(true);
                    }}
                  >
                    <img
                      src="/assets/images/shield_check.svg"
                      alt="secure-checks"
                    />
                    Beli Sekarang
                  </button>
                </div>
              </div>
              <div className="bottomeds">
                <div className="flexed">
                  <img
                    style={{ marginRight: 10 }}
                    src="/assets/images/e_new_lock.svg"
                    alt="locks"
                  />
                  <p>Sudah beli program ini?</p>
                </div>
                <a
                  href="#"
                  onClick={() => {
                    localStorage.setItem("c892072", `/e/${eventCode}/payment-package`);
                    if (setIdl) {
                      setIdl(1);
                    }
                    setOpenLogin(true);
                  }}
                >
                  Masuk Sekarang
                </a>
              </div>
            </div>
          )}
          {!isEventPaid && !token && (
            <div className="logins">
              <button
                onClick={() => {
                  if (eventCode === "orangtuaberpijar") {
                    window.location.href =
                      process.env.REACT_APP_PIJAR_LOGIN_PAGE;
                    return;
                  }

                  setIdl(1);
                  if (setLoginMode) {
                    setLoginMode("non-event");
                  }
                  setOpenLogin(true);
                }}
                className="msk"
              >
                {t("login")}
              </button>
              <button
                onClick={() => {
                  if (eventCode === "orangtuaberpijar") {
                    window.location.href =
                      process.env.REACT_APP_PIJAR_REGISTER_PAGE;
                    return;
                  }

                  setIdl(0);
                  if (setLoginMode) {
                    setLoginMode("non-event");
                  }
                  setOpenLogin(true);
                }}
                className="dft"
              >
                {t("sign-up")}
              </button>
            </div>
          )}
          {token && (
            <>
              <div className="mained">
                {NewMenuList.map((item) => (
                  <div style={{ marginBottom: 24 }} key={item.id}>
                    <h6>{item.name}</h6>
                    {item.listMenu.map((d) => (
                      <div
                        key={d.id}
                        onClick={async () => {
                          if (!d.path) {
                            d.action();
                          } else {
                            props.history.push(`/e/${eventCode}${d.path}`);
                          }
                        }}
                        className="logouts"
                        style={{
                          paddingLeft: "10px",
                          paddingBottom: 0,
                          border: "none",
                        }}
                      >
                        <img
                          src={`/assets/images/${d.imgKey}.svg`}
                          className="menu-mob"
                          alt="menu-mob"
                        />
                        <p>{d.name}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        {visibleAds && dataAds?.adsType === "left-sidebar" && (
          <div style={{ marginTop: 20 }}>
            <CardAds
              getAdvertisingLog={getAdvertisingLog}
              mini
              eventCode={eventCode}
              data={dataAds}
            />
          </div>
        )}
      </div>
      {premiumDialog && (
        <PremiumDialog
          endDate={eventData?.endDate}
          onClose={() => setPremiumDialog(false)}
          history={props.history}
          setPremiumDialog={setPremiumDialog}
        />
      )}
    </React.Fragment>
  );
}

export default withRouter(DesktopLeft);
