import React from "react";

export const TestimoniSection = ({ t }) => {
  const DATALIST = [
    {
      id: 1,
      testimoni: t("testi-1"),
      name: "Masyrifah",
      title: "Kepala Sekolah TK Sabilina",
      logo: "/assets/new-home/tk-sabilina.png",
    },
    {
      id: 2,
      testimoni: t("testi-2"),
      name: "Muhammad Ali",
      title: "Kepala Sekolah SMP Hikmah Teladan",
      logo: "/assets/new-home/smp-hikmah.png",
    },
    {
      id: 3,
      content: `<p> <b>80,2%</b> ${t("parent-testi")}</p>  <p><b>93,8% </b>${t(
        "parent-helped"
      )} </p>  <p><b>95,7%</b> ${t("parent-responded")}.</p>`,
      name: "Sekolah Assalaam Papua",
      span: t("research-testi"),
      logo: "/assets/new-home/sekolah-assalam.png",
    },
    {
      id: 4,
      testimoni: t("testi-4"),
      name: "Teti Herawati",
      title: "Kepala Sekolah SD Sabilina",
      logo: "/assets/new-home/sd-sabilina.png",
    },
  ];
  return (
    <div className="testimoni-section">
      <div style={{ maxWidth: "1170px", margin: "auto" }}>
        <h1 className="mb-4 mo-mb-2">{t("been-trusted")}</h1>
        <p className="text-center">{t("scattered")}</p>
        <div className="mt-13 flex justify-between flex-wrap mo-mt-6">
          {DATALIST.map((d) => (
            <div
              className="bg-ori-white rounded-md p-9 w-half-button mb-9 flex flex-col justify-between h-card mo-mb-4 mo-w-full mo-p-6"
              key={d.id}
            >
              <div>
                <div className="flex items-center justify-between">
                  <img src="/assets/new-home/quote-icon.svg" alt="quote-icon" />
                  <div className="flex items-center justify-between">
                    {[1, 2, 3, 4, 5].map((s) => (
                      <img
                        src="/assets/new-home/star-on.svg"
                        alt="star"
                        key={s}
                      />
                    ))}
                  </div>
                </div>
                <div className="mt-4">
                  {d.content ? (
                    <div dangerouslySetInnerHTML={{ __html: d.content }} />
                  ) : (
                    <p>{d.testimoni}</p>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-between mo-flex-col-reverse mo-items-start mo-mt-9">
                <div className="w-75-percent mo-w-full">
                  <p className="font-bold text-lg">{d.name}</p>
                  {d.span ? (
                    <span>{d.span}</span>
                  ) : (
                    <p className="text-lg">{d.title}</p>
                  )}
                </div>
                <img src={d.logo} alt={d.title} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
