import moment from "moment";
import "moment-duration-format";

export default function formatDuration(durations) {
  return moment
    .duration(durations, "seconds")
    .format(durations > 3599 ? "hh:mm:ss" : "mm:ss", { trim: false });
}

export const formatDurationQna = (durations) => {
  return moment
    .duration(durations, "minutes")
    .format(durations > 60 ? "hh [Jam]" : "mm [Menit]", { trim: false });
};
