import React, { useState } from "react";
import downIcon from "../../images/arrow-down.svg";
import "./ProfileMenu.css";
import { ProfileMenuList } from "./ProfileMenuList";
import { useTranslation } from "react-i18next";

export const ProfileMenu = ({ eventCode, eventData, name, email, memberType, setExpired, expired, setPremiumDialog, activePackages }) => {
  const token = localStorage.getItem("token");
  const [profileMenu, setProfileMenu] = useState(false);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div id="profile_menu">
        <button
          className="asking"
          style={{
            border: "1px solid #000",
            background: "none",
            width: "120px",
            display: token ? "content" : "none",
          }}
          onClick={() => setProfileMenu(!profileMenu)}
        >
          <img src={`/assets/images/user_square.svg`} alt="user" />
          <p style={{ color: "#000" }}>
            {t("profile")}
          </p>
          <img src={downIcon} alt="down" style={{ marginLeft: "15px" }} />
        </button>
        {profileMenu && (
          <ProfileMenuList
            eventCode={eventCode}
            eventData={eventData}
            name={name}
            email={email}
            memberType={memberType}
            setExpired={setExpired}
            expired={expired}
            setPremiumDialog={setPremiumDialog}
            activePackages={activePackages}
          />
        )}
      </div>
      {profileMenu && (
        <div className="backdrop" onClick={() => setProfileMenu(false)} />
      )}
    </React.Fragment>
  );
};
