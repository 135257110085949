import moment from "moment";
import writeEndLog from "./write-end-log";

function writeLogIntoStorage(mode, log) {
  let currentLogs = [];

  if (mode === "play") {
    // incase there is opened logs
    writeEndLog();

    if (localStorage.getItem("logs") !== null) {
      currentLogs = JSON.parse(localStorage.getItem("logs"));
    }

    log.startTime = moment().format("YYYY-MM-DD HH:mm:ss");
    log.endTime = null;

    currentLogs = [...currentLogs, log];
    localStorage.setItem("logs", JSON.stringify(currentLogs));
  }

  if (mode !== "play") {
    writeEndLog();
  }
}

export default writeLogIntoStorage;
