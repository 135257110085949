import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import translations
import translationEN from "./locales/en/translation.json";
import translationID from "./locales/id/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  id: {
    translation: translationID,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: (!localStorage.getItem("lang") || localStorage.getItem("lang") === null) ? "id" : localStorage.getItem("lang"), // default language
  fallbackLng: "en", // fallback language
  keySeparator: false, // allow key separator in translation json file
  interpolation: {
    escapeValue: false, // allow HTML tags in translation
  },
});

export default i18n;
