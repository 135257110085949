import React from "react";
import "../css/VideoSection.css";

export default function VideoSection() {
  return (
    <>
      <div className="parent-video-container">
        <div className="parent-content-video">
          <div className="section-one-video">
            <p className="text-title-video-subs">
              Berkenalan lebih dekat dengan Fammi
            </p>
            <p className="text-description-video-subs">
              FAMMI adalah platform edukasi dengan topik seputar Keluarga
            </p>
          </div>
          <div className="section-separator-video" />
          <div className="section-two-video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/n4T4TpyWnTE"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              className="video-subs"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}
