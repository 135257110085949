import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { CheckIcon } from "../../../assets";
import "./DetailSection.css";

export const DetailSection = ({ children, title, rightImg }) => {
  return (
    <div id="detail_section">
      <div className="ds_head">
        <div className="ds_title">
          <div className="dot" />
          <h6>{title}</h6>
        </div>
        {rightImg && (
          <img
            className="ds_img"
            src={`/assets/images/payments/${rightImg}.svg`}
            alt="payment-method"
          />
        )}
      </div>
      <div className="ds_box">{children}</div>
    </div>
  );
};

export const SectionContent = ({ label, value, btnLabel, isHour, method, valueCopied }) => {
  const color = isHour ? "#999999" : "#6C60E0";
  const [copied, setCopied] = React.useState(false);
  return (
    <div id="section_content">
      <div>
        <p>{label}</p>
        <p>{value}</p>
      </div>
      {
        btnLabel && isHour &&
        <button disabled={isHour} style={{ borderColor: color }}>
          <p style={{ color: color }}>{btnLabel}</p>
        </button>
      }
      {btnLabel && !isHour && (
        <CopyToClipboard 
          text={valueCopied}
          onCopy={() => {
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 3000);
          }}
        >
          <button disabled={isHour} style={{ borderColor: color, position: "relative", height: "32px" }}>
            <p style={{ color: color }}>
              {
                !copied && 
                btnLabel
              }
              {
                copied &&
                <div style={{position:"absolute", top:"0px", padding: "5px", left:"24%"}}>
                  <CheckIcon />
                </div>
              }
            </p>
          </button>
        </CopyToClipboard>
      )}
      {method && (
        <img src={`/assets/images/payments/${method}.svg`} alt="logo-payment" />
      )}
    </div>
  );
};

export const PaymentInfo = ({ isSuccess }) => {
  return (
    <div
      id="payment_info"
      style={{
        background: isSuccess ? "#B8EEDC" : "#F5F0FF",
        borderColor: isSuccess ? "#3FA985" : "#6C60E0",
      }}
    >
      <img
        src={`/assets/images/${isSuccess ? "e_checks_green" : "e_checks"}.svg`}
        alt="check"
      />
      <p style={{ color: isSuccess ? "#000" : "#6C60E0" }}>
        {isSuccess
          ? "Pembelian Program Telah Sukses"
          : "1-Langkah Lagi Selesaikan Pembelian"}
      </p>
    </div>
  );
};

export const PaymentFixedButton = ({ label, onClick }) => {
  return (
    <div id="pf_button">
      <button onClick={onClick}>{label}</button>
    </div>
  );
};
