import axios from "axios";

export const getArticle = (articleId) => {
  return axios.get(
      process.env.REACT_APP_FETCH_URL + "/article/" + articleId
  );
}

export const getArticles = (eventId) => {
  return axios.get(
      process.env.REACT_APP_FETCH_URL + "/event_materials/" + eventId + "/article"
  );
}
