import React from "react";

export const PrioritySection = (props) => {
  const { t } = props;
  const DATALIST = [
    {
      id: 0,
      name: "praktik",
      title: t("parent-practice"),
      description: t("for-parents"),
    },
    {
      id: 1,
      name: "parenting",
      title: t("school-parenting"),
      description: t("digital-based"),
    },
    {
      id: 2,
      name: "ahli",
      title: t("handling-online"),
      description: t("child-problem"),
    },
    {
      id: 3,
      name: "research",
      title: "Research Centre",
      description: t("children-family"),
    },
    {
      id: 4,
      name: "community",
      title: "Brand Community",
      description: "Activation Program",
    },
    {
      id: 5,
      name: "game",
      title: "Titik Pintar",
      description: t("children-edu"),
    },
  ];
  return (
    <div className="primary-section">
      <div style={{ maxWidth: "1170px", margin: "auto" }}>
        <div className="parent-logo">
          <img
            src="/assets/images/fammi-logo.svg"
            className="logos"
            alt="logos"
          />
        </div>
        <div className="flex w-full justify-between mo-flex-col-reverse">
          <div className="w-75-percent mo-w-full">
            <div className="mo-flex mo-justify-center mo-mt-6 mo-mb-4">
              <img
                src="/assets/images/fammi-logo.svg"
                className="logos"
                alt="logos"
              />
            </div>
            <h1>Education Ecosystem for Child Development</h1>
            <div className="flex items-center mt-10 mo-flex-col mo-mt-0">
              <div className="indicator-border" />
              <div>
                <h3>{t("helping-parents")}</h3>
              </div>
            </div>
          </div>
          <div className="mo-flex mo-justify-center">
            <img
              src="/assets/new-home/priority.png"
              alt="vector"
              className="priority-vec"
            />
          </div>
        </div>
        <div className="flex w-full justify-between mt-20 mo-flex-col mo-mt-6">
          <div className="bg-main rounded-md p-4 w-23-percent mo-w-full mo-mb-4">
            <div className="flex h-full">
              <h1 className="text-white h1-custom">6</h1>
              <div className="ml-4 flex flex-col justify-between h-full pb-9 mo-pt-4 mo-pb-4">
                <h4 className="text-white">{t("featured-product")}</h4>
                <div className="flex items-center cursor-pointer">
                  <p className="text-white">{t("read-more")}</p>
                  <img
                    src="/assets/images/arrow-right-white.svg"
                    alt="arrow-white"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between flex-wrap w-75-percent mo-flex-col mo-w-full">
            {DATALIST.map((data, index) => (
              <div
                key={data.id}
                className={`border-main rounded-md p-4 h-fits w-32-percent mo-w-full ${
                  index < 3 && "mb-4"
                } mo-mb-4`}
              >
                <div className="flex items-center">
                  <img
                    src={`/assets/new-home/${data.name}.svg`}
                    alt="icon-vec"
                  />
                  <div className="ml-4">
                    <p className="font-bold">{data.title}</p>
                    <p>{data.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
