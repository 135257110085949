import React from "react";
import { useHistory } from "react-router-dom";
import "./ProfileMenuList.css";
import moment from "moment";
import { useTranslation } from "react-i18next";

const MenuButton = ({ label, img, onClick }) => {
  return (
    <div className="pm_menu" onClick={onClick}>
      <img src={`/assets/images/${img}.svg`} alt={img} />
      <p>{label}</p>
    </div>
  );
};

const LangMenuButton = ({ img, onClick }) => {
  return (
    <div className="pm_menu" >
      <img src={`/assets/images/${img}.svg`} alt={img} />
      <div style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
        <p onClick={()=>onClick('id')}>Bahasa</p>&nbsp;
        <span style={{color: "#999999"}}> • </span>&nbsp;
        <p onClick={()=>onClick('en')}>English</p>
      </div>
    </div>
  );
};

export const ProfileMenuList = ({
  eventCode,
  eventData,
  isSidebarMode,
  name,
  email,
  memberType, 
  setExpired, 
  expired,
  setPremiumDialog,
  activePackages
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const PROFILE_MENU = [
    {
      id: 0,
      name: t("my-profile"),
      imgKey: "menu-profile",
      path: "/profile",
      action: null,
    },
    {
      id: 1,
      name: t("my-report"),
      imgKey: "menu-raport",
      path: null,
      action: () => {
        window.open(`https://report.fammi.ly/digital-raport/${localStorage.getItem("token")}/${eventData?.tenantId}/${eventData?.eventId}`, "_blank");
      }
    },
    {
      id: 2,
      name: t("my-questions"),
      imgKey: "menu-qna",
      path: "/pertanyaansaya",
      action: null,
    },
  ];
  
  const SIDEBAR_MENU = [
    {
      id: 11,
      name: t("schedules-webinar"),
      imgKey: "menu-schedule",
      path: "/schedule",
      action: null,
    },
    {
      id: 12,
      name: t("record-webinar"),
      imgKey: "menu-video",
      path: "/schedule",
      action: null,
    },
    {
      id: 13,
      name: t("certificate-webinar"),
      imgKey: "menu-certificate",
      path: "/certificate",
      action: null,
    },
  ];

  if (eventData?.isScreening === "true" && isSidebarMode) {
    PROFILE_MENU.push({
      id: 21,
      name: "Tes Screening",
      imgKey: "menu-screening",
      path: "/screening",
      action: null,
    });
  }

  return (
    <div
      id="profile_menu_list"
      style={
        isSidebarMode
          ? {
              border: "1px solid #EAEAEA",
              borderRadius: "12px",
            }
          : {}
      }
    >
      <div
        className="pm_box"
        style={{
          position: isSidebarMode ? "unset" : "absolute",
          width: isSidebarMode ? "100%" : "240px",
          background: isSidebarMode ? "none" : "#fff",
        }}
      >
        <div className="pm_head">
          <h4>{name}</h4>
          <p>{email}</p>
        </div>
        <div className="pm_list">
          {PROFILE_MENU.map((menu) => (
            <MenuButton
              key={menu.id}
              label={menu.name}
              img={menu.imgKey}
              onClick={async () => {
                if (!menu.path) {
                  menu.action();
                } else {
                  if (expired && menu.id > 0) {
                    return setPremiumDialog(true);
                  }
                  
                  if (eventData?.isPaid !== "true" && memberType && memberType !== "payforMicrosite" && menu.id > 0) {
                    setExpired(false);
                    return setPremiumDialog(true);
                  }

                  history.push(`/e/${eventCode}${menu.path}`);
                }
              }}
            />
          ))}
        </div>

        {isSidebarMode && (
          <div
            className="pm_logout"
            style={{ borderBottom: "1px solid #eaeaea" }}
          >
            {SIDEBAR_MENU.map((menu) => (
              <MenuButton
                key={menu.id}
                label={menu.name}
                img={menu.imgKey}
                onClick={async () => {
                  if (!menu.path) {
                    menu.action();
                  } else {
                    history.push(`/e/${eventCode}${menu.path}`);
                  }
                }}
              />
            ))}
          </div>
        )}

        <div className="pm_logout">
          {
            (
              (activePackages?.length === 0 ||
              !activePackages ||
              (
                moment(activePackages?.[0]?.limitTime).isBefore(moment())
              )) && 
              <MenuButton
                label={
                  `Non Premium`
                }
                img="medal_star"
              />
            )
          }
          {
            activePackages?.length > 0 &&
            moment(activePackages?.[0]?.limitTime).isAfter(moment()) &&
            <MenuButton
              label={
                `Premium s.d ${activePackages?.[0]?.limitTime}`  
              }
              img="medal_star"
            />
          }
          <LangMenuButton img="language_square" 
          onClick={(param) => {
            localStorage.setItem("lang", param);
            window.location.reload(true);
          }}/>
          <MenuButton
            label={t('logout')}
            img="e_logouts"
            onClick={() => {
              localStorage.setItem("token", "");
              localStorage.clear();
              window.location.href = "/e/" + eventCode;
            }}
          />
        </div>
      </div>
    </div>
  );
};
