import React from "react";
import { withRouter } from "react-router-dom";
import Carousels from "../Carousels";
import CardNewEpisode from "../NewComponents/NewCardList";
import "./NewEpisode.css";
function NewEpisode(props) {
  const { data, isLogin, setOpenLogin } = props;

  return (
    <div className="container-topepisode">
      <Carousels
        numberOfShow={5}
        id="neweps"
        showDots
        title="Pertanyaan Terbaru"
        path="/episode/newepisode"
        dataLength={data?.length}
      >
        {data?.map((item, index) => (
          <CardNewEpisode
            data={item}
            push={() => {
              if (!isLogin) {
                setOpenLogin(true);
                return;
              }
              props.history.push({
                pathname: "/playlist/newepisode/" + item?.playlistId,
                search: "?audioIds=" + item?.playlistId,
              });
            }}
            key={index}
            mode={"newepisode"}
          />
        ))}
      </Carousels>
    </div>
  );
}

export default withRouter(NewEpisode);
