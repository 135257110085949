import React from "react";
import { withRouter } from "react-router";
import "./NewCardList.css";
import moment from "moment";
import "moment-duration-format";
function formatDuration(durations) {
  durations = durations / 1000;
  return moment
    .duration(durations, "minutes")
    .format(durations > 60 ? "hh [jam] mm [menit]" : "mm [Menit]", {
      trim: false,
    });
}

function NewCardList(props) {
  const { data, style, imgStyle, push, covers, className } = props;
  if (data) {
    return (
      <div
        onClick={push}
        className={"parent-card-playlists " + (className ? className : ' non-classname')}
        style={style}
      >
        <img
          src={covers ? covers : data?.cover}
          alt="img-playlist"
          className="img-playlists"
          id="img-playlist"
          style={imgStyle}
        />

        <>
          <p className="desktop">
            {" "}
            {data?.speaker_name?.length >= 25
              ? data?.speaker_name?.slice(0, 25) + "..."
              : data?.speaker_name}
          </p>
          <p className="mobile">
            {" "}
            {data?.speaker_name?.length >= 15
              ? data?.speaker_name?.slice(0, 15) + "..."
              : data?.speaker_name}
          </p>
        </>

        <div>
          <p>Pelajari hanya {formatDuration(data?.duration)}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="parent-card-playlists" style={style}>
        <img
          src="https://i.ibb.co/CB5Scsq/Rectangle-679.png"
          alt="img-playlist"
          className="img-playlists"
          id="img-playlist"
          style={imgStyle}
        />
      </div>
    );
  }
}
export default withRouter(NewCardList);
