import React from "react";
import { useMediaQuery } from "react-responsive";
import formatDuration from "../../helpers/format-duration";
import closeGreyIcon from "../../images/close-grey.svg";
import "./AudioPlayerNewLayout.css";

export const AudioPlayerNewLayout = ({
  isLoading,
  audio,
  titleAudio,
  handlePlayAudio,
  isPlay,
  currentTime,
  duration,
  handleTimeDrag,
  onClose,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const curPercentage = (currentTime / duration) * 100;
  const hideStyles = { display: isMobile ? "none" : "flex" };

  const renderAudioBar = (
    <div
      className="parent-indicator audio-play"
      onMouseDown={(e) => handleTimeDrag(e)}
    >
      {duration !== 0 && (
        <div
          style={{ width: `${curPercentage}%` }}
          className="indicator"
          onMouseDown={(e) => handleTimeDrag(e)}
        />
      )}
      <div
        className="circle"
        onMouseDown={(e) => handleTimeDrag(e)}
        style={hideStyles}
      />
    </div>
  );

  return (
    <div id="audio_player">
      <audio id="audio">
        <source id="audioSrc" src={audio?.file} />
      </audio>
      {isLoading ? (
        <div
          className="loads audio_wrapper"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="audio_wrapper">
          <div className="audio_row_styles">
            <div className="audio_row_styles">
              <img
                onClick={handlePlayAudio}
                src={
                  isPlay
                    ? "/assets/images/pause.svg"
                    : "/assets/images/play.svg"
                }
                alt="pause"
              />
              <div className="audio_info audio_row_styles">
                <img
                  className="audio_image"
                  src="https://fammi.ly/uploads/audio_covers/images/002f1a759d9c0823bc35f1aea8121d30.png"
                  style={{ display: isMobile ? "none" : "block" }}
                  alt="audio-audio-cover"
                />
                <div>
                  <p className="audio_titles">
                    {titleAudio ? titleAudio : audio.titles}
                  </p>
                  {audio.speakers[0]?.name && (
                    <p className="audio_description">
                      {audio.speakers[0]?.name}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div
              className="section_audios_bar audio_row_styles"
              style={hideStyles}
            >
              <p className="text_times_audios" style={hideStyles}>
                {formatDuration(currentTime)}
              </p>
              {!isMobile && renderAudioBar}
              <p className="text_times_audios" style={hideStyles}>
                {formatDuration(duration)}
              </p>
            </div>
            <button className="close_audio_button" onClick={onClose}>
              <img src={closeGreyIcon} alt="close" />
            </button>
          </div>
          {isMobile && renderAudioBar}
        </div>
      )}
    </div>
  );
};
