import React from "react";
import "./ArticlePrograms.css";

function ArticlePrograms(props) {
  const { open, handleClose, data } = props;

  return (
    <>
      {open && (
        <div id="article-program">
          {
              data?.cover &&
              data?.cover!== "undefined" &&
              <div className="img-bkg mobile" style={{backgroundImage: `url("https://fammi.ly/uploads/articles/images/${data?.cover}")`, borderRadius: "12px"}} />
          }
          <div style={{ padding: 16, background: "#fff", border: "solid 1px #EAEAEA", borderRadius: "12px" }}>
            <div className="heads">
              <div className="main">
                {
                  data?.duration && data?.duration !== "undefined" &&
                  <div className="item-heads">
                    <img src="/assets/programs/clock.svg" alt="clockicon" />
                    <p>Hanya {data?.duration}</p>
                  </div>
                }
                {
                  data?.tags && data?.tags !== "undefined" &&
                  <div className="item-heads-mains">
                  <img src="/assets/programs/category.svg" alt="categoryicon" />
                  <p>{data?.tags}</p>
                </div>
                }
              </div>
              <img
                style={{ cursor: "pointer" }}
                onClick={handleClose}
                className={"close_button"}
                src="/assets/programs/close.svg"
                alt="closeicon"
              />
            </div>
            <h1>{data?.title}</h1>
            {
              data?.cover &&
              data?.cover!== "undefined" &&
              <div className="img-bkg" style={{backgroundImage: `url("https://fammi.ly/uploads/articles/images/${data?.cover}")`, borderRadius: "12px"}} />
            }
            <div className="author-sec" style={{display:"none"}}>
              <img
                src="https://cdn-icons-png.flaticon.com/512/147/147142.png"
                alt="ava-author"
              />
              <div>
                <h5>Erika Kamaria Yamin</h5>
                <p>
                  Penulis Buku-buku Parenting, Penulis buku anak, Praktisi
                  Homeschooling
                </p>
              </div>
            </div>
            <div className="bodys" dangerouslySetInnerHTML={{__html:data?.detail?.replace("\r", "<br/>")}}>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ArticlePrograms;
