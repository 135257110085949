import moment from "moment";
import React from "react";
import { withRouter } from "react-router";
import { getMaterialDetail, updateState } from "../../services/packages";
import "./ListPrograms.css";

function ListPrograms(props) {
  const {
    data,
    isNotEnroll,
    setOpenDialog,
    program,
    eventCode,
    setAudioId,
    setTheLimitTime,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [detailId, setDetailid] = React.useState(undefined);
  const [materials, setMaterials] = React.useState([]);
  const [indexDone, setIndexDone] = React.useState(0);

  React.useEffect(() => {
    // chosenMaterials
    if (materials && materials[indexDone]) {
      materials[indexDone].state = "done";
    }
    setMaterials([...materials]);
  }, [indexDone]);

  React.useEffect(() => {
    if (!open || !detailId) {
      return;
    }

    getMaterialDetail(
      program?.packageId,
      localStorage.getItem("token"),
      detailId
    ).then((d) => {
      setMaterials(d?.data);

      if (d?.data[0] && d?.data[0].state === null) {
        setOpenDialog(true);
      }
    });
  }, [open, detailId]);

  return (
    <div id="list-programs">
      <div className="head">
        <div className="main">
          {data?.tags && (
            <div className="material">
              <img
                src="/assets/images/program_materi.svg"
                alt="main-material"
              />
              <p>{data?.tags}</p>
            </div>
          )}
          {data?.duration && (
            <div className="timming">
              <img src="/assets/images/yellow_clock.svg" alt="Yellows" />
              <p>{data?.duration}</p>
            </div>
          )}
        </div>
        <button
          onClick={() => {
            if (data?.state !== null) {
              setOpen(!open);
              setDetailid(data?.detailId);
            } else {
              setOpenDialog(true);
            }
          }}
        >
          <p>{open ? "Tutup" : "Lihat"}</p>
          <img
            src={
              data?.state === null
                ? "/assets/programs/lock.svg"
                : "/assets/images/down_white.svg"
            }
            alt="down"
            style={{ transform: open && "rotate(180deg)" }}
          />
        </button>
      </div>
      <h5>{data?.name}</h5>
      <div className="bottom">
        <p>
          {(data?.articleList?.split(",")?.length ?? 0) +
            (data?.audioList?.split(",")?.length ?? 0) +
            (data?.panduanList?.split(",")?.length ?? 0)}{" "}
          Materi • &nbsp;
          {data?.quizList?.split(",")?.length ?? 0} Kuis • &nbsp;
          {data?.practiceList?.split(",")?.length ?? 0} Praktek
        </p>
      </div>

      <div
        style={{
          height: !open ? 0 : "auto",
          overflow: !open && "hidden",
          padding: !open && 0,
          borderTop: !open && "none",
          marginTop: !open && "none",
        }}
        className="details"
      >
        {materials?.map((a, index) => (
          <div key={a?.detailId ?? Math.random()} className="item-details">
            <div>
              <img
                src={
                  a?.state === "done"
                    ? "/assets/programs/check.svg"
                    : "/assets/programs/uncheck.svg"
                }
                alt="icon-details"
              />
              <p>
                {a?.audioList && "Audio Materi"}
                {a?.videoList && "Video Materi"}
                {a?.articleList && "Artikel Materi"}
                {a?.quizList && "Kuis"}
                {a?.practiceList && "Praktek"}
                {a?.panduanList && "Panduan"}
              </p>
            </div>
            <button
              onClick={async () => {
                if (
                  a?.limitTime &&
                  a?.limitTime !== "0000-00-00" &&
                  moment().format("YYYY-MM-DD") <
                    moment(a?.limitTime).format("YYYY-MM-DD")
                ) {
                  setTheLimitTime(a?.limitTime);
                  setOpenDialog(true);
                  return;
                }

                setTheLimitTime(undefined);

                if (a?.articleList) {
                  await updateState(a.detailId);
                  setIndexDone(index);
                  props.history.push(
                    `/e/${eventCode}/article/${program.packageId}/${a.articleList}`
                  );
                }

                if (a?.panduanList) {
                  await updateState(a.detailId);
                  setIndexDone(index);
                  props.history.push(
                    `/e/${eventCode}/article/${program.packageId}/${a.panduanList}/panduan`
                  );
                }

                if (a?.quizList) {
                  props.history.push(
                    `/e/${eventCode}/quiz/${a?.quizList}/activity/${a.detailId}/${program.packageId}`
                  );
                }

                if (a?.practiceList) {
                  props.history.push(
                    `/e/${eventCode}/quiz/${a?.practiceList}/activity/${a.detailId}/${program.packageId}`
                  );
                }

                if (a?.audioList) {
                  await updateState(a.detailId);
                  setIndexDone(index);
                  setAudioId(a?.audioList);
                }
              }}
            >
              <img
                src={
                  a?.audioList
                    ? "/assets/programs/play.svg"
                    : a?.videoList
                    ? "/assets/programs/video_brand.svg"
                    : a?.articleList || a?.panduanList
                    ? "/assets/programs/read.svg"
                    : "/assets/programs/pen.svg"
                }
                alt="icon-cta"
              />
              <p>
                {a?.audioList && "Dengarkan"}
                {a?.videoList && "Tonton"}
                {a?.articleList && "Baca"}
                {a?.panduanList && "Baca"}
                {a?.quizList && "Kerjakan"}
                {a?.practiceList && "Kerjakan"}
              </p>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default withRouter(ListPrograms);
