import React from "react";
import { withRouter } from "react-router";

function CardPlaylist(props) {
  const { data, style, imgStyle, push } = props;
  if (data) {
    return (
      <div onClick={push} className="parent-card-playlist" style={style}>
        <img
          src={data?.cover}
          alt="img-playlist"
          className="img-playlist"
          id="img-playlist"
          style={imgStyle}
        />
      </div>
    );
  } else {
    return (
      <div className="parent-card-playlist" style={style}>
        <img
          src="https://i.ibb.co/CB5Scsq/Rectangle-679.png"
          alt="img-playlist"
          className="img-playlist"
          id="img-playlist"
          style={imgStyle}
        />
      </div>
    );
  }
}
export default withRouter(CardPlaylist);
