import React from "react";
import "./FillQna.css";

function FillQna(props) {
  const {
    qna,
    setQna,
    eventData,
    profileRules,
    qnaFormStatus,
    isImportant,
    caption,
    isWebinarRegistration,
    setProfile,
    profile,
    profileMember, 
    isValid,
    isWAEmpty
  } = props;

  return (
    <div className="fq_parent">
      <div className="fq_container" style={{ paddingLeft: 0 }}>
        <div style={{ display: "flex" }}>
          <span
            style={{
              background: "#6C60E0",
              borderRadius: "0px 4px 4px 0px",
              height: "42px",
              width: "4px",
              marginRight: 12,
              marginLeft: 0,
            }}
          />
          <h1 className="tx_fq_title">
            {isImportant &&
              "Yuk tuliskan pertanyaan seputar parenting mu di sini"}
            {!isImportant && (
              <div>
                Tuliskan pertanyaan seputar tema webinar
                <br />
                Kosongkan jika tidak ada
              </div>
            )}
          </h1>
        </div>
        <div style={{ paddingLeft: 16 }}>
          <p className="tx_fq_desc">
            {!caption &&
              profileRules &&
              Object.keys(profileRules).map((key, i) => {
                if (key === "caption") {
                  return profileRules[key];
                }
              })}
          </p>
          <div className="fp_alert">
            <img
              src="/assets/images/e_alert.svg"
              alt="alerts"
              style={{ width: 16, height: 16 }}
            />
            <p>Tambahkan keterangan jenis kelamin dan usia</p>
          </div>
          <textarea
            className={`txar_fq ${
              // qnaFormStatus?.indexOf("question") > -1
              !isValid 
                ? `mandatory`
                : `not-mandatory`
            }`}
            placeholder="Tulis pertanyaan dengan rinci ya"
            onChange={(e) => {
              setQna({
                ...qna,
                question: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="fq_container" style={{ paddingLeft: 0 }}>
        <div style={{ display: "flex" }}>
          <span
            style={{
              background: "#6C60E0",
              borderRadius: "0px 4px 4px 0px",
              height: "42px",
              width: "4px",
              marginRight: 12,
              marginLeft: 0,
            }}
          />
          <h1 className="tx_fq_title">
            Pilih kategori masalah yang kamu tanyakan ya!
          </h1>
        </div>
        <div style={{ paddingLeft: 16 }}>
          <div className="fp_alert">
            <img
              src="/assets/images/e_alert.svg"
              alt="alerts"
              style={{ width: 16, height: 16 }}
            />
            <p>Boleh pilih lebih dari satu kategori</p>
          </div>
          <div className="wrapper_fq">
            {[
              { name: "Emosi", id: 1 },
              { name: "Komunikasi", id: 2 },
              { name: "Masalah Belajar", id: 3 },
              { name: "Perilaku Anak", id: 4 },
            ]?.map((d) => (
              <div
                onClick={() => {
                  const categories = qna?.categories ? qna?.categories : [];
                  if (categories.indexOf(d?.name) > -1) {
                    const newCategory = categories.filter((s) => s !== d?.name);
                    setQna({
                      ...qna,
                      categories: [...newCategory],
                    });
                    return;
                  }
                  setQna({
                    ...qna,
                    categories: [...categories, d?.name],
                  });
                }}
                className={`fq_select${
                  qna?.categories?.indexOf(d?.name) > -1 ? "_active" : ""
                }
                ${
                  qnaFormStatus?.indexOf("categories") > -1 ? "mandatory" : ""
                }`}
                key={d?.id}
              >
                <img
                  src={`/assets/images/fp_checks${
                    qna?.categories?.indexOf(d?.name) > -1 ? "_active" : ""
                  }.svg`}
                  alt="checks"
                  style={{ width: 16, height: 16 }}
                />
                <p className="fq_btn_label">{d?.name}</p>
              </div>
            ))}
          </div>
          <input
            className={`inp_item ${
              qnaFormStatus?.indexOf("categories") > -1 ? `mandatory` : ``
            }`}
            placeholder="Perlu kategori lainnya? Tulis di sini ya"
            onBlur={(e) => {
              const categories = qna?.categories ? qna?.categories : [];
              setQna({
                ...qna,
                categories: [...categories, e.target.value],
              });
            }}
          />
        </div>
      </div>
      {profileRules?.no_whatsapp === "visible" && !profileMember?.no_whatsapp && (
        // qna.sendWa &&
        <div className="fq_container" style={{ paddingRight: 16 }}>
          <h1 className="tx_fq_title">
            Masukkan no WhatsApp untuk mendapatkan notifikasi jawaban
          </h1>
          <input
            className={`inp_item ${
              isWAEmpty ? `mandatory`: ``
            }`}
            placeholder="Tuliskan nomor WhatsApp kamu di sini"
            onChange={(e) => {
              setProfile({
                ...profile,
                no_whatsapp: e.target.value,
              });
            }}
          />
        </div>
      )}
      {/* <div className="fq_container" style={{ padding: padContainer }}>
        <h1 className="tx_fq_title">
          Jika jawaban sudah tersedia, mau dikirim langsung ke WhatsApp?
        </h1>
        this is whatsapp
      </div> */}
    </div>
  );
}

export default FillQna;
