import axios from "axios";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";

function RegisterEvent(props) {
  const { tenantId, setStep, setOpenLogin, setIdl } = props;
  const [form, setForm] = useState({
    email: "",
    name: "",
    password: "",
    phone: "",
    tenantId: tenantId,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [registerError, setRegisterError] = useState(false);

  const changeText = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleRegister = async () => {
    setIsLoading(true);
    await axios
      .post(
        process.env.REACT_APP_FETCH_URL + "/partner_mc4zntyzodmyoda4odm0odmwmw",
        {
          email: form?.email,
          password: form?.password,
          tenantId: form?.tenantId,
          name: form?.name,
          phone: new Date().getTime(),
        }
      )
      .then((res) => {
        if (res?.data && res.data.indexOf(" ") < 0) {
          const redirectUrl = localStorage.getItem("c892072");
          localStorage.clear();
          localStorage.setItem("token", res.data);
          localStorage.setItem("name", form.name);
          localStorage.setItem("a01c12", form.tenantId);
          if (redirectUrl) {
            window.location.href = redirectUrl;
            return;
          }
          setOpenLogin(false);
          setIdl(0);
          return;
        }

        setIsLoading(false);
        setStep(4);
      })
      .catch((err) => {
        if (err) {
          setIsLoading(false);
          setRegisterError(true);
        }
      });
  };
  return (
    <div className="form-register">
      <div className="form-item">
        <label>Isi Nama Lengkap</label>
        <div
          style={{
            borderColor: registerError && "#E85050",
          }}
        >
          <input
            name="name"
            onChange={(e) => changeText(e)}
            type="text"
            value={form.name}
            placeholder="Masukkan nama lengkap"
          />
        </div>
        {registerError && (
          <p
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: 12,
              lineHeight: "14px",
              color: "#E85050",
              margin: "unset",
              marginTop: 8,
            }}
          >
            {registerError}
          </p>
        )}
      </div>
      <div className="form-item">
        <label>Masukkan Email</label>
        <div
          style={{
            borderColor: registerError && "#E85050",
          }}
        >
          <input
            name="email"
            onChange={(e) => changeText(e)}
            type="email"
            value={form.email}
            placeholder="Masukkan email"
          />
        </div>
        {registerError && (
          <p
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: 12,
              lineHeight: "14px",
              color: "#E85050",
              margin: "unset",
              marginTop: 8,
            }}
          >
            {registerError}
          </p>
        )}
      </div>
      <div className="form-item">
        <label>Buat Password</label>
        <div
          style={{
            borderColor: registerError && "#E85050",
          }}
        >
          <input
            name="password"
            onChange={(e) => changeText(e)}
            type={showPassword ? "text" : "password"}
            value={form.password}
            placeholder="Masukkan password"
          />
          <img
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            src={
              showPassword
                ? "/assets/images/eye-off.svg"
                : "/assets/images/eyes.svg"
            }
            alt="password"
          />
        </div>
        {registerError && (
          <p
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: 12,
              lineHeight: "14px",
              color: "#E85050",
              margin: "unset",
              marginTop: 8,
            }}
          >
            {registerError}
          </p>
        )}
      </div>
      <div className="kebijakan">
        <p>
          Dengan membuat akun FAMMI, Anda telah menyetujui{" "}
          <a href="/ketentuan">Ketentuan Layanan</a> dan{" "}
          <a href="/kebijakan">Kebijakan Data</a>
        </p>
      </div>
      <button
        disabled={
          !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
            form?.email
          ) ||
          form?.email === "" ||
          form?.name === "" ||
          form?.password === ""
        }
        style={{
          backgroundColor:
            !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
              form?.email
            ) ||
            form?.email === "" ||
            form?.name === "" ||
            form?.password === ""
              ? "#999"
              : "#6C60E0",
        }}
        onClick={handleRegister}
        className="btn-daftar"
      >
        {isLoading ? "Loading..." : "Daftar"}
      </button>
      <button onClick={() => setStep(0)} className="btn-back">
        Kembali
      </button>
    </div>
  );
}
export default withRouter(RegisterEvent);
