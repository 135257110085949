import axios from "axios";
import MD5 from "md5";
import React, { useMemo } from "react";
import { useMediaQuery } from "react-responsive";

export const AskQuestionContainer = ({
  children,
  isQuestionSubmitted,
  question,
  eventData,
  setIsQuestionSubmitted,
  setAskQuestion,
  profile,
  speaker,
  setIsValid,
  setIsWAEmpty
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const renderButton = useMemo(() => {
    const sendQuestion = async () => {
      let isValid = true;

      if (question?.question === "" || !question?.question) {
        isValid = false;
      }

      // if (!question?.categories || !question?.categories) {
      //   isValid = false;
      // }

      // if (question?.categories && question?.categories.join("") === "") {
      //   isValid = false;
      // }

      if (!isValid) {
        // setAskQuestion(false);
        setIsValid(false);
        return;
      }

      if (!profile?.no_whatsapp || profile?.no_whatsapp === "") {
        return setIsWAEmpty(true); 
      }

      submitProfile(localStorage.getItem("token"));

      const questionData = {
        questionId: MD5(new Date().getTime()),
        categories: question.categories?.join(",")+" ",
        memberId: localStorage.getItem("token"),
        scheduleId: null,
        eventId: eventData.eventId,
        tenantId: eventData.tenantId,
        question: question.question,
        tags: `Event ${eventData?.name}`
      };

      if (speaker?.speakerId) {
        questionData.speakerId = speaker?.speakerId;
      }
      
      if (speaker?.speakerName) {
        questionData.tags += `, Speaker: ${speaker?.speakerName}` ;
      }

      await axios
        .post(process.env.REACT_APP_FETCH_URL + "/questions", {
          ...question,
          questionId: MD5(new Date().getTime()),
          categories: question.categories.join(","),
          memberId: localStorage.getItem("token"),
          scheduleId: null,
          eventId: eventData.eventId,
          tenantId: eventData.tenantId,
          question: question.question,
          tags: `Event ${eventData?.name}`
        })
        .then(() => {
          setIsQuestionSubmitted(true);
        })
        .catch(() => {
          setAskQuestion(false);
        });
    };

    const submitProfile = async (token) => {
      await axios
        .post(process.env.REACT_APP_FETCH_URL + "/profile", {
          memberId: token,
          profileData: JSON.stringify(profile),
        })
    };

    const close = () => {
      setAskQuestion(false);
      setIsQuestionSubmitted(false);
    };

    return (
      <button
        className="button-submit-question"
        style={{ marginTop: "0px" }}
        onClick={isQuestionSubmitted ? close : sendQuestion}
      >
        {isQuestionSubmitted ? "Tutup" : "Kirim Pertanyaan"}
      </button>
    );
  }, [isQuestionSubmitted, question, profile]);

  return (
    <div className="ask-question-container">
      <div
        className="pop_boxes"
        style={{
          flexDirection: "column",
          background: "#fff",
        }}
      >
        <div
          className="ask-question-header"
          style={{
            display: isQuestionSubmitted && !isMobile ? "none" : "flex",
          }}
        >
          <div onClick={()=>{
            setAskQuestion(false);
            setIsQuestionSubmitted(false);
          }}>
            <img src="/assets/images/e_close.svg" alt="close"/>
          </div>
          <h1>Tanya Ahlinya Fammi</h1>
        </div>
        <div className="ask-question-content">{children}</div>
        <div className="ask-question-footer">{renderButton}</div>
      </div>
    </div>
  );
};
