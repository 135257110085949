import React from "react";

function DescriptionsSuccess() {
  return (
    <React.Fragment>
      <div style={{fontFamily:"Rubik", fontWeight: 500, fontSize: "22px", lineHeight: "26px", display: "flex"}}>
                  <div style={{marginRight: "12px"}}>
                    <img src={'/assets/images/hiber1-success-icon.svg'} />
                  </div>
                  <div style={{fontSize: "16px"}}>
                    Pendaftaran Sukses. Data Anda telah disimpan sebagai Calon Peserta Program Guru Terampil
                  </div>
                </div>
                <div style={{marginTop: "24px", fontFamily:"Rubik", fontWeight: 400, fontSize: "14px", lineHeight: "24px"}}>
                Terima kasih atas kesedian Anda mengisi formulir pendaftaran Program Guru Terampil. Selanjutnya mohon untuk menunggu pengumuman seleksi untuk 5.000 guru terpilih.
                </div>
                <div style={{marginTop: "32px"}}>
                  <img src={'/assets/images/guru-terampil-cover-success.png'} style={{width:"100%"}}/>
                </div>
                <div style={{ fontFamily:"Rubik", fontWeight: 500, fontSize: "14px", lineHeight: "16.59px", color:"#6C60E0", background: "#F5F0FF", borderRadius: "12px", padding: "16px", textAlign: "center", margin: "32px 0"}}>
                  PENGUMUMAN GURU TERPILIH Fammi akan mengumumkan 5.000 guru terpilih melalui akun Instagram Fammi @fammiofficial hari Senin, 27 Feb 2022
                </div>
    </React.Fragment>
  );
}

export default DescriptionsSuccess;
