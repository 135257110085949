import React from "react";

export const ResearchSection = ({ t }) => {
  return (
    <div style={{ background: "#FFF" }}>
      <div
        className="research-section flex items-center justify-between mo-flex-col-reverse mo-justify-start"
        style={{ maxWidth: "1170px", margin: "auto", padding: "94px 10px" }}
      >
        <div className="w-50-percent mo-w-full mo-mt-6">
          <h1 className="mb-4">Research Centre</h1>
          <h2 className="mb-8 mo-mb-4">{t("are-researcher")}</h2>
          <p>{t("depth-research")}</p>
          <button
            className="bg-main rounded border-none text-white font-bold flex items-center justify-center btn-look mt-13 mo-w-full mo-mt-6"
            onClick={() => {
              window.open("https://wa.me/6282121006788", "_blank");
            }}
          >
            <img src="/assets/new-home/wa.svg" alt="wa" className="mr-3" />
            <span>{t("contact-adm")}</span>
          </button>
        </div>
        <img
          src="/assets/new-home/research-vector.svg"
          alt="research-vec"
          className="img-research"
        />
      </div>
    </div>
  );
};
