import React from "react";
import moment from "moment";
import {
  DetailSection,
  PaymentFixedButton,
  PaymentInfo,
  SectionContent,
} from "./DetailSection";
import "./PaymentSuccess.css";

export const PaymentSuccess = (props) => {
  const {product, transaction, productFromTransaction, additionalContent, event} = props;

  const bankName = {
    "mandiri": "VA Bank Mandiri",
    "bca": "VA Bank BCA",
    "bri": "VA Bank BRI",
    "permata": "VA Bank Permata",
    "bni": "VA Bank BNI",
    "gopay": "Gopay",
    "shopeepay": "Shopeepay",
  }
  return (
    <div id="payment_success" style={{border: "1px solid #EAEAEA", borderRadius: "12px"}}>
      <PaymentInfo isSuccess />
      <DetailSection title="Detail Pembelian">
        <div className="ps_program">
          {
            additionalContent?.desktopBanner &&
            <img src={`https://fammi.ly/uploads/events/images/${additionalContent?.desktopBanner}?v=${Math.random()}`} alt="fee" style={{marginRight: "12px", width: "64px", height: "64px", borderRadius: "12px"}}/>
          }
          <div>
            <p>
              {/* {additionalContent?.title} */}
              {
                event?.name
              }
            </p>
            {
              transaction?.amount && 
              <p style={{ color: "#6C60E0", marginTop: 6 }}>Rp.{parseInt(transaction.amount).toLocaleString("id-ID")},-</p>
            }
          </div>
        </div>
        <hr />
        <SectionContent label="Metode Pembayaran" value={bankName[transaction?.paymentMethod]} />
        <hr />
        <SectionContent
          label="Waktu Pembayaran"
          value={`${moment(transaction?.created).add(1, "days").format("DD MMM YYYY HH:mm:SS")} WIB`}
          method={transaction?.paymentMethod}
        />
      </DetailSection>

      {
        productFromTransaction?.notes &&
        productFromTransaction?.notes !== "undefined" &&
        <DetailSection title="Catatan untuk Pembeli">
          <p className="ps_notes">
          {/* <span style={{color:"#6C60E0"}}>Ya Ndak Tau Kok Tanya yang Tanya Saya</span> */}
          {
            productFromTransaction?.notes
          }
          </p>
        </DetailSection>
      }
      <PaymentFixedButton
        label="Kembali ke Beranda"
        onClick={() => {
          window.location.href = `/e/${localStorage.getItem("eventCode")}`;
        }}
      />
    </div>
  );
};
