import React from "react";
import Images from "../ImagesComponent/Images";
import "../css/HomeSlider.css";
function HomeHeader(props) {
  const { click } = props;
  return (
    <div className="container-maincarousel">
      <div
        id="fammi-maincarousel"
        className="iqbal-ganti home-slider"
        style={{ paddingBottom: "0px", background: "none" }}
      >
        <div id="myCarousel" className="carousel" data-ride="carousel">
          <div className="">
            <div className="carousel-inner">
              <div className="item active">
                <div className="item-carousel parent-home-h">
                  <div className="one-home-h">
                    <img
                      src="/assets/images/home-header-logo.svg"
                      alt="logos"
                    />
                    <h1>Layanan Tanya Jawab Seputar Parenting</h1>
                    <p className="p-home-h">
					Apapun pertanyaan seputar masalah parenting dan keluarga, Tanyakan dan temukan jawabannya di Fammi.
                    </p>
                    <button onClick={click}>
                      <p>Kirim Pertanyaan</p>
                      <div>
                        <img src="/assets/images/mail.svg" alt="mail" />
                      </div>
                    </button>
                  </div>
                  <div className="two-home-h"></div>
                </div>
              </div>
            </div>

            <div
              className="iqbal-tambah container"
              style={{ position: "relative", display: "none" }}
            >
              <ol
                className="carousel-indicators iqbal-ganti"
                style={{ bottom: "32px", left: "95px", zIndex: "1000" }}
              >
                <li
                  data-target="#myCarousel"
                  data-slide-to="0"
                  className="active"
                  style={{ marginRight: "12px" }}
                ></li>
                <li
                  data-target="#myCarousel"
                  data-slide-to="1"
                  style={{ marginRight: "12px" }}
                ></li>
                <li
                  data-target="#myCarousel"
                  data-slide-to="2"
                  style={{ marginRight: "12px" }}
                ></li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeHeader;
