import React from "react";

export const CampaignSection = ({ t }) => {
  const DATALIST = [
    { id: 1, name: "keluarga-digital" },
    { id: 2, name: "mom-fest" },
    { id: 3, name: "seru" },
    { id: 4, name: "orangtua-berpijar" },
    { id: 5, name: "guru-terampil" },
    { id: 6, name: "internet-ceria" },
  ];
  return (
    <div className="campaign-section">
      <div style={{ maxWidth: "1170px", margin: "auto" }}>
        <h1>Program Campaign</h1>
        <h2 className="mt-4">{t("collab-prepares")}</h2>
        <div className="flex items-center justify-between mt-13 mo-mt-4 mo-flex-wrap">
          {DATALIST.map((d) => (
            <img
              src={`/assets/new-home/${d.name}.png`}
              alt={`img-${d.id}`}
              className="mo-w-half-button"
              style={{ maxWidth: "175px", maxHeight: "131px", height: "auto" }}
            />
          ))}
        </div>
        <div className="mt-13">
          <h1>Mitra Yang Telah Bekerjasama</h1>
          <img
            src="/assets/new-home/mitra-mob.png"
            alt="mitra-mob"
            className="none mo-block mo-mt-6"
          />
          <div className="flex flex-col items-center mo-none">
            <img
              src="/assets/new-home/mitra-1.png"
              alt="mitra-1"
              className="mt-4"
            />
            <img
              src="/assets/new-home/mitra-2.png"
              alt="mitra-2"
              className="mt-4"
            />
            <img
              src="/assets/new-home/mitra-3.png"
              alt="mitra-3"
              className="mt-4"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
