import React from "react";
import { withRouter } from "react-router-dom";
import Carousels from "../Carousels";
import CardEpisode from "../NewComponents/NewCardList";
import "./TopEpisode.css";

function TopEpisode(props) {
  const { data, isLogin, setOpenLogin } = props;
  return (
    <div id="topepisode" className="container-topepisode">
      <Carousels
        numberOfShow={5}
        showDots
        id="neweps"
        title="Pertanyaan Favorit"
        path="/episode/topepisode"
        dataLength={data && data.length}
      >
        {data?.map((item, index) => (
          <CardEpisode
            data={item}
            push={() => {
              if (!isLogin) {
                setOpenLogin(true);
                return;
              }
              props.history.push({
                pathname: "/playlist/topepisode/" + item?.playlistId,
                search: "?audioIds=" + item?.playlistId,
              });
            }}
            key={index}
            mode={"topepisode"}
          />
        ))}
      </Carousels>
    </div>
  );
}

export default withRouter(TopEpisode);
