import React from "react";
import "./QnaSection.css";
import CardQna from "./CardQna";
import { withRouter } from "react-router-dom";
import Carousels from "../Carousels";

function QnaSection(props) {
  const {
    data,
    isLogin,
    setOpenLogin,
    quotaQna,
    setQuotaQna,
    setOpenSubs,
  } = props;
  return (
    <div>
      {" "}
      <div className="container-topepisode">
        <Carousels
          numberOfShow={5}
          id="qna"
          showDots
          title="Sesi Tanya Jawab"
          path="/episode/qna"
          dataLength={data?.length}
        >
          {data?.map((item, index) => (
            <CardQna
              data={item}
              key={index}
              isLogin={isLogin}
              setOpenLogin={setOpenLogin}
              quotaQna={quotaQna}
              setQuotaQna={setQuotaQna}
              setOpenSubs={setOpenSubs}
            />
          ))}
        </Carousels>
      </div>
    </div>
  );
}

export default withRouter(QnaSection);
