import React from "react";
import moment from "moment";
import {
  DetailSection,
  PaymentFixedButton,
  PaymentInfo,
  SectionContent,
} from "./DetailSection";
import "./PaymentSuccess.css";

export const PaymentSuccess = (props) => {
  const {product, transaction} = props;
  const bankName = {
    "mandiri": "VA Bank Mandiri",
    "bca": "VA Bank BCA",
    "bri": "VA Bank BRI",
    "permata": "VA Bank Permata",
    "bni": "VA Bank BNI",
    "gopay": "Gopay",
    "shopeepay": "Shopeepay",
  }
  return (
    <div id="payment_success">
      <PaymentInfo isSuccess />
      <DetailSection title="Detail Pembelian">
        <div className="ps_program">
          <img src="/assets/images/program-fee.svg" alt="fee" />
          <div style={{ marginLeft: 12 }}>
            <p>Program Siap Masuk SD</p>
            <p style={{ color: "#6C60E0", marginTop: 6 }}>Rp.{(product?.price).toLocaleString("id-ID")},-</p>
          </div>
        </div>
        <hr />
        <SectionContent label="Metode Pembayaran" value={bankName[transaction?.paymentMethod]} />
        <hr />
        <SectionContent
          label="Waktu Pembayaran"
          value={`${moment(transaction?.created).add(1, "days").format("DD MMM YYYY HH:mm:SS")} WIB`}
          method={transaction?.paymentMethod}
        />
      </DetailSection>

      <DetailSection title="Catatan untuk Pembeli">
        <p className="ps_notes">
        <span style={{color:"#6C60E0"}}>Rangkaian Program Siap Masuk SD akan berlangsung pada tanggal 11 Feb - 11 Mar 23.</span>
        <br/><br/>
        Klik tombol dibawah ini untuk masuk ke webiste khusus Siap Masuk SD.
        <br/><br/>
        Selama masa menunggu, Ayah Bunda dapat mendengarkan podcast parenting dari Fammi. Tersedia pula layanan Tanya Ahli untuk bertanya seputar masalah parenting dan keluarga.
        <br/><br/>
        Silakan kontak admin Fammi di nomor WhatsApp 0821-2100-6788 jika ada yang ingin ditanyakan atau memerlukan informasi lainnya.`
        </p>
      </DetailSection>
      <PaymentFixedButton
        label="Masuk ke Web Program"
        onClick={() => {
          window.location.href = "https://siapmasuksd.fammi.ly";
        }}
      />
    </div>
  );
};
