import React, { useState } from "react";
import md5 from "md5";
import { addToCart, getVa, setVa } from "../../services/transactions";
import "./BoxPaymentPackage.css";
import PaymentSelector from "./PaymentSelector";
import PeriodeSelector from "./PeriodeSelector";
import ProductInformation from "./ProductInformation";

export const BoxPaymentPackage = ({products, history, content, eventData}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [product, setProduct] = useState(products?.[0]);
  const transactionId = md5(new Date().getTime());

  const handlePayment = () => {
      if (!product.productId || selectedPaymentMethod === "") {
        return;
      }

      addToCart({
        transaction_id: transactionId,
        productId: product.productId,
        price: product.price 
      }, {
        payment_method: selectedPaymentMethod
      }).then((res) => {
        getVa({
          code: res.code,
          price: product.price,
          payment_method: selectedPaymentMethod,
        }).then(async (resp) => {
          const res = resp.data;
          if (res.errorCode !== 0) {
            alert("Pembayaran Gagal");
            return;
          }

          let vaNumber = "";
          if (["shopeepay", "gopay"].indexOf(selectedPaymentMethod) > -1) {
            vaNumber = res.actions?.filter(
              (a) => a.name === "deeplink-redirect"
            )?.[0].url;
          } else {
            vaNumber = res.vaNumber;
          }

          await setVa({
            transactionId: transactionId,
            paymentMethod: selectedPaymentMethod,
            vaNumber: vaNumber,
          });

          history.push(`/payment/${transactionId}`);
        });
      });
  };

  return (
    <div className="box-payments">
      <ProductInformation content={content} product={product} eventData={eventData}/>
      <PeriodeSelector products={products} setProduct={setProduct}/>
      <PaymentSelector setSelectedPaymentMethod={setSelectedPaymentMethod}/>
      <div className="cta-boxs">
        <button onClick={() => handlePayment()}>Lanjut ke Pembayaran</button>
      </div>
    </div>
  );
};
