import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useMediaQuery } from "react-responsive";
import { withRouter } from "react-router";
import "../css/AllEvent.css";
import "../css/Events.css";
import DesktopLeft from "../EventComponent/DesktopLeft";
import DesktopRight from "../EventComponent/DesktopRight";
import FillQna from "../EventComponent/FillQna/FillQna";
import { formatDurationQna } from "../helpers/format-duration";
import CardNewEpisode from "../NewEpisode/CardNewEpisode";
import PopupAudioEvent from "../Popup/PopupAudioEvent";
import PopupAuthEvent from "../Popup/PopupAuthEvent";
import { logAdvertising } from "../services/ads";
import { AskQuestionContainer } from "./AskQuestionContainer";
import Loading from "./Loading";

function WebinarRecord(props) {
  const eventCode = props.match.params.event_code;
  const [scheduleFilter, setScheduleFilter] = useState(0);
  const token = localStorage.getItem("token");
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isLoading, setIsLoading] = useState(true);
  const [openLogin, setOpenLogin] = useState(false);
  const names = localStorage.getItem("name");
  const [memberType, setMembertype] = useState("non-member");
  const [tenants, setTenants] = useState(null);
  const [activePackages, setActivePackages] = useState(null);
  const [eventData, setEventData] = useState({});
  const [schedule, setSchedule] = useState([]);
  const [maindata, setMaindata] = useState({});
  const [idl, setIdl] = useState(0);
  const [dataAds, setAds] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [audioFiltered, setAudioFiltered] = useState([]);
  const [audioId, setAudioId] = useState(null);
  const [titleAudio, setTitleAudio] = useState("");
  const [page, setPage] = useState(1);
  const [question, setQuestion] = React.useState({});
  const [isQuestionSubmitted, setIsQuestionSubmitted] = React.useState(false);
  const [askQuestion, setAskQuestion] = useState(false);
  const [profile, setProfile] = React.useState({});
  const [profileMember, setProfileMember] = React.useState({});
  const slice = 8;

  const getSchedules = async (events) => {
    let payload = {};

    if (token !== "" || token !== null) {
      payload = {
        tenantId: events.tenantId,
        token: token,
      };
    }

    await axios
      .post(
        process.env.REACT_APP_FETCH_URL + "/schedule/" + events.eventId,
        payload
      )
      .then(async (res) => {
        if (res?.data) {
          setSchedule(res?.data);

          setMaindata({
            ...maindata,
            schedule: res?.data,
          });
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    if (!eventData?.eventId) {
      return;
    }

    getAdvertising("right-sidebar");

    axios
      .get(process.env.REACT_APP_FETCH_URL + "/all_new_episode_webinar")
      .then((res) => {
        const filtered = res?.data;
        setAudioFiltered(filtered);
      });
  }, [eventData]);

  const getAdvertising = async (mode = "right-sidebar") => {
    let payload = {};
    let placement = window.location.pathname.slice(
      1,
      window.location.pathname.length
    );

    payload = {
      placement,
      tenantId: "",
      eventId: eventData.eventId,
      scheduleId: "",
      adsType: mode,
    };

    await axios
      .post(process.env.REACT_APP_FETCH_URL + "/advertising", payload)
      .then(async (res) => {
        if (res?.data) {
          logAdvertising("loaded", mode, res?.data[0]?.adsId);
          setAds(res?.data);
        }
      });
  };

  useEffect(() => {
    async function checkEvent() {
      const url = localStorage.getItem("a01c12")
        ? "/members/" + token + "/" + localStorage.getItem("a01c12") + "/mcs"
        : "/members/" + token;

      await axios.get(process.env.REACT_APP_FETCH_URL + url).then((result) => {
        setMembertype(result?.data?.memberType);
        setTenants(result?.data?.tenants);
        setActivePackages(result?.data?.activePackages);
        setUserEmail(result?.data?.email);
      });
      await axios
        .get(process.env.REACT_APP_FETCH_URL + "/events/" + eventCode)
        .then((res) => {
          if (res?.data) {
            setEventData(res?.data);
            getSchedules(res?.data);
          }
        });
      
      await axios
        .get(process.env.REACT_APP_FETCH_URL + `/profile/${token}`)
        .then((res) => {
          const profileJson = res?.data[0]?.profileData
            ? JSON.parse(res?.data[0]?.profileData)
            : {};

          setProfileMember(profileJson);
          setProfile(profileJson);
        });
    }
    checkEvent();
  }, [token, eventCode]);

  const gotoTypeForm = React.useCallback(() => {
    if (token === null) {
      setOpenLogin(true);
      return;
    }    

    // if (eventData && new Date(eventData.endDate) < new Date()) {
    //   return setPremiumDialog(true);
    // }

    setAskQuestion(true);
  }, [eventData]);

  useEffect(() => {
    if (openLogin) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openLogin]);

  return (
    <React.Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isMobile ? (
            <>
              <div className="main-all">
                <div className="header">
                  <img
                    onClick={() => (window.location.href = `/e/${eventCode}`)}
                    src="/assets/images/back.svg"
                    alt="back"
                  />
                  <p>Materi Audio</p>
                </div>
                <div className="parent-allevent">
                  <div className="allevent-container">
                    <div className="schedule">
                      <div
                        className="audio-materials"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          width: "100%",
                        }}
                      >
                        {audioFiltered?.map((a, index) => {
                          if (!a.playlistId) {
                            return;
                          }

                          const currentPage = Math.ceil(index / slice);
                          if (currentPage !== page) {
                            return;
                          }

                          return (
                            <div className="audio-materials-container">
                              <CardNewEpisode
                                data={a}
                                push={() => {
                                  setAudioId(a.playlistId);
                                  setTitleAudio(a.title);
                                }}
                                key={0}
                                mode={"newepisode"}
                                style={{
                                  border: "solid 1px #EAEAEA",
                                  borderRadius: "12px",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  maxWidth: "100%",
                                  padding: "14px 10px 10px",
                                  position: "relative",
                                  marginTop: "-8px",
                                  background: "#FFF",
                                  border: "solid 1px #EAEAEA",
                                  borderRadius: "0 0 12px 12px",
                                  borderTop: "none",
                                  overflow: "hidden",
                                }}
                              >
                                <div style={{ marginRight: "10px" }}>
                                  <img
                                    src={"/assets/programs/play-soft.svg"}
                                    alt="play"
                                  />
                                </div>
                                <div
                                  style={{
                                    maxWidth: "100px",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      width: "100%",
                                      fontFamily: "Rubik",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {a.speakerName}
                                  </div>
                                  <div
                                    style={{
                                      fontWeight: 700,
                                      fontFamily: "Lato",
                                      color: "#7D61D6",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {formatDurationQna(a.duration / 1000)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className="contentpagination"
                        style={{ height: "120px" }}
                      >
                        <ReactPaginate
                          activeClassName="active"
                          nextClassName="nextpage"
                          previousLabel=""
                          nextLabel=""
                          forcePage={page - 1}
                          previousClassName="prevpage"
                          pageCount={audioFiltered.length / slice}
                          onPageChange={(args) => {
                            const selectedPage = args.selected + 1;
                            setPage(selectedPage);
                          }}
                          style={{ cursor: "pointer" }}
                          pageRangeDisplayed={2}
                          marginPagesDisplayed={1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="parent-event">
                <div className="event-container">
                  <div className="last-event" />
                  <DesktopLeft
                    eventData={eventData}
                    token={token}
                    setIdl={setIdl}
                    setOpenLogin={setOpenLogin}
                    names={names}
                    memberType={memberType}
                    tenants={tenants}
                    activePackages={activePackages}
                    eventCode={eventCode}
                    email={userEmail}
                  />
                  <DesktopRight
                    activeTabs={2}
                    token={token}
                    setOpenLogin={setOpenLogin}
                    eventCode={eventCode}
                    eventData={eventData}
                    gotoTypeForm={gotoTypeForm}
                    profileName={names}
                    profileEmail={userEmail}
                    activePackages={activePackages}
                    leftChildren={
                      <React.Fragment>
                        <div className="last-event">
                          <div
                            className="audio-materials"
                            style={{ display: "flex", flexWrap: "wrap" }}
                          >
                            {audioFiltered?.map((a, index) => {
                              if (!a.playlistId) {
                                return;
                              }

                              const currentPage = Math.ceil(index / slice);
                              if (currentPage !== page) {
                                return;
                              }

                              return (
                                <div className="audio-materials-container">
                                  <CardNewEpisode
                                    data={a}
                                    push={() => {
                                      setAudioId(a.playlistId);
                                      setTitleAudio(a.title);
                                    }}
                                    key={0}
                                    mode={"newepisode"}
                                    style={{
                                      border: "solid 1px #EAEAEA",
                                      borderRadius: "12px",
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      maxWidth: "175px",
                                      padding: "14px 10px 10px",
                                      position: "relative",
                                      marginTop: "-8px",
                                      background: "#FFF",
                                      border: "solid 1px #EAEAEA",
                                      borderRadius: "0 0 12px 12px",
                                      borderTop: "none",
                                      overflow: "hidden",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setAudioId(a.playlistId);
                                      setTitleAudio(a.title);
                                    }}
                                  >
                                    <div style={{ marginRight: "10px" }}>
                                      <img
                                        src={"/assets/programs/play-soft.svg"}
                                        alt="play"
                                      />
                                    </div>
                                    <div
                                      style={{
                                        maxWidth: "100px",
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          width: "100%",
                                          fontFamily: "Rubik",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {a.speakerName}
                                      </div>
                                      <div
                                        style={{
                                          fontWeight: 700,
                                          fontFamily: "Lato",
                                          color: "#7D61D6",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {formatDurationQna(a.duration / 1000)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="contentpagination">
                            <ReactPaginate
                              activeClassName="active"
                              nextClassName="nextpage"
                              previousLabel=""
                              nextLabel=""
                              forcePage={page - 1}
                              previousClassName="prevpage"
                              pageCount={audioFiltered.length / slice}
                              onPageChange={(args) => {
                                const selectedPage = args.selected + 1;
                                setPage(selectedPage);
                              }}
                              style={{ cursor: "pointer" }}
                              pageRangeDisplayed={2}
                              marginPagesDisplayed={1}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    }
                    rightChildren={undefined}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
      {audioId !== null && (
        <PopupAudioEvent
          titleAudio={titleAudio}
          audioId={audioId}
          setAudioId={setAudioId}
          isPrograms={false}
          isWebinar={true}
          isNewLayout={true}
        />
      )}
      {openLogin && (
        <PopupAuthEvent
          setOpenLogin={setOpenLogin}
          tenantId={eventData?.tenantId}
          eventCode={eventCode}
          idl={idl}
          setIdl={setIdl}
        />
      )}
      {askQuestion && (
        <AskQuestionContainer
          question={question}
          eventData={eventData}
          setAskQuestion={setAskQuestion}
          isQuestionSubmitted={isQuestionSubmitted}
          setIsQuestionSubmitted={setIsQuestionSubmitted}
          profile={profile}
        >
          {!isQuestionSubmitted ? (
            <FillQna
              qna={question}
              isImportant={true}
              setQna={setQuestion}
              eventData={eventData}
              profileRules={eventData ? JSON.parse(eventData.rules) : {}}
              qnaFormStatus={undefined}
              setProfile={setProfile}
              profile={profile}
              profileMember={profileMember}
            />
          ) : (
            <div className="q_submitted">
              <img src="/assets/images/u_check-circle.svg" />
              <div>
                Pertanyaan Kamu
                <br />
                Berhasil Dikirim
              </div>
            </div>
          )}
        </AskQuestionContainer>
      )}
    </React.Fragment>
  );
}

export default withRouter(WebinarRecord);
