import React from "react";
import { withRouter } from "react-router";

function CardNarasumber(props) {
  const { data, style, push } = props;
  if (data) {
    return (
      <div
        onClick={push}
        className="parent-card-narasumber"
        style={{ border: "solid 1px #FFF" }}
      >
        <img
          className="img-narasumber"
          src={data?.photo}
          alt="narasumber-img"
        />
        <p className="text-title-narasumber">
          {data?.name?.length > 30
            ? data?.name?.slice(0, 30) + "..."
            : data?.name}
        </p>
      </div>
    );
  } else {
    return (
      <div className="parent-card-narasumber" style={style}>
        <img
          className="img-narasumber"
          src="https://i.ibb.co/V39tYg3/Ellipse-70.png"
          alt="narasumber-img"
        />
        <p className="text-title-narasumber">
          Vera Itabiliana Hadiwijojo, S.Psi...
        </p>
      </div>
    );
  }
}
export default withRouter(CardNarasumber);
