import React from 'react';
import Images from '../ImagesComponent/Images';
import MetaTags from 'react-meta-tags';
import M from 'moment'

class MetaTagContent extends React.Component {

	render() {
		const { image, url, title, desc } = this.props;
		let titlePage = (title && title !== "undefined") ? `Fammi - ${title}` : 'Fammi - Solusi Terpusat untuk Pencegahan dan Penanganan Masalah Anak';
		return (
			<MetaTags>
				<title>{titlePage}</title>
				<meta id="fb-appid" property="fb:app_id" content="970351590121950" />
				<meta id="meta-description" name="description" content={desc ? desc : "Fammi membantu orangtua dan sekolah untuk mengoptimalisasi proses pendidikan melalui pembelajaran kolaboratif"} />
				<meta id="og-url" property="og:url" content={url} />
				<meta id="og-type" property="og:type" content="website" />
				<meta id="og-description" property="og:description" content={desc ? desc : "Fammi membantu orangtua dan sekolah untuk mengoptimalisasi proses pendidikan melalui pembelajaran kolaboratif"} />
				<meta id="og-title" property="og:title" content={title ? title : "Fammi - Solusi Terpusat untuk Pencegahan dan Penanganan Masalah Anak"} />
				<meta id="og-image" property="og:image" itemprop="image" content={image} />
			</MetaTags>
		);
	}
}

export default MetaTagContent;
