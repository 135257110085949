import React from "react";
import { useState } from "react";

const SheetPeriode = ({ open, handleClose, selectedPeriode, onSelect, products }) => {
  const [indexActive, setIndexActive] = useState(selectedPeriode);
  const ListPeriode = ({ isActive, handleSelect, data }) => (
    <div
      onClick={handleSelect}
      className={`list-periode ${isActive && "actived"}`}
      style={{textTransform: "capitalize"}}
    >
      <p>{`${parseInt(data?.lifeTimeNumber)} ${data?.lifeTimeMetric}`} •&nbsp;
      Rp{(data?.normalPrice).toLocaleString()},-</p>
      <div className={isActive ? "actived" : ""} />
    </div>
  );
  return (
    <>
      {open ? (
        <div className="parent-sheet">
          <div className="sheet-periode">
            <h2>Pilih Periode Waktu</h2>
            <div style={{ marginTop: 6 }}>
              {products.map((d, i) => (
                <ListPeriode
                  data={d}
                  key={i}
                  isActive={indexActive === i}
                  handleSelect={() => setIndexActive(i)}
                />
              ))}
            </div>
            <div className="ctas">
              <button onClick={handleClose} className="closes">
                Tutup
              </button>
              <button
                onClick={() => onSelect(indexActive)}
                disabled={indexActive === null}
                style={{ background: indexActive !== null && "#6c60e0" }}
                className="actions"
              >
                Pilih
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

function PeriodeSelector({products, setProduct}) {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [selectedPeriode, setSelectedPeriode] = useState(0);

  React.useEffect(() => {
    setProduct({
      productId: products[0].productId,
      price: products[0].normalPrice
    });
  }, [products]);
  return (
    <>
      <div className="periode-selector">
        <h2>Pilih Periode Waktu</h2>
        <div
          className="boxeds"
          onClick={() => {
            setIsSheetOpen(true);
          }}
        >
          <p style={{textTransform: "capitalize"}}>
            {`${parseInt(products[selectedPeriode]?.lifeTimeNumber)} ${products[selectedPeriode]?.lifeTimeMetric}`} •&nbsp;
          Rp{(products[selectedPeriode]?.normalPrice).toLocaleString()},-</p>
          <div>
            <span>Lihat Semua</span>
            <img src="/assets/images/arrow-right.svg" alt="chevron" />
          </div>
        </div>
      </div>
      <SheetPeriode
        open={isSheetOpen}
        handleClose={() => {
          setIsSheetOpen(false);
        }}
        onSelect={(i) => {
          setSelectedPeriode(i);
          setProduct({
            productId: products[i].productId,
            price: products[i].normalPrice
          });
          setIsSheetOpen(false);
        }}
        selectedPeriode={selectedPeriode}
        products={products}
      />
    </>
  );
}

export default PeriodeSelector;
