import React from "react";
import "./RightPrograms.css";
function RightPrograms(props) {
  const { setAskQuestion } = props;
  return (
    <div id="right-program">
      <h1>Tanya Ahlinya Fammi</h1>
      <div className="box">
        <div
          className="head"
          style={{ flexDirection: "column", alignItems: "start" }}
        >
          <img src="/assets/images/program_ask.svg" alt="question" />
          <h1>
            Masih bingung mempraktekkan materi di rumah? Yuk tanya narasumber
            ahli Fammi
          </h1>
        </div>
        <div className="bottoms">
          <button
            onClick={() => {
              setAskQuestion(true);
            }}
            style={{ width: "100%", height: 40, justifyContent: "center" }}
          >
            <img src="/assets/images/ask_ahli.svg" alt="qna" />
            <p>Tanya Ahli</p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default RightPrograms;
