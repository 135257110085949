import React, { useEffect, useState } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import "../css/Events.css";
import "../css/AllEvent.css";
import DesktopLeft from "../EventComponent/DesktopLeft";
import DesktopRight from "../EventComponent/DesktopRight";
import Loading from "./Loading";
import PopupAuthEvent from "../Popup/PopupAuthEvent";
import { useMediaQuery } from "react-responsive";
import CardPrograms from "../EventComponent/CardPrograms/CardPrograms";
import RightPrograms from "../EventComponent/RightPrograms/RightPrograms";
import ListPrograms from "../EventComponent/ListPrograms/ListPrograms";
import DialogEnroll from "../EventComponent/DialogEnroll/DialogEnroll";
import { getProgram, getProgramDetail } from "../services/packages";
import ArticlePrograms from "../EventComponent/ArticlePrograms/ArticlePrograms";
import { getArticle } from "../services/aritcles";
import { AskQuestionContainer } from "./AskQuestionContainer";
import FillQna from "../EventComponent/FillQna/FillQna";
import "../EventComponent/FillProfile/FillProfile.css";
import CardAds from "../Card/CardAds";
import { logAdvertising } from "../services/ads";

function ArticleProgram(props) {
  const eventCode = props.match.params.event_code;
  const packageId = props.match.params.id;
  const articleId = props.match.params.article_id;
  const isPanduan = props.isPanduan;
  const token = localStorage.getItem("token");
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isLoading, setIsLoading] = useState(true);
  const [openLogin, setOpenLogin] = useState(false);
  const [openEnroll, setOpenEnroll] = useState(false);

  const names = localStorage.getItem("name");
  const [memberType, setMembertype] = useState("non-member");
  const [tenants, setTenants] = useState(null);
  const [activePackages, setActivePackages] = useState(null);
  const [eventData, setEventData] = useState({});
  const [idl, setIdl] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [isNotEnroll, setIsNotEnroll] = useState(false);
  const [program, setProgram] = useState({});
  const [programDetail, setProgramDetail] = useState([]);
  const [openArticle, setOpenArticle] = useState(true);
  const [data, setData] = useState({});
  const [askQuestion, setAskQuestion] = useState(false);
  const [question, setQuestion] = React.useState({});
  const [isQuestionSubmitted, setIsQuestionSubmitted] = React.useState(false);
  const [profile, setProfile] = React.useState({});
  const [profileMember, setProfileMember] = React.useState({});
  const [dataRightAds, setRightAds] = useState([]);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (!eventData?.eventId) {
      return;
    }

    getAdvertising("right-sidebar");
    getProgram(packageId).then((d) => {
      setProgram(d?.data);

      setIsLoading(false);
    });

    getProgramDetail(packageId, token).then((d) => {
      setProgramDetail(d?.data);
      setIsLoading(false);

      if (d?.data?.[0]?.state === null) {
        setIsNotEnroll(true);
        setOpenEnroll(true);

        return;
      }

      getArticle(articleId).then((a) => {
        setData(a?.data);
      });
    });
  }, [eventData]);

  const getAdvertising = async (mode = "right-sidebar") => {
    let payload = {};
    let placement = window.location.pathname.slice(
      1,
      window.location.pathname.length
    );

    if (token !== "" || token !== null) {
      payload = {
        placement,
        tenantId: "",
        eventId: eventData.eventId,
        scheduleId: "",
        adsType: mode,
      };
    }

    await axios
      .post(process.env.REACT_APP_FETCH_URL + "/advertising", payload)
      .then(async (res) => {
        if (res?.data) {
          if (mode === "right-sidebar") {
            setRightAds(res?.data);
            logAdvertising("loaded", mode, res?.data[0]?.adsId);
          }
        }
      });
  };

  useEffect(() => {
    async function checkEvent() {
      const url = localStorage.getItem("a01c12")
        ? "/members/" + token + "/" + localStorage.getItem("a01c12") + "/mcs"
        : "/members/" + token;

      await axios.get(process.env.REACT_APP_FETCH_URL + url).then((result) => {
        setMembertype(result?.data?.memberType);
        setTenants(result?.data?.tenants);
        setActivePackages(result?.data?.activePackages);
        setUserEmail(result?.data?.email);
      });

      await axios
        .get(process.env.REACT_APP_FETCH_URL + `/profile/${token}`)
        .then((res) => {
          const profileJson = res?.data[0]?.profileData
            ? JSON.parse(res?.data[0]?.profileData)
            : {};

          setProfileMember(profileJson);
          setProfile(profileJson);
        });

      await axios
        .get(process.env.REACT_APP_FETCH_URL + "/events/" + eventCode)
        .then((res) => {
          if (res?.data) {
            setEventData(res?.data);
          }
        });
    }
    checkEvent();
  }, [token, eventCode]);

  useEffect(() => {
    if (openLogin) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openLogin]);

  return (
    <React.Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isMobile ? (
            <>
              <div className="main-all">
                <div className="header">
                  <img
                    onClick={() => (window.location.href = `/e/${eventCode}`)}
                    src="/assets/images/back.svg"
                    alt="back"
                  />
                  <p>{packageId ? (isPanduan ? "Panduan" : "Artikel") : "Jawaban"}</p>
                </div>
                <div className="parent-allevent">
                  <div
                    style={{ padding: 0, background: "#F9F9F9" }}
                    className="allevent-container"
                  >
                    <ArticlePrograms
                      open={true}
                      data={data}
                      handleClose={() => {
                        props.history.goBack();
                      }}
                    />
                  </div>
                </div>
                <div
                  className="lists"
                  style={{ padding: 15, backgroundColor: "#fff" }}
                >
                  {dataRightAds &&
                    dataRightAds[0]?.adsType === "right-sidebar" && (
                      <CardAds eventCode={eventCode} data={dataRightAds[0]} />
                    )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="parent-event">
                <div className="event-container">
                  <div className="last-event"></div>
                  <DesktopLeft
                    eventData={eventData}
                    token={token}
                    setIdl={setIdl}
                    setOpenLogin={setOpenLogin}
                    names={names}
                    memberType={memberType}
                    tenants={tenants}
                    activePackages={activePackages}
                    eventCode={eventCode}
                    email={userEmail}
                  />
                  <DesktopRight
                    activeTabs={0}
                    token={token}
                    setOpenLogin={setOpenLogin}
                    eventCode={eventCode}
                    isSchedules={false}
                    eventData={eventData}
                    customTitles="Detail Artikel"
                    goBackAction={() => {
                      props.history.push(
                        `/e/${eventCode}/programs/${packageId}`
                      );
                    }}
                    profileName={names}
                    profileEmail={userEmail}
                    activePackages={activePackages}
                    leftChildren={
                      <React.Fragment>
                        <div className="last-event">
                          <h1>
                            {packageId
                              ? `Artikel
                            ${!isPanduan ? "" : "- Panduan"}`
                              : "Jawaban"}
                          </h1>
                          <ArticlePrograms open={true} data={data} />
                        </div>
                      </React.Fragment>
                    }
                    rightChildren={
                      <React.Fragment>
                        <RightPrograms setAskQuestion={setAskQuestion} />
                        <div
                          className="lists"
                          style={{ marginBottom: 20, marginTop: 15 }}
                        >
                          {dataRightAds &&
                            dataRightAds[0]?.adsType === "right-sidebar" && (
                              <CardAds
                                eventCode={eventCode}
                                data={dataRightAds[0]}
                              />
                            )}
                        </div>
                      </React.Fragment>
                    }
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
      {openLogin && (
        <PopupAuthEvent
          setOpenLogin={setOpenLogin}
          tenantId={eventData?.tenantId}
          eventCode={eventCode}
          idl={idl}
          setIdl={setIdl}
        />
      )}
      <DialogEnroll
        open={openEnroll}
        program={program}
        eventData={eventData}
        token={token}
        handleClose={() => {
          setOpenEnroll(false);
        }}
      />
      {askQuestion && (
        <AskQuestionContainer
          question={question}
          eventData={eventData}
          setAskQuestion={setAskQuestion}
          isQuestionSubmitted={isQuestionSubmitted}
          setIsQuestionSubmitted={setIsQuestionSubmitted}
          profile={profile}
          setIsValid={setIsValid}
        >
          {!isQuestionSubmitted ? (
            <FillQna
              qna={question}
              isImportant={true}
              setQna={setQuestion}
              eventData={eventData}
              profileRules={eventData ? JSON.parse(eventData.rules) : {}}
              qnaFormStatus={undefined}
              setProfile={setProfile}
              profile={profile}
              profileMember={profileMember}
              isValid={isValid}
            />
          ) : (
            <div className="q_submitted">
              <img src="/assets/images/u_check-circle.svg" />
              <div>
                Pertanyaan Kamu
                <br />
                Berhasil Dikirim
              </div>
            </div>
          )}
        </AskQuestionContainer>
      )}
    </React.Fragment>
  );
}

export default withRouter(ArticleProgram);
