import React from "react";

export const CheckIcon = (props) => {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M14.0333 5.4074C13.9636 5.3371 13.8806 5.2813 13.7892 5.24323C13.6979 5.20515 13.5998 5.18555 13.5008 5.18555C13.4018 5.18555 13.3038 5.20515 13.2124 5.24323C13.121 5.2813 13.038 5.3371 12.9683 5.4074L7.38082 11.0024L5.03332 8.6474C4.96093 8.57747 4.87547 8.52248 4.78183 8.48558C4.68819 8.44868 4.58819 8.43058 4.48756 8.43232C4.38692 8.43406 4.28762 8.45561 4.19531 8.49573C4.103 8.53585 4.0195 8.59375 3.94957 8.66615C3.87964 8.73854 3.82465 8.82399 3.78775 8.91763C3.75085 9.01128 3.73275 9.11127 3.73449 9.2119C3.73623 9.31254 3.75778 9.41185 3.7979 9.50416C3.83802 9.59646 3.89593 9.67997 3.96832 9.7499L6.84832 12.6299C6.91804 12.7002 7.00099 12.756 7.09238 12.7941C7.18378 12.8321 7.28181 12.8517 7.38082 12.8517C7.47983 12.8517 7.57786 12.8321 7.66925 12.7941C7.76064 12.756 7.8436 12.7002 7.91332 12.6299L14.0333 6.5099C14.1094 6.43966 14.1702 6.35443 14.2118 6.25955C14.2533 6.16468 14.2748 6.06222 14.2748 5.95865C14.2748 5.85507 14.2533 5.75262 14.2118 5.65774C14.1702 5.56287 14.1094 5.47763 14.0333 5.4074Z"
        fill={props.color || "#6C60E0"}
      />
    </svg>
  );
};
