import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import CardLoginPartner from "../CardLoginPartner/CardLoginPartner";
import "../css/RegisterCompany.css";
import axios from "axios";
import Loading from "./Loading";
import firebase from "firebase/app";
import "firebase/auth";

function LoginPartner(props) {
  const companyCode = props.match.params.id;
  const [isLoading, setIsLoading] = useState(true);
  const [partner, setPartner] = useState({});
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    tenantId: "",
  });
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    async function checkTenant() {
      return await axios
        .post(process.env.REACT_APP_FETCH_URL + "/get_tenant", {
          name: companyCode,
        })
        .then((res) => {
          if (res?.data) {
            setIsLoading(false);
            setPartner(res?.data);
          } else {
            props.history.push("/login");
          }
        });
    }
    checkTenant();
  }, [companyCode]);

  const handleLogin = async () => {
    setIsLoading(true);
    await axios
      .post(process.env.REACT_APP_FETCH_URL + "/partner_login", {
        email: formData?.email,
        password: formData?.password,
        tenantId: partner?.tenantId,
      })
      .then((res) => {
        if (res?.data.isAuthenticate) {
          localStorage.clear();
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("name", res.data.name);
          localStorage.setItem("a01c12", partner.tenantId);

          if (
            window.location.href.indexOf("931812c3a2642304d0b620205c35fc08=") >
            -1
          ) {
            window.location.href = window.location.href.split(
              "931812c3a2642304d0b620205c35fc08="
            )[1];
            return;
          }

          props.history.push("/");
        }

        setIsLoading(false);
        setLoginError(true);
      })
      .catch((err) => {
        if (err) {
          setIsLoading(false);
          setLoginError(true);
        }
      });
  };

  const handleGoogleAuth = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        if (res) {
          const payload = JSON.stringify({
            provider: "google",
            email: res?.user?.email,
            name: res?.user?.displayName,
            token: res?.credential.accessToken,
            tenantId: partner?.tenantId,
          });
          const url = process.env.REACT_APP_FETCH_URL + "/socmed_auth";

          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: payload,
          })
            .then((result) => result.json())
            .then((result) => {
              if (result && result?.isAuthenticate) {
                // localStorage.clear();
                localStorage.setItem("token", result.token);
                localStorage.setItem("name", result.name);
                localStorage.setItem("a01c12", partner.tenantId);

                window.fbq("track", "CompleteRegistration");

                setTimeout(() => {
                  if (localStorage.getItem("pleasecomeback") !== null) {
                    this.addToCart();
                    return;
                  }

                  if (
                    window.location.href.indexOf(
                      "931812c3a2642304d0b620205c35fc08="
                    ) > -1
                  ) {
                    window.location.href = window.location.href.split(
                      "931812c3a2642304d0b620205c35fc08="
                    )[1];
                    return;
                  }

                  window.location.href = "/";
                }, 500);
              }
            });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleFacebookAuth = () => {
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        console.log(res);
        if (res) {
          const payload = JSON.stringify({
            provider: "facebook",
            email: res?.user?.email,
            name: res?.user?.displayName,
            token: res?.user?.refreshToken,
            tenantId: partner?.tenantId,
          });
          const url = process.env.REACT_APP_FETCH_URL + "/socmed_auth";

          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: payload,
          })
            .then((result) => result.json())
            .then((result) => {
              if (result && result?.isAuthenticate) {
                // localStorage.clear();
                localStorage.setItem("token", result.token);
                localStorage.setItem("name", result.name);
                localStorage.setItem("a01c12", partner.tenantId);

                window.fbq("track", "CompleteRegistration");

                setTimeout(() => {
                  if (localStorage.getItem("pleasecomeback") !== null) {
                    this.addToCart();
                    return;
                  }

                  if (
                    window.location.href.indexOf(
                      "931812c3a2642304d0b620205c35fc08="
                    ) > -1
                  ) {
                    window.location.href = window.location.href.split(
                      "931812c3a2642304d0b620205c35fc08="
                    )[1];
                    return;
                  }

                  window.location.href = "/";
                }, 500);
              }
            });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="header-register-company">
            <div className="container-header-company">
              <img
                src="https://new.fammi.ly/images/fammi-logo.svg"
                alt="logo-fammi"
                style={{ cursor: "pointer" }}
                onClick={() => props.history.push("/")}
              />
              <p className="text-header-register-company">
                Belum Mempunyai Akun?{" "}
                <b
                  onClick={() =>
                    props.history.push("/register" + "/" + partner?.name)
                  }
                >
                  Daftar
                </b>
              </p>
            </div>
            <div id="new-header">
              <a href="/">
                <img src="/assets/images/back.svg" alt="back" />
              </a>
            </div>
          </div>
          <div className="container-register-company">
            <CardLoginPartner
              formData={formData}
              setFormData={setFormData}
              partner={partner}
              loginError={loginError}
              handleLogin={handleLogin}
              handleFacebookAuth={handleFacebookAuth}
              handleGoogleAuth={handleGoogleAuth}
            />
          </div>
        </>
      )}
    </React.Fragment>
  );
}

export default withRouter(LoginPartner);
