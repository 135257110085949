import React, { useState } from "react";
import { withRouter } from "react-router";
import "../css/Events.css";
import MobileHeader from "../EventComponent/MobileHeader";
import { DetailInvoice } from "../EventComponent/SiapMasukSD/PaymentDetail/DetailInvoice";
import { PaymentSuccess } from "../EventComponent/SahabatSekolah/PaymentDetail/PaymentSuccess";
import { getTransaction } from "../services/transactions";
import Loading from "./Loading";

function WebinarFammiSahabatSekolahPayment(props) {
  const eventCode = props.match.params.event_code;
  const transactionId = props.match.params.transaction_id
    ? props.match.params.transaction_id
    : localStorage.getItem("fe941a31");
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [openLogin, setOpenLogin] = useState(true);
  const names = localStorage.getItem("name");
  const [memberType, setMembertype] = useState("non-member");
  const [tenants, setTenants] = useState(null);
  const [activePackages, setActivePackages] = useState(null);
  const [email, setEmail] = useState("");
  const [transaction, setTransaction] = useState(undefined);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const product = {
    price: 199000,
    productId: "02e332e5cb79e74c27b680dd4d81ce8b",
  };

  React.useEffect(() => {
    if (!transactionId || transactionId === null) {
      return;
    }

    getTransaction(transactionId).then((res) => {
      setTransaction(res.data?.[0]);

      if (res.data?.[0].status === "3") {
        setIsPaymentSuccess(true);
      }
    });
  }, [transactionId]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <div id="detail-schedule">
          <MobileHeader
            tenants={tenants}
            activePackages={activePackages}
            eventCode={eventCode}
            token={token}
            setOpenLogin={setOpenLogin}
            names={names}
            memberType={memberType}
            email={email}
            forceDisplayed={false}
          />
          <div className="parent-event" style={{ overflowY: "auto" }}>
            <div className="event-container" style={{ height: "100vh" }}>
              <div id="e_mobs">
                {isPaymentSuccess ? (
                  <PaymentSuccess product={product} transaction={transaction} />
                ) : (
                  <DetailInvoice transaction={transaction} />
                )}
              </div>
              <div
                className="two"
                style={{
                  maxWidth: "360px",
                  margin: "36px auto 0",
                  width: "100%",
                }}
              >
                {
                  isPaymentSuccess &&
                  <img height="1" width="1" style={{display:"block"}} src={`https://www.facebook.com/tr?id=836825333578633&ev=Purchase&cd[currency]=IDR&cd[productId]=${product.productId}&cd[value]=${product.price}`}/>
                }
                {isPaymentSuccess ? <PaymentSuccess product={product} transaction={transaction} /> : <DetailInvoice transaction={transaction}/>}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default withRouter(WebinarFammiSahabatSekolahPayment);
