import React from "react";

function Descriptions() {
  return (
    <React.Fragment>
      <div style={{fontFamily:"Rubik", fontWeight: 500, fontSize: "22px", lineHeight: "26px"}}>
        Parenting Solutions from the Expert for Your Child's Behaviour
      </div>
      <div style={{marginTop: "12px", fontFamily:"Rubik", fontWeight: 500, fontSize: "16px", lineHeight: "24px", color:"#6C60E0"}}>
        Are you a parent who's struggling with your child's behavior and feeling completely overwhelmed and frustrated to find parenting solutions?
      </div>
      <div style={{marginTop: "16px", fontFamily:"Rubik", fontWeight: 500, fontSize: "10px", lineHeight: "11.85px", color:"#000", background: "#F9A33F", borderRadius:"8px", padding: "9px 12px"}}>
        <img src={'/assets/images/u_clock-three.svg'}/> Less than 5 minutes to fill the form
      </div>
      <div style={{marginTop: "32px"}}>
        <img src={'/assets/images/malaysia-cover.png'} style={{width:"100%"}}/>
      </div>
      <div style={{marginTop: "24px", fontFamily:"Rubik", fontWeight: 400, fontSize: "14px", lineHeight: "24px"}}>
         It's important to understand that every child is unique and requires special attention for their physical and psychological development.
        <br/><br/>
        Realize or not, we're often pressured to compare our children to others, which can lead to unrealistic expectations and a lack of support for our kids. As a result, many children may struggle with poor self-esteem and self-worth. They may feel unsupported, unloved, or inadequate, which can lead to a range of emotional and psychological issues. It's a challenging situation for any parent to face, and seeking advice from experts can be time-consuming and costly.
        <br/><br/>
        But what if you could find a quick, reliable, and trustworthy parenting solutions to your problems? That's where Fammi comes in. With over 5 years of experience, our team of experienced and trusted psychologists and counselors are here to help you overcome any problems or challenges related to parenting and child development.
        <br/><br/>
        Don't let parenting stress you out any longer. Fammi provides personalized solutions to fit your unique family needs. We understand the importance of creating a supportive and nurturing environment for your child's growth and development. Our team is dedicated to helping parents achieve their parenting goals and create a happier and healthier family.
        <br/><br/>
        At Fammi, we believe that every child deserves the best possible start in life. That's why we offer a wide range of services to support parents, including individual and group counseling, parent training, and educational workshops. We help parents to understand their child's behavior, build strong relationships, and develop effective parenting skills.
        <br/><br/>
        By filling out our form today, you can take the first step towards a happier and healthier family. Let Fammi provide you with the parenting solutions you need to overcome any challenges and create a loving and supportive environment for your child's growth and development. Don't let the stress of parenting hold you back any longer. Contact Fammi today!
        <br/><br/>
      </div>
    </React.Fragment>
  );
}

export default Descriptions;
