import React from "react";
import { useState } from "react";

const SheetPeriode = ({ open, handleClose, selectedPayment, onSelect }) => {
  const ListPayment = ({ isActive, handleSelect, data }) => (
    <div
      onClick={handleSelect}
      className={`list-periode ${isActive && "actived"}`}
    >
      <img src={data?.image} alt={data?.name} />
      <div className={isActive ? "actived" : ""} />
    </div>
  );
  const LISTBANK = [
    {
      id: 1,
      name: "Bank Transfer & Virtual Account",
      list: [
        {
          id: 11,
          image: "/assets/images/payments/mandiri.svg",
          name: "Mandiri",
        },
        {
          id: 12,
          image: "/assets/images/payments/bni.svg",
          name: "BNI",
        },
        {
          id: 13,
          image: "/assets/images/payments/bri.svg",
          name: "BRI",
        },
        {
          id: 14,
          image: "/assets/images/payments/permata.svg",
          name: "Permata",
        },
      ],
    },
    {
      id: 2,
      name: "Bank Transfer & Virtual Account",
      list: [
        {
          id: 21,
          image: "/assets/images/payments/gopay.svg",
          name: "Gopay",
        },
        {
          id: 22,
          image: "/assets/images/payments/shopee.svg",
          name: "Shopeepay",
        },
      ],
    },
  ];
  const [currentBank, setCurrentBank] = useState(selectedPayment ?? null);

  return (
    <>
      {open ? (
        <div className="parent-sheet">
          <div className="sheet-periode">
            {LISTBANK.map((l, i) => (
              <div style={{ marginTop: i + 1 !== 1 && 24 }} key={l?.id}>
                <h2>{l?.name}</h2>
                <div style={{ marginTop: 6 }}>
                  {l?.list.map((d, i) => (
                    <ListPayment
                      key={d}
                      data={d}
                      isActive={currentBank?.id === d?.id}
                      handleSelect={() => setCurrentBank(d)}
                    />
                  ))}
                </div>
              </div>
            ))}

            <div className="ctas">
              <button onClick={handleClose} className="closes">
                Tutup
              </button>
              <button
                onClick={() => onSelect(currentBank)}
                disabled={currentBank === null}
                style={{ background: currentBank !== null && "#6c60e0" }}
                className="actions"
              >
                Pilih
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

function PaymentSelector({setSelectedPaymentMethod}) {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  return (
    <>
      <div className="payment-selector">
        <h2>Pilih Periode Waktu</h2>
        <div
          className="boxeds"
          onClick={() => {
            setIsSheetOpen(true);
          }}
        >
          {
            selectedPayment === null &&
            <div>
              <img
                src="/assets/images/payments/mandiri.svg"
                className="img"
                alt="bank-logo"
              />
              <img
                src="/assets/images/payments/shopee.svg"
                className="img"
                alt="bank-logo"
              />
            </div>
          }
          {
            selectedPayment !== null &&
            <div>
              <img
                src={selectedPayment.image}
                className="img"
                alt="bank-logo"
              />
            </div>
          }

          <div>
            <span>Lihat Semua</span>
            <img src="/assets/images/arrow-right.svg" alt="chevron" />
          </div>
        </div>
      </div>
      <SheetPeriode
        open={isSheetOpen}
        handleClose={() => {
          setIsSheetOpen(false);
        }}
        onSelect={(i) => {
          setSelectedPayment(i);
          setSelectedPaymentMethod(i.name.replace("Mandiri", "echannel").toLowerCase());
          setIsSheetOpen(false);
        }}
        selectedPayment={selectedPayment}
      />
    </>
  );
}

export default PaymentSelector;
