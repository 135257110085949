import React from "react";

export const FooterSection = ({ t }) => {
  return (
    <footer className="footer-section ">
      <div
        className="flex  justify-between border-b-white pb-20 mo-pb-11 mo-flex-col-reverse"
        style={{ maxWidth: "1170px", margin: "auto" }}
      >
        <div className="w-40-percent mo-w-full">
          <div>
            <h1>{t("let-collab")}</h1>
            <h2 className="mo-mt-2">It takes a village to raise a child</h2>
          </div>
          <div className="mt-10">
            <p className="text-white font-bold text-lg">Fammi Indonesia</p>
            <p className="text-white mt-4 text-main mo-mt-2">
              Jl. Ir. H. Djuanda No. 299 Kota Bandung Jawa Barat, Indonesia
            </p>
          </div>
          <div className="mt-10">
            <p className="text-white font-bold text-lg mb-4">{t("contact")}</p>
            <div
              className="bg-ori-white rounded p-4 flex items-center mb-4"
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open("mailto:care@fammi.ly");
              }}
            >
              <img
                src="/assets/new-home/email.svg"
                alt="contact"
                className="mr-4"
              />
              <div>
                <p className="text-grey">email</p>
                <p className="font-semibold">care@fammi.ly</p>
              </div>
            </div>
            <div
              className="bg-ori-white rounded p-4 flex items-center mb-4"
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open("https://wa.me/6282121006788", "_blank");
              }}
            >
              <img
                src="/assets/new-home/whatsapp.svg"
                alt="contact"
                className="mr-4"
              />
              <div>
                <p className="text-grey">phone</p>
                <p className="font-semibold">0821-2100-6788</p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="/assets/new-home/footer-vector.png"
          alt="footer-vector"
          className="footer-vec"
        />
      </div>
      <div
        className="flex items-center justify-between mt-6"
        style={{ maxWidth: "1170px", margin: "auto" }}
      >
        <img src="/assets/new-home/logo-white.svg" alt="logo-white" />
        <p className="text-small text-white mo-none">
          Copyright © 2022 Fammi Indonesia •
          <a
            href="https://fammi.ly/ketentuan"
            style={{ textDecoration: "none", color: "#FFF" }}
          >
            Syarat & Ketentuan
          </a>{" "}
          •
          <a
            href="https://fammi.ly/kebijakan"
            style={{ textDecoration: "none", color: "#FFF" }}
          >
            Kebijakan Data
          </a>
        </p>
        <div className="flex items-center">
          <img
            src="/assets/new-home/ig.svg"
            className="mr-8"
            alt="icon-white"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open("https://www.instagram.com/fammiindonesia", "_blank");
            }}
          />
          <img
            src="/assets/new-home/tiktok.svg"
            className="mr-8"
            alt="icon-white"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open("https://www.tiktok.com/@fammiindonesia", "_blank");
            }}
          />
          <img
            src="/assets/new-home/youtube.svg"
            alt="icon-white"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(
                "https://www.youtube.com/@fammiindonesia1168",
                "_blank"
              );
            }}
          />
        </div>
      </div>
      <div className="none mo-flex justify-center mo-mt-4">
        <p className="text-small text-white text-center">
          Copyright © 2022 Fammi Indonesia •&nbsp;
          <a
            href="https://fammi.ly/ketentuan"
            style={{ textDecoration: "none", color: "#FFF" }}
          >
            &nbsp;Syarat & Ketentuan
          </a>{" "}
          • &nbsp;
          <a
            href="https://fammi.ly/kebijakan"
            style={{ textDecoration: "none", color: "#FFF" }}
          >
            Kebijakan Data
          </a>
        </p>
      </div>
    </footer>
  );
};
