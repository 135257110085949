import axios from "axios";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import "../css/Events.css";

import MobileHeader from "../EventComponent/MobileHeader";
import DescriptionsSuccess from "../EventComponent/GuruTerampil/DescriptionsSuccess";
import Loading from "./Loading";

function WebinarProfilePaud(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [eventData, setEventData] = useState({});
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [openLogin, setOpenLogin] = useState(true);
  const names = localStorage.getItem("name");
  const [memberType, setMembertype] = useState("non-member");
  const [tenants, setTenants] = useState(null);
  const [activePackages, setActivePackages] = useState(null);
  const eventCode = props.match.params.event_code;
  const [email, setEmail] = useState("");
  const [form, setForm] = React.useState({});
  const [loadingForm, setLoadingForm] = useState(false);

  const isDisabledButton =
    form?.name === "" ||
    form?.usia === "" ||
    form?.no_whatsapp === "" ||
    form?.kota === "" ||
    form?.gender === "" ||
    form?.asal_sekolah === "";

  return (
    <React.Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <div id="detail-schedule" style={{ height: "95%", background: "#FFF" }}>
          <MobileHeader
            tenants={tenants}
            activePackages={activePackages}
            eventCode={eventCode}
            token={token}
            setOpenLogin={setOpenLogin}
            names={names}
            memberType={memberType}
            email={email}
            forceDisplayed={false}
            eventData={eventData}
          />
          <div className="parent-event" style={{ overflowY: "auto" }}>
            <div className="event-container">
              <div id="e_mobs" style={{ padding: 16, paddingTop: 32 }}>
                <DescriptionsSuccess />
                <div
                  style={{
                    background: "#FFFFFF",
                    boxShadow: "0px -5px 30px rgba(0, 0, 0, 0.1)",
                    borderRadius: "12px 12px 0px 0px",
                    padding: "16px",
                    position: "fixed",
                    bottom: "0px",
                    width: "100%",
                    left: 0,
                  }}
                >
                  <button
                    style={{
                      background:
                        isDisabledButton || loadingForm ? "#999" : "#6C60E0",
                      borderRadius: "12px",
                      border: "none",
                      width: "100%",
                      fontFamily: "'Rubik'",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: "#FFFFFF",
                      padding: "12px",
                    }}
                    onClick={() => {
                      window.location.href =
                        "https://www.instagram.com/fammiindonesia";
                    }}
                  >
                    Kunjungi Instagram Fammi
                  </button>
                </div>
              </div>

              <div
                className="two"
                style={{ maxWidth: "360px", margin: "36px auto 0" }}
              >
                <DescriptionsSuccess />
                <div
                  style={{
                    background: "#FFFFFF",
                    boxShadow: "0px -5px 30px rgba(0, 0, 0, 0.1)",
                    borderRadius: "12px 12px 0px 0px",
                    padding: "16px",
                    position: "fixed",
                    bottom: "0px",
                    width: "100%",
                    left: 0,
                  }}
                >
                  <button
                    style={{
                      background:
                        isDisabledButton || loadingForm ? "#999" : "#6C60E0",
                      borderRadius: "12px",
                      border: "none",
                      width: "100%",
                      fontFamily: "'Rubik'",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: "#FFFFFF",
                      padding: "12px",
                    }}
                    onClick={() => {
                      window.location.href =
                        "https://www.instagram.com/fammiindonesia";
                    }}
                  >
                    Kunjungi Instagram Fammi
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default withRouter(WebinarProfilePaud);
