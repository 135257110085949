import React from "react";
import { useTranslation } from "react-i18next";
import "./CallCenter.css";

function CallCenter() {
  const { t } = useTranslation();
  return (
    <div className="parent-call">
      <div>
        <h1>{t("have-problem")}</h1>
        <h1>{t("want-to-ask")}</h1>
        <h5>{t("contact-admin-wa")}</h5>
        <button
          onClick={() => {
            window.open(
              "https://wa.me/6282126600093?text=Halo Admin Fammi, saya mau bertanya",
              "_blank"
            );
          }}
        >
          <img src="/assets/images/whatsapp-white.svg" alt="whatsapp" />
          <p>{t("contact-admin")}</p>
        </button>
      </div>
      <img src="/assets/images/admin-vec.svg" alt="vec" />
    </div>
  );
}

export default CallCenter;
