import React from "react";

function ProductInformation({content, product, eventData}) {
  const price = Number;
  return (
    <div className="product-information">
      <h2>Informasi Produk</h2>
      <div className="boxeds">
        {
          content?.desktopBanner &&
          content?.desktopBanner !== "undefined" &&
          <img src={`https://fammi.ly/uploads/events/images/${content?.desktopBanner}?v=${Math.random()}`} alt="fee" style={{marginRight: "12px", width: "64px", height: "64px", borderRadius: "12px"}}/>
        }
        <div style={{marginLeft: "0px"}}>
          {/* <p>{content?.title}</p> */}
          <p>
            {eventData?.name}
          </p>
          {
            product &&
            <span>Rp{product?.price?.toLocaleString()},-</span>
          }
        </div>
      </div>
    </div>
  );
}

export default ProductInformation;
