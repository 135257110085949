import React from "react";
import Star from "./assets/star.svg";
import StarBlank from "./assets/star-blank.svg";
import Close from "./assets/close.svg";

export default function ReasonBox(props) {
  const { rate, close, send, changeText, text, isWebinar } = props;
  return (
    <div id="reason-box">
      <section>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <img
            onClick={close}
            style={{ cursor: "pointer" }}
            src={Close}
            alt="close"
          />
        </div>

        <h4>{isWebinar ? `Berikan penilaian untuk materi` : `Puas dengan jawaban narasumber?`}</h4>
        <div className="stared">
          {[1, 2, 3, 4, 5].map((d, i) => (
            <img src={rate >= d ? Star : StarBlank} key={i} alt="rating" />
          ))}
        </div>
        <h4 className="isi">Isi alasan kamu memberikan nilai tersebut ya!</h4>
        <textarea
          onChange={changeText}
          value={text}
          placeholder="Tulis alasannya di sini"
        />
        <div className="ctas">
          <button
            disabled={!text || text === ""}
            style={{
              background: !text || text === "" ? "#999" : "#7d61d6",
            }}
            onClick={send}
            className="send"
          >
            <p>Kirim Ulasan</p>
          </button>
        </div>
      </section>
    </div>
  );
}
