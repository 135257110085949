import moment from "moment";
import "moment/locale/id";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { HomeQnaContainer } from "../HomeQnaContainer/HomeQnaContainer";

function CardQna(props) {
  const {
    data,
    push,
    style,
    className,
    isLogin,
    setOpenLogin,
    quotaQna,
    setQuotaQna,
    setOpenSubs,
  } = props;
  const [askQuestion, setAskQuestion] = useState(false);
  const [questionData, setQuestionData] = useState({});

  const limitTime = moment(data?.limitTime);
  const startTime = moment(data?.startTime);
  const currentTime = moment();
  const isSoon = currentTime < startTime;
  const isOnGoing = currentTime >= startTime && currentTime < limitTime;
  const isEnd = currentTime > limitTime;
  const diffDays = parseInt(limitTime - currentTime) / 60000;

  const gotoTypeForm = React.useCallback(() => {
    if (localStorage.getItem("token") === null) {
      setOpenLogin(true);
      document.body.style.overflow = "hidden";
      return;
    }

    let typeformId = data.typeformId ?? "ehc2jqXG";
    if (quotaQna > 0) {
      localStorage.setItem("quotaQna", quotaQna - 1);
      setQuotaQna(quotaQna - 1);
    }
    if (quotaQna === -1) {
      localStorage.setItem("quotaQna", -1);
      setQuotaQna(-1);
    }
    if (data.keepOpened === "1" && !data.typeformId) {
      typeformId = "HjI1JaqW";
    }

    if (data.typeformId) {
      typeformId = data.typeformId;
    }

    window.qnaId = data.qnaId;
    window.speakerId = data.speakerId;
    window.prepareButton(typeformId);
    setTimeout(() => {
      document.getElementById("typeforms").click();
    });
  }, []);

  const seeAswerButtonOpenedQna = () => {
    if (!isLogin) {
      setOpenLogin(true);
      document.body.style.overflow = "hidden";
      return;
    }

    if (data.keepOpened === 0) {
      return;
    }

    if (data.playlistId) {
      window.location.href = "/playlist/" + data.playlistId;
      return;
    }

    alert("Belum ada pertanyaan");
  };

  const askQuestionButtonOpenedQna = (data) => {
    setQuestionData({
      ...data,
      tags: `Speaker ${data?.speakerName}`
    });
    setAskQuestion(true);
    // gotoTypeForm();
  };

  const clickAction = () => {
    if (isOnGoing) {
      return gotoTypeForm();
    }
    if (isSoon) {
      window.location.href = "/tanya/" + data.shortenedUrl;
      return;
    }

    if (isEnd) {
      if (data.playlistId) {
        window.location.href = "/playlist/" + data.playlistId;
        return;
      }
    }

    window.location.href = "/tanya/" + data.shortenedUrl;
  };

  return (
    <div className={"cards-qna " + className} style={style}>
      <img
        src={"https://fammi.ly/uploads/qna/images/" + data?.cover}
        alt="img-qna"
      />

      <div className="contents">
        <div>
          <p className="name">{data?.speakerName}</p>
          {data.keepOpened === "0" && (
            <p className="description">
              {isSoon && (
                <>
                  Sesi Bertanya <span className={"isSoon"}>Segera Dibuka</span>
                </>
              )}
              {isOnGoing && (
                <>
                  Sesi Selesai{" "}
                  <span className={"isOnGoing"}>
                    {diffDays > 1440
                      ? Math.floor(diffDays / 1440) + " hari lagi"
                      : diffDays > 60
                      ? Math.ceil(diffDays / 60) + " jam lagi"
                      : Math.ceil(diffDays) + " menit lagi"}
                  </span>
                </>
              )}
              {isEnd && (
                <>
                  Sesi Bertanya <span className={"isEnd"}>Telah Selesai</span>
                </>
              )}
            </p>
          )}

          {data.keepOpened === "0" && (
            <p className="time">
              {isSoon &&
                startTime.lang("id").format("ddd, DD MMM YY, HH:mm [WIB]")}
              {isOnGoing &&
                limitTime.lang("id").format("ddd, DD MMM YY, HH:mm [WIB]")}
              {isEnd &&
                limitTime.lang("id").format("ddd, DD MMM YY, HH:mm [WIB]")}
            </p>
          )}
        </div>

        {data.keepOpened === "0" && (
          <button
            style={{
              background: isSoon ? "#999999" : isEnd && "#fff",
              border: isEnd && "1px solid #DEDEDE",
              color: isEnd && "#7D61D6",
            }}
            onClick={() => {
              if (!isLogin) {
                setOpenLogin(true);
                document.body.style.overflow = "hidden";
                return;
              }

              if (parseInt(quotaQna) === 0) {
                setOpenSubs(true);
                document.body.style.overflow = "hidden";
                return;
              }
              clickAction();
            }}
          >
            {isSoon && "Segera Dibuka"}
            {isOnGoing && "Tanya Sekarang"}
            {isEnd && data.playlistId && "Lihat Jawaban"}
            {isEnd && !data.playlistId && "Sedang Menjawab"}
          </button>
        )}

        {/* {data.keepOpened === "1" && (
          <button
            className={"the-answer"}
            style={{
              background: "#fff",
              border: "1px solid #DEDEDE",
              color: data.playlistId ? "#7D61D6" : "#999",
            }}
            onClick={() => {
              seeAswerButtonOpenedQna();
            }}
          >
            Lihat Jawaban
          </button>
        )} */}

        {data.keepOpened === "1" && (
          <button
            style={{
              background: "#7D61D6",
              color: "#FFF",
            }}
            onClick={() => {
              if (!isLogin) {
                setOpenLogin(true);
                document.body.style.overflow = "hidden";
                return;
              }

              if (parseInt(quotaQna) === 0) {
                setOpenSubs(true);
                document.body.style.overflow = "hidden";
                return;
              }
              askQuestionButtonOpenedQna(data);
            }}
          >
            Tanya Sekarang
          </button>
        )}
      </div>
      {askQuestion && (
        <HomeQnaContainer
          onClose={() => setAskQuestion(false)}
          questionData={questionData}
          profileRules={{}}
          qnaFormStatus={undefined}
        />
      )}
    </div>
  );
}

export default withRouter(CardQna);
