import axios from "axios";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { PaymentSuccess } from "../components/Payment/PaymentSuccess";
import { DetailInvoice } from "../components/Payment/DetailInvoice";
import "../css/PaymentPackage.css";
import { getTransaction, getTransactionDetails } from "../services/transactions";
import Loading from "./Loading";
import { getAdditionalContent, getAdditionalProductByProductId } from "../services/products";
import { getEventById } from "../services/events";
function Payment(props) {
  const transactionId = props.match.params.transaction_id ? props.match.params.transaction_id : localStorage.getItem("fe941a31");;
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [transaction, setTransaction] = useState(undefined);
  const [productFromTransaction, setProductFromTransaction] = useState({});
  const [additionalContent, setAdditionalContent] = useState({});
  const [event, setEvent] = useState({});
  const product = {
    price: 10000,
    productId: "28a64a589e2864419a8a4638abc83d19",
  };

  React.useEffect(() => {
    if (!transactionId || transactionId === null) {
      return;
    }

    getTransaction(transactionId).then((res) => {
      setTransaction(res.data?.[0]);

      if (res.data?.[0].status === "3") {
        setIsPaymentSuccess(true);
      }
    });

    getTransactionDetails(transactionId).then((res) => {
      if (!res?.data?.[0]?.product_id) {
        return;
      }

      getAdditionalProductByProductId(res?.data?.[0]?.product_id)
      .then((res) => {
        setProductFromTransaction(res?.data[0]);

        const eventId = res?.data?.[0]?.eventId;

        getEventById(eventId)
        .then((res) => {
          setEvent(res?.data)
        });

        getAdditionalContent(eventId)
        .then((res) => {
          setAdditionalContent(res?.data?.[0]);
          setIsLoading(false);
        })
      })
    });
  }, [transactionId]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div id="payment-package">
      <div className="headers">
        <img src="/assets/images/fammi-logo.svg" alt="logos" />
      </div>
      <div className="parent-containers">
        <div className="containers">
          <div id="e_mobs">
            {isPaymentSuccess ? (
              <PaymentSuccess product={product} transaction={transaction} productFromTransaction={productFromTransaction} additionalContent={additionalContent} event={event}/>
              ) : (
              <DetailInvoice transaction={transaction} />
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Payment);
