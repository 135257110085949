import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, withRouter } from "react-router";
import "../css/Events.css";

import MobileHeader from "../EventComponent/MobileHeader";
import Descriptions from "../EventComponent/Malaysia/Descriptions";
import SurveyForm from "../EventComponent/Malaysia/SurveyForm";
import Loading from "./Loading";
import closeIcon from "../images/close-icon.svg";
import warningIconCircle from "../images/warning-circle.svg";

function WebinarProfilePaud(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [eventData, setEventData] = useState({});
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [openLogin, setOpenLogin] = useState(true);
  const names = localStorage.getItem("name");
  const [memberType, setMembertype] = useState("non-member");
  const [tenants, setTenants] = useState(null);
  const [activePackages, setActivePackages] = useState(null);
  const eventCode = props.match.params.event_code;
  const [email, setEmail] = useState("");
  const [form, setForm] = React.useState({});
  const [loadingForm, setLoadingForm] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [showErrorForm, setShowErrorForm] = useState(false);
  const [errorFormText, setErrorFormText] = useState(
    "You have to fill all of mandatory form"
  );

  const isDisabledButton =
    form?.name === "" ||
    form?.usia === "" ||
    form?.no_whatsapp === "" ||
    form?.kota === "" ||
    form?.gender === "" ||
    form?.asal_sekolah === "";

  const submitProfile = async () => {
    setLoadingForm(true);
    if (!form || Object.keys(form).length < 6) {
      setErrorForm(true);
      setLoadingForm(false);
      setShowErrorForm(true);
      return;
    }

    setErrorForm(false);

    await axios
      .post(process.env.REACT_APP_FETCH_URL + "/profile", {
        memberId: "malaysia-" + new Date().getTime(),
        profileData: JSON.stringify(form),
      })
      .then((res) => {
        setLoadingForm(false);
        window.location.href = "/survey-malaysia-success";
      })
      .catch((err) => {
        if (err) {
          setLoadingForm(false);
          alert("Terjadi Kesalahan, Coba ulangi!");
        }
      });
  };
  return (
    <React.Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <div id="detail-schedule">
          <MobileHeader
            tenants={tenants}
            activePackages={activePackages}
            eventCode={eventCode}
            token={token}
            setOpenLogin={setOpenLogin}
            names={names}
            eventData={eventData}
            memberType={memberType}
            email={email}
            forceDisplayed={false}
          />
          <div className="parent-event" style={{ overflowY: "auto" }}>
            <div className="event-container">
              <div id="e_mobs" style={{ padding: 16, paddingTop: 32 }}>
                <Descriptions />
                <SurveyForm
                  login={false}
                  isEmail
                  tenantId={eventData.tenantId}
                  eventData={eventData}
                  token={token}
                  form={form}
                  setForm={setForm}
                  isProfile
                  errorForm={errorForm}
                  setShowErrorForm={setShowErrorForm}
                  setErrorFormText={setErrorFormText}
                />
                <div
                  style={{
                    background: "#FFFFFF",
                    boxShadow: "0px -5px 30px rgba(0, 0, 0, 0.1)",
                    borderRadius: "12px 12px 0px 0px",
                    padding: "16px",
                    position: "fixed",
                    bottom: "0px",
                    width: "100%",
                    left: 0,
                  }}
                >
                  <button
                    style={{
                      background:
                        isDisabledButton || loadingForm ? "#999" : "#6C60E0",
                      borderRadius: "12px",
                      border: "none",
                      width: "100%",
                      fontFamily: "'Rubik'",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: "#FFFFFF",
                      padding: "12px",
                    }}
                    onClick={submitProfile}
                    disabled={isDisabledButton || loadingForm}
                  >
                    {loadingForm ? "Loading..." : "Send"}
                  </button>
                </div>
              </div>
              <div
                className="two"
                style={{ maxWidth: "360px", margin: "36px auto 0" }}
              >
                <Descriptions />
                <SurveyForm
                  login={false}
                  isEmail
                  token={token}
                  form={form}
                  setForm={setForm}
                  isProfile
                  errorForm={errorForm}
                  setShowErrorForm={setShowErrorForm}
                  setErrorFormText={setErrorFormText}
                />
                <div>&nbsp;</div>
                <div
                  style={{
                    background: "#FFFFFF",
                    boxShadow: "0px -5px 30px rgba(0, 0, 0, 0.1)",
                    borderRadius: "12px 12px 0px 0px",
                    padding: "16px",
                    position: "fixed",
                    bottom: "0px",
                    width: "100%",
                    left: 0
                  }}
                >
                  <button
                    style={{
                      background:
                        isDisabledButton || loadingForm ? "#999" : "#6C60E0",
                      borderRadius: "12px",
                      border: "none",
                      width: "100%",
                      fontFamily: "'Rubik'",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: "#FFFFFF",
                      padding: "12px",
                    }}
                    onClick={submitProfile}
                    disabled={isDisabledButton || loadingForm}
                  >
                    {loadingForm ? "Loading..." : "Send"}
                  </button>
                </div>
              </div>

              {showErrorForm && (
                <React.Fragment>
                  <div
                    style={{
                      position: "fixed",
                      top: "0px",
                      left: "0px",
                      height: "100%",
                      width: "100%",
                      background: "rgba(0, 0, 0, 0.5)",
                      zIndex: "9999",
                    }}
                  ></div>
                  <div
                    style={{
                      position: "fixed",
                      bottom: "0px",
                      left: "0px",
                      width: "100%",
                      height: "50%",
                      zIndex: "9999",
                      background: "#FFF",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        width: "100%",
                        zIndex: "999999999",
                        height: "67%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "16px",
                          fontFamily: "Rubik",
                          fontWeight: 500,
                          fontSize: "16px",
                        }}
                      >
                        <div></div>
                        <div
                          onClick={() => {
                            setShowErrorForm(false);
                          }}
                        >
                          <img src={closeIcon} />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <div>
                          <img src={warningIconCircle} />
                        </div>
                        <div
                          style={{
                            marginTop: "12px",
                            fontFamily: "Rubik",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        >
                          {errorFormText}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#FFFFFF",
                        boxShadow: "0px -5px 30px rgba(0, 0, 0, 0.1)",
                        borderRadius: "12px 12px 0px 0px",
                        padding: "16px",
                        position: "fixed",
                        bottom: "0px",
                        width: "100%",
                        left: 0,
                      }}
                    >
                      <button
                        style={{
                          background:
                            isDisabledButton || loadingForm
                              ? "#999"
                              : "#6C60E0",
                          borderRadius: "12px",
                          border: "none",
                          width: "100%",
                          fontFamily: "'Rubik'",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "17px",
                          color: "#FFFFFF",
                          padding: "12px",
                        }}
                        onClick={() => {
                          setShowErrorForm(false);
                        }}
                      >
                        Tutup
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default withRouter(WebinarProfilePaud);
