import React from "react";
import { withRouter } from "react-router-dom";
import Carousels from "../Carousels";
import CardNarasumber from "./CardNarasumber";
import "./Narasumber.css";

function Narasumber(props) {
  const { data, isLogin, setOpenLogin } = props;
  return (
    <div id="narasumber-section" className="container-topepisode">
      <Carousels
        numberOfShow={8}
        showDots
        title="Narasumber"
        path="/episode/speaker"
      >
        {data?.map((item, index) => (
          <CardNarasumber
            data={item}
            key={index}
            push={() => {
              if (!isLogin) {
                setOpenLogin(true);
                return;
              }
              props.history.push("/u/" + item?.username);
            }}
          />
        ))}
      </Carousels>
    </div>
  );
}
export default withRouter(Narasumber);
