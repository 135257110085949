import React from "react";
import axios from "axios";
import arrowDown from "../../images/arrow-down.svg";
import checklistIcon from "../../images/checklist-aff.svg";
import closeIcon from "../../images/close-icon.svg";
import searchIconRadius from "../../images/searchicon-grey-radius.svg";
import searchIcon from "../../images/searchicon-grey.svg";
import "./SurveyForm.css";

function SurveyForm(props) {
  const {
    token,
    onClose,
    profile,
    setProfile,
    qnaFormStatus,
    setQna,
    qna,
    setForm,
    form,
    handleOnSubmit,
    loading,
    profileRules,
    profileMember,
    alertForm,
    isProfile,
    errorForm,
    setShowErrorForm,
    setErrorFormText
  } = props;

  const [temp, setTemp] = React.useState([]);
  const [filtered, setFiltered] = React.useState([]);

  const questions = [
    {
      id: 0,
      name: "questions",
      label: "Let’s write down questions about child behavior problems below!",
      type: "textarea",
      placeholder: "Describe your child behaviour problems",
      alert: null,
      desc: null,
      is_onlogin: true,
      on_rules: true,
    },
    {
      id: 1,
      name: "category",
      label: "Enter the category of the problem you are facing",
      desc: "Examples: Child Emotions, Learning Problems, Communication",
      type: "textarea",
      placeholder: "Examples: Child Emotions, Learning Problems, Communication",
      alert: null,
      is_onlogin: true,
      on_rules: true,
    },
  ];
  const inputProfile = [
    {
      id: 0,
      name: "name",
      label: "Full Name",
      type: "text",
      placeholder: "Enter your full name",
      alert: null,
      desc: null,
      is_onlogin: true,
      on_rules: true,
    },
    {
      id: 1,
      name: "age",
      label: "Age",
      type: "text",
      placeholder: "Enter your age",
      alert: null,
      desc: null,
      is_onlogin: true,
      on_rules: true,
    },
    {
      id: 2,
      name: "wa_number",
      label: "WhatsApp Number",
      type: "text",
      placeholder: "Enter your WhatsApp Number",
      alert: null,
      desc: null,
      is_onlogin: true,
      on_rules: true,
      validation: (e) => {
        return !isNaN(e);
      }
    },
    {
      id: 3,
      name: "email",
      label: "Email",
      type: "text",
      placeholder: "Enter your email address",
      alert: null,
      desc: null,
      is_onlogin: true,
      on_rules: true,
    },
    {
      id: 4,
      name: "child_name",
      label: "Name of your children’s school ~ if any",
      type: "text",
      placeholder: "Enter your children’s school name ",
      alert: null,
      desc: null,
      is_onlogin: true,
      on_rules: false,
    },
  ];

  const [showOptions, setShowOptions] = React.useState("");
  React.useEffect(() => {
    if (showOptions === "") {
      document.body.style.overflow = "auto";
      return;
    }

    document.body.style.overflow = "hidden";
  }, [showOptions]);

  return (
    <div id="prog-profile" className={isProfile && "profs"}>
      <div className="boxes" style={{ boxShadow: "none" }}>
        <div className="scrolled-mob">
          <div
            className="bodys"
            style={{ padding: "0px", marginBottom: "80px" }}
          >
            <div
              style={{
                borderBottom: "solid 3px #6C60E0",
                fontWeight: 600,
                color: "#6C60E0",
                fontFamily: "Rubik",
                fontSize: "10px",
                paddingBottom: "10px",
                marginBottom: "16px",
              }}
            >
              Complete Your Biodata
            </div>
            {questions?.map((il) => (
              <div key={il?.id} className="item-list survey">
                <p className="pg">{il?.label}</p>
                {il?.desc && <p className="pg-desc" style={{fontFamily:"Rubik", fontSize: "10px", color:"#000"}}>{il?.desc}</p>}
                {il?.alert && (
                  <div className="alerto">
                    <img src="/assets/images/e_alert.svg" alt="alert" />
                    <p>{il?.alert}</p>
                  </div>
                )}
                {il?.type === "single" ? (
                  <div
                    className="fp_gender"
                    style={{
                      flexDirection: il?.choice.length > 2 ? "column" : "row",
                    }}
                  >
                    {il?.choice.map((d, i) => (
                      <div
                        onClick={() => {
                          setForm({
                            ...form,
                            [il?.name]: d,
                          });
                        }}
                        key={d}
                        className={`fp_gender_item${
                          form?.[il?.name] === d ? "_active" : ""
                        } ${"profile_enabled"} 
                          ${
                            errorForm &&
                            (form?.[il?.name] === "" || !form?.[il?.name])
                              ? ` mandatory`
                              : ``
                          }
                          `}
                        style={{
                          width: il?.choice.length > 2 ? "100%" : "47.5%",
                          marginBottom: il?.choice.length > 2 ? "8px" : "0px",
                        }}
                      >
                        <div />
                        <p>{d}</p>
                      </div>
                    ))}
                  </div>
                ) : il?.type === "multiple" ? (
                  <div className="wrapper_fq">
                    {il?.choice?.map((d, i) => (
                      <div
                        className={`fq_select${
                          form?.[il?.name]?.indexOf(d.id) > -1 ? "_active" : ""
                        }
                          ${
                            errorForm &&
                            (form?.[il?.name] === "" || !form?.[il?.name])
                              ? ` mandatory`
                              : ``
                          }
                          `}
                        key={d?.id}
                        onClick={() => {
                          let currentSelection = form?.[il?.name] ?? [];
                          const theIndex = currentSelection?.indexOf(d.id);
                          if (theIndex > -1) {
                            currentSelection = currentSelection.filter(
                              (c) => c !== d.id
                            );
                          } else {
                            currentSelection.push(d.id);
                          }
                          setForm({
                            ...form,
                            [il.name]: currentSelection,
                          });
                        }}
                      >
                        <img
                          src={`/assets/images/fp_checks.svg`}
                          alt="checks"
                        />
                        <p>{d?.name}</p>
                      </div>
                    ))}
                  </div>
                ) : il?.type === "textarea" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <textarea
                      className={`inp_item ${
                        errorForm &&
                        (form?.[il?.name] === "" || !form?.[il?.name])
                          ? ` mandatory`
                          : ``
                      }`}
                      placeholder={il?.placeholder}
                      style={{height: "123px"}}
                      value={form?.[il?.name]}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [il?.name]: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                )
                  :
                  (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      className={`inp_item ${
                        errorForm &&
                        (form?.[il?.name] === "" || !form?.[il?.name])
                          ? ` mandatory`
                          : ``
                      }`}
                      type="text"
                      placeholder={il?.placeholder}
                      // disabled={il?.is_form && token}
                      value={form?.[il?.name]}
                      onChange={(e) => {
                        if (il?.validation) {
                          const res = il.validation(e.target.value);
                          if (!res) {
                            setErrorFormText("Nomor WhatsApp harus berupa angka");
                            setShowErrorForm(true);
                            return false;
                          }
                        }

                        setErrorFormText("");
                        
                        setForm({
                          ...form,
                          [il?.name]: e.target.value,
                        });
                      }}
                    />
                  </div>
                )}
                {il?.add_on && (
                  <input
                    className={`inp_item`}
                    placeholder="Perlu kategori lainnya? Ketik di sini"
                  />
                )}
              </div>
            ))}

<div
              style={{
                borderBottom: "solid 3px #6C60E0",
                fontWeight: 600,
                color: "#6C60E0",
                fontFamily: "Rubik",
                fontSize: "10px",
                paddingBottom: "10px",
                marginBottom: "16px",
              }}
            >
              Complete Your Biodata
            </div>
            {inputProfile?.map((il) => (
              <div key={il?.id} className="item-list survey">
                <p className="pg">{il?.label}</p>
                {il?.desc && <p className="pg-desc" style={{fontFamily:"Rubik", fontSize: "10px", color:"#000"}}>{il?.desc}</p>}
                {il?.alert && (
                  <div className="alerto">
                    <img src="/assets/images/e_alert.svg" alt="alert" />
                    <p>{il?.alert}</p>
                  </div>
                )}
                {il?.type === "single" ? (
                  <div
                    className="fp_gender"
                    style={{
                      flexDirection: il?.choice.length > 2 ? "column" : "row",
                    }}
                  >
                    {il?.choice.map((d, i) => (
                      <div
                        onClick={() => {
                          setForm({
                            ...form,
                            [il?.name]: d,
                          });
                        }}
                        key={d}
                        className={`fp_gender_item${
                          form?.[il?.name] === d ? "_active" : ""
                        } ${"profile_enabled"} 
                          ${
                            errorForm &&
                            (form?.[il?.name] === "" || !form?.[il?.name])
                              ? ` mandatory`
                              : ``
                          }
                          `}
                        style={{
                          width: il?.choice.length > 2 ? "100%" : "47.5%",
                          marginBottom: il?.choice.length > 2 ? "8px" : "0px",
                        }}
                      >
                        <div />
                        <p>{d}</p>
                      </div>
                    ))}
                  </div>
                ) : il?.type === "multiple" ? (
                  <div className="wrapper_fq">
                    {il?.choice?.map((d, i) => (
                      <div
                        className={`fq_select${
                          form?.[il?.name]?.indexOf(d.id) > -1 ? "_active" : ""
                        }
                          ${
                            errorForm &&
                            (form?.[il?.name] === "" || !form?.[il?.name])
                              ? ` mandatory`
                              : ``
                          }
                          `}
                        key={d?.id}
                        onClick={() => {
                          let currentSelection = form?.[il?.name] ?? [];
                          const theIndex = currentSelection?.indexOf(d.id);
                          if (theIndex > -1) {
                            currentSelection = currentSelection.filter(
                              (c) => c !== d.id
                            );
                          } else {
                            currentSelection.push(d.id);
                          }
                          setForm({
                            ...form,
                            [il.name]: currentSelection,
                          });
                        }}
                      >
                        <img
                          src={`/assets/images/fp_checks.svg`}
                          alt="checks"
                        />
                        <p>{d?.name}</p>
                      </div>
                    ))}
                  </div>
                ) : il?.type === "textarea" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <textarea
                      className={`inp_item ${
                        il?.on_rules &&
                        errorForm &&
                        (form?.[il?.name] === "" || !form?.[il?.name])
                          ? ` mandatory`
                          : ``
                      }`}
                      placeholder={il?.placeholder}
                      style={{height: "123px"}}
                      value={form?.[il?.name]}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [il?.name]: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                )
                  :
                  (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      className={`inp_item ${
                        il?.on_rules &&
                        errorForm &&
                        (form?.[il?.name] === "" || !form?.[il?.name])
                          ? ` mandatory`
                          : ``
                      }`}
                      type="text"
                      placeholder={il?.placeholder}
                      // disabled={il?.is_form && token}
                      value={form?.[il?.name]}
                      onChange={(e) => {
                        if (il?.validation) {
                          const res = il.validation(e.target.value);
                          if (!res) {
                            setErrorFormText("Whatsapp Number cannot using alphabet");
                            setShowErrorForm(true);
                            return false;
                          }
                        }

                        setErrorFormText("");
                        
                        setForm({
                          ...form,
                          [il?.name]: e.target.value,
                        });
                      }}
                    />
                  </div>
                )}
                {il?.add_on && (
                  <input
                    className={`inp_item`}
                    placeholder="Perlu kategori lainnya? Ketik di sini"
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveyForm;
