import axios from "axios";

export const getAdditionalProducts = (eventId) => {
  return axios.get(
      process.env.REACT_APP_FETCH_URL + "/content_products_events/" + eventId
  );
} 

export const getAdditionalContent = (eventId) => {
  return axios.get(
      process.env.REACT_APP_FETCH_URL + "/additional_contents/" + eventId
  );
} 

export const getAdditionalProductByProductId = (productId) => {
  return axios.get(
    process.env.REACT_APP_FETCH_URL + "/content_product/" + productId
  );
}