import React from "react";
import "./CardPrograms.css";
import { useHistory } from "react-router-dom";
import { enroll } from "../../services/packages";
import { useTranslation } from "react-i18next";

function CardPrograms(props) {
  const {
    isMobile,
    isNotEnroll,
    programs,
    isProfileComplete,
    setIsRegisterSchedule,
    materi,
    eventData,
    setOpenLogin,
    setPremiumDialog,
    expired,
    memberType,
    setExpired
  } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const hostName = window.location.host?.replace("www.", "").split(".");
  const subDomain =
    hostName &&
    hostName[0].indexOf("localhost") < 0 &&
    hostName[0].indexOf("fammi") < 0 &&
    hostName[0].indexOf("beta") < 0
      ? hostName[0]
      : "";
  const eventCode =
    subDomain && subDomain !== ""
      ? subDomain
      : window.location.pathname.split("/")[2];
  const comparison = programs?.totalDone + programs?.totalUndone * 1;
  const percentage = Math.ceil(
    (programs?.totalDone / (comparison === 0 ? 1 : comparison)) * 100
  );
  const totalActivity =
    parseInt(programs?.totalAudio) +
    parseInt(programs?.totalArticle) +
    parseInt(programs?.totalPanduan) +
    parseInt(programs?.totalQuiz) +
    parseInt(programs?.totalPractice);
  const token = localStorage.getItem("token");

  return (
    <div id="card-programs" className={isMobile && "is-mobile"}>
      <img
        src={`https://fammi.ly/uploads/program/images/${programs?.cover}`}
        alt="img"
        className="img"
      />
      <h5>{programs?.name}</h5>
      <div className="flex-sec">
        <div>
          <img src="/assets/images/program_materi.svg" alt="materi" />
          <p>
            {parseInt(programs?.totalAudio) +
              parseInt(programs?.totalArticle) +
              parseInt(programs?.totalPanduan)}{" "}
            {t("materials")}
          </p>
        </div>
        <div>
          <img src="/assets/images/program_quiz.svg" alt="materi" />
          <p>
            {programs?.totalQuiz} {t("quizes")}
          </p>
        </div>
        <div>
          <img src="/assets/images/program_praktik.svg" alt="materi" />
          <p>
            {programs?.totalPractice} {t("practices")}
          </p>
        </div>
      </div>
      <div className="bar-sec">
        <div style={{ width: `${percentage}%` }} />
      </div>
      <div className="last-sec">
        <h5 style={{ fontSize: 12 }}>
          {materi && `${t("achieved")} ${percentage > 100 ? 100 : percentage}%`}
          {!materi &&
            `${t("done")} ${programs?.totalDone} ${t(
              "from"
            )} ${totalActivity} ${t("activities")}`}
        </h5>
        {materi && (
          <div
            onClick={() => {
              if(expired) {
                return setPremiumDialog(true);
              }

              if (eventData?.isPaid !== "true" && memberType && memberType !== "payforMicrosite") {
                setExpired(false);
                return setPremiumDialog(true);
              } 

              history.push(`/e/${eventCode}/programs/${programs.packageId}`);
            }}
          >
            <p>{t("see-materials")}</p>
            <img src="/assets/images/arrow-right.svg" alt="arr-right" />
          </div>
        )}
        {!materi && (
          <div>
            <p>{`${t("achieved")} ${percentage > 100 ? 100 : percentage}%`}</p>
          </div>
        )}
      </div>
      {isNotEnroll && (
        <div className="cta-enrolls">
          <button
            onClick={async () => {
              if (!token && setOpenLogin) {
                setOpenLogin(true);
                return;
              }
              if (!isProfileComplete) {
                setIsRegisterSchedule(true);
                return;
              }
              await enroll({
                activityId: new Date().getTime(),
                packageId: programs.packageId,
                memberId: localStorage.getItem("token"),
                eventId: eventData.eventId,
              });

              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }}
          >
            {t("join-program")}
          </button>
        </div>
      )}
    </div>
  );
}

export default CardPrograms;
