import moment from "moment";

function writeEndLog() {
  if (localStorage.getItem("logs") === null) {
    return;
  }

  let currentLogs = JSON.parse(localStorage.getItem("logs"));

  currentLogs.map((log) => {
    if (log.endTime === null) {
      log.endTime = moment().format("YYYY-MM-DD HH:mm:ss");
    }
  });

  localStorage.setItem("logs", JSON.stringify(currentLogs));
}
export default writeEndLog;
