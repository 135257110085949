import axios from "axios";
import moment from "moment";

export const addToCart = async (item, payment) => {
  const token = localStorage.getItem("token");
  const code = "INV-" + moment().format("YYYY/MM/DD-" + parseInt(Math.random() * 10000));

  let payload = {
    transaction_id: item.transaction_id,
    transaction_voucher_id: "",
    member_id: token,
    amount: item.price,
    discount: 0,
    total: item.price,
    unique_code: 0,
    code: code,
    status: 1,
    note: "",
    va_number: "",
    payment_method: payment.payment_method,
    created: moment().format("YYYY-MM-DD HH:mm:ss"),
    product_id: item.productId,
  };

  await axios
    .post(process.env.REACT_APP_FETCH_URL + "/addtocartsp", payload);
  
  return {
    code
  };
};

export const getVa = async (item) => {
  var FormData = require('form-data');
  var data = new FormData();
  data.append('order_id', item.code);
  data.append('gross_amount', item.price);
  data.append('payment_method', item.payment_method);

  var config = {
    method: 'post',
    url: process.env.REACT_APP_PAYMENT_URL,
    data : data
  };

  return axios(config);
}

export const setVa = async (item) => {
  await axios
  .post(process.env.REACT_APP_FETCH_URL + "/transactions/setva", {
    transactionId: item.transactionId,
    vaNumber: item.vaNumber,
    paymentMethod: item.paymentMethod
  });
}

export const getTransaction = (transactionId) => {
  return axios.get(`${process.env.REACT_APP_FETCH_URL}/transactions/theid/${transactionId}`)
}

export const getTransactionDetails = (transactionId) => {
  return axios.get(`${process.env.REACT_APP_FETCH_URL}/transaction_products/${transactionId}`)
}