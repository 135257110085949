import firebase from "firebase/app";
import "firebase/auth";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { withRouter } from "react-router-dom";
import { LoginPopup } from "../../EventComponent/OrangTuaBerpijar/Login/LoginPopup";
import LoginEvent from "../../Form/LoginEvent";
import RegisterEvent from "../../Form/RegisterEvent";
import "./PopupAuthEvent.css";
function PopupAuthEvent(props) {
  const {
    setOpenLogin,
    tenantId,
    eventCode,
    idl,
    setIdl,
    mode,
    setIsLoginSchedule,
    setIsRegisterSchedule,
    setRegisteredMode,
    setEmail,
    setToken,
  } = props;
  const [step, setStep] = useState(idl ?? 0);
  const [errorStep, setErrorStep] = useState(null);
  const [values, setValues] = useState({
    email: "",
    code: "",
    newPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const handleTextReset = (e) => {
    setValues({ ...values, [e?.target?.name]: e?.target?.value });
  };
  const handleGoogleAuth = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        if (res) {
          const payload = JSON.stringify({
            provider: "google",
            email: res?.user?.email,
            name: res?.user?.displayName,
            token: res?.credential.accessToken,
            tenantId: tenantId,
          });
          const url = process.env.REACT_APP_FETCH_URL + "/socmed_auth";

          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: payload,
          })
            .then((result) => result.json())
            .then((result) => {
              if (result && result?.isAuthenticate) {
                // localStorage.clear();
                const redirectUrl = localStorage.getItem("c892072");
                localStorage.setItem("token", result.token);
                localStorage.setItem("name", result.name);
                localStorage.setItem("a01c12", tenantId);

                window.fbq("track", "CompleteRegistration");

                if (redirectUrl) {
                  window.location.href = redirectUrl;
                  return;
                }

                if (mode) {
                  if (mode === "event") {
                    setOpenLogin(false);
                    setIsRegisterSchedule(true);
                    setRegisteredMode("google");
                    return;
                  }

                  if (isMobile && mode !== "non-schedule") {
                    window.location.href =
                      window.location.href +
                      `/login/google/${res?.user?.email}`;
                    return;
                  }
                  if (isMobile && mode === "non-schedule") {
                    window.location.href = `/e/${eventCode}/schedule/0412f9285e5c4dd6460564640cdd9b38/login/google/${res?.user?.email}`;
                    return;
                  }

                  setToken(result.token);
                  setEmail(res?.user?.email);
                  setRegisteredMode("google");
                  setOpenLogin(false);
                  setIsLoginSchedule(true);
                  return;
                }

                setTimeout(() => {
                  if (localStorage.getItem("pleasecomeback") !== null) {
                    this.addToCart();
                    return;
                  }
                  window.location.reload();
                  setOpenLogin(false);
                }, 500);
              }
            });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleFacebookAuth = () => {
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        if (res) {
          const payload = JSON.stringify({
            provider: "facebook",
            email: res?.user?.email,
            name: res?.user?.displayName,
            token: res?.user?.refreshToken,
            tenantId: tenantId,
          });
          const url = process.env.REACT_APP_FETCH_URL + "/socmed_auth";

          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: payload,
          })
            .then((result) => result.json())
            .then((result) => {
              if (result && result?.isAuthenticate) {
                // localStorage.clear();
                const redirectUrl = localStorage.getItem("c892072");
                localStorage.setItem("token", result.token);
                localStorage.setItem("name", result.name);
                localStorage.setItem("a01c12", tenantId);

                window.fbq("track", "CompleteRegistration");

                if (redirectUrl) {
                  window.location.href = redirectUrl;
                  return;
                }

                if (mode) {
                  if (mode === "event") {
                    setOpenLogin(false);
                    setIsRegisterSchedule(true);
                    setRegisteredMode("facebook");
                    return;
                  }

                  if (isMobile && mode !== "non-schedule") {
                    window.location.href =
                      window.location.href +
                      `/login/facebook/${res?.user?.email}`;
                    return;
                  }
                  if (isMobile && mode === "non-schedule") {
                    window.location.href = `/e/${eventCode}/schedule/0412f9285e5c4dd6460564640cdd9b38/login/google/${res?.user?.email}`;
                    return;
                  }

                  setToken(result.token);
                  setEmail(res?.user?.email);
                  setRegisteredMode("facebook");
                  setOpenLogin(false);
                  setIsLoginSchedule(true);
                  return;
                }

                setTimeout(() => {
                  if (localStorage.getItem("pleasecomeback") !== null) {
                    this.addToCart();
                    return;
                  }
                  window.location.reload();
                  setOpenLogin(false);
                }, 500);
              }
            });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };
  const RESETSTEP = [
    {
      name: "Input Email",
    },
    {
      name: "Verifikasi",
    },
    {
      name: "Reset",
    },
  ];
  const handleSendCode = async () => {
    setLoading(true);
    const url = process.env.REACT_APP_FETCH_URL + "/sendotpreset";
    const postedBody = JSON.stringify({ phone: values?.email });
    await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: postedBody,
    })
      .then((res) => res.text())
      .then(
        (result) => {
          if (result !== "false") {
            setLoading(false);
            setStep(6);
            setErrorStep(null);
            return;
          }
          setLoading(false);
          setErrorStep("Format email tidak sesuai");
        },
        (error) => {
          setLoading(false);
          setErrorStep("Format email tidak sesuai");
          console.log(error);
        }
      );
  };
  const handleCheckOtp = () => {
    setLoading(true);
    const url = process.env.REACT_APP_FETCH_URL + "/checkotp";
    const postedBody = JSON.stringify({ otpCode: values?.code });
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: postedBody,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result && result.isAuthenticate) {
            setLoading(false);
            setStep(7);
            setErrorStep(null);
            return;
          }
          setLoading(false);
          setErrorStep("Kode verifikasi tidak valid");
        },
        (error) => {
          console.log(error);
          setLoading(false);
          setErrorStep("Kode verifikasi tidak valid");
        }
      );
  };
  const handleNewPassword = () => {
    setLoading(true);

    const url = process.env.REACT_APP_FETCH_URL + "/forgotpassword";
    let payload = JSON.stringify({
      email: values?.email,
      password: values?.newPassword,
    });

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: payload,
    })
      .then((res) => res.text())
      .then(
        (result) => {
          if (result) {
            setStep(8);
            setLoading(false);
            setErrorStep(null);
            return;
          }
          setLoading(false);
          setErrorStep("Ubah Password Gagal, Coba Lagi");
        },
        (error) => {
          console.log(error);
          setLoading(false);
          setErrorStep("Ubah Password Gagal, Coba Lagi");
        }
      );
  };
  return (
    <div className="auths-box">
      <section>
        {(step < 5 || step === 8) && (
          <img
            onClick={() => {
              if (step > 0 && step !== 1) {
                setStep(0);
                return;
              }
            }}
            src={`/assets/images/e_${
              step === 2 || step === 3 || step === 4
                ? "back"
                : step === 8
                ? "verifs"
                : "people"
            }.svg`}
            alt="people"
          />
        )}
        {step >= 5 && step < 8 && (
          <div className="reset-step">
            {RESETSTEP?.map((d, i) => (
              <div key={i} className={`steps ${step - 5 >= i && "active"}`}>
                <div className="numbers">
                  <p>{i + 1}</p>
                </div>
                <p>{d?.name}</p>
                {i !== 2 && <hr />}
              </div>
            ))}
          </div>
        )}
        <h1>
          {eventCode === "orangtuaberpijar"
            ? "Masuk dengan akun Pijar Belajar untuk melanjutkan"
            : step === 0
            ? "Yuk daftar untuk mengikuti webinar"
            : step === 1
            ? "Yuk masuk untuk mengikuti webinar"
            : step === 2
            ? "Lengkapi biodata berikut untuk daftar"
            : step === 3
            ? "Isi email dan password untuk masuk"
            : step === 4
            ? "Email kamu sudah terdaftar sebelumnya"
            : step === 5
            ? "Masukan email untuk reset akun kamu"
            : step === 6
            ? "Cek email kamu dan masukan kode verifikasi"
            : step === 7
            ? "Buat password baru untuk akun kamu"
            : step === 8 && "Password kamu telah berhasil direset"}
        </h1>
        {(step === 1 || step === 0) &&
          (eventCode === "orangtuaberpijar" && (step === 1 || step === 0) ? (
            <LoginPopup
              onLogin={() => {
                window.location.href = process.env.REACT_APP_PIJAR_LOGIN_PAGE;
                return;
              }}
              onRegister={() => {
                window.location.href = process.env.REACT_APP_PIJAR_REGISTER_PAGE;
                return;

              }}
              onCancel={() => {
                setOpenLogin(false);
                setIdl(0);
              }}
            />
          ) : (
            <div className="one">
              {
              }
              <button onClick={handleGoogleAuth}>
                <img src="/assets/images/google.svg" alt="google" />
                <p>{step === 0 ? "Daftar" : "Masuk"} Dengan Google</p>
              </button>
              <button onClick={handleFacebookAuth}>
                <img src="/assets/images/facebook.svg" alt="fb" />
                <p>{step === 0 ? "Daftar" : "Masuk"} Dengan Facebook</p>
              </button>
              <button
                onClick={() => {
                  if (step === 0) {
                    setStep(2);
                    return;
                  }

                  setStep(3);
                }}
              >
                <img src="/assets/images/e_mail.svg" alt="mail" />
                <p>{step === 0 ? "Daftar" : "Masuk"} Dengan Email</p>
              </button>
              <div>
                <hr />
                <p>atau</p>
                <hr />
              </div>
              <button
                onClick={() => {
                  if (step === 0) {
                    setStep(1);
                    return;
                  }
                  setStep(0);
                }}
                className="login-cta"
              >
                <img src="/assets/images/e_lock.svg" alt="lock" />
                <p>
                  {step === 0
                    ? "Sudah Punya Akun? Masuk"
                    : "Belum Punya Akun? Daftar"}{" "}
                </p>
              </button>
              <button
                onClick={() => {
                  setOpenLogin(false);
                  setIdl(0);
                }}
              >
                <img src="/assets/images/e_cross.svg" alt="cross" />
                <p>Nanti Aja</p>
              </button>
            </div>
          ))}
        {step === 2 && (
          <div className="two">
            <RegisterEvent
              setStep={setStep}
              tenantId={tenantId}
              eventCode={eventCode}
              setOpenLogin={setOpenLogin}
              setIdl={setIdl}
            />
          </div>
        )}
        {step === 3 && (
          <div className="two">
            <LoginEvent
              setStep={setStep}
              tenantId={tenantId}
              eventCode={eventCode}
              setOpenLogin={setOpenLogin}
              setIdl={setIdl}
              mode={mode}
              setIsRegisterSchedule={setIsRegisterSchedule}
              setRegisteredMode={setRegisteredMode}
            />
          </div>
        )}
        {step >= 4 ? (
          <div className="step-four">
            {step === 4 && (
              <p>
                Silakan gunakan email dan password yang sudah didaftarkan
                sebelumnya
              </p>
            )}
            {step === 8 && (
              <p>
                Silakan masuk dengan menggunakan password baru yang sudah
                diinputkan sebelumnya
              </p>
            )}
            {step >= 5 && step < 8 && (
              <div className="five-sections">
                <p>
                  {" "}
                  {step === 5
                    ? "Email yang Terdaftar"
                    : step === 6
                    ? "Masukkan 6 Digit Kode Verifikasi"
                    : step === 7 && "Buat Password Baru"}
                </p>
                <div
                  style={{
                    borderColor: errorStep && "#E85050",
                  }}
                >
                  <input
                    name={
                      step === 5
                        ? "email"
                        : step === 6
                        ? "code"
                        : step === 7 && "newPassword"
                    }
                    value={
                      step === 5
                        ? values?.email
                        : step === 6
                        ? values?.code
                        : step === 7 && values?.newPassword
                    }
                    onChange={(e) => handleTextReset(e)}
                    placeholder={`Masukkan ${
                      step === 5
                        ? "email"
                        : step === 6
                        ? "kode verifikasi"
                        : step === 7 && "password"
                    }`}
                  />
                </div>
                {errorStep ? (
                  <p
                    style={{
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: 12,
                      lineHeight: "14px",
                      color: "#E85050",
                      margin: "unset",
                      marginTop: 12,
                    }}
                  >
                    {errorStep}
                  </p>
                ) : (
                  ""
                )}
              </div>
            )}
            <button
              disabled={
                (step === 5 && values?.email === "") ||
                (step === 6 && values?.code === "") ||
                (step === 7 && values?.newPassword === "") ||
                loading
              }
              onClick={() => {
                if (step === 4) {
                  setStep(5);
                  return;
                }
                if (step === 5) {
                  handleSendCode();
                  return;
                }
                if (step === 6) {
                  handleCheckOtp();
                  return;
                }
                if (step === 7) {
                  handleNewPassword();
                  return;
                }
                setStep(3);
              }}
              className={`reset-cta ${step >= 5 && step < 8 && "fives"}`}
            >
              {loading ? (
                "Loading..."
              ) : step === 4 ? (
                <>
                  <img src="/assets/images/e_shield.svg" alt="lock" />
                  <p>Lupa Akun? Reset Sekarang</p>
                </>
              ) : step === 5 ? (
                "Reset Akun"
              ) : step === 6 ? (
                "Validasi Kode"
              ) : (
                step === 7 && "Reset Password"
              )}
              {step === 8 ? (
                <>
                  <img src="/assets/images/e_lock_white.svg" alt="lock" />
                  <p>Masuk dengan Password Baru</p>
                </>
              ) : (
                ""
              )}
            </button>
            {step === 4 ? (
              <div>
                <hr />
                <p>atau</p>
                <hr />
              </div>
            ) : (
              ""
            )}
            {step === 4 ? (
              <button
                onClick={() => {
                  if (step === 4) {
                    setStep(3);
                    return;
                  }
                }}
                className="remember-cta"
              >
                <img src="/assets/images/e_lock.svg" alt="lock" />
                <p>Ingat Akun Kamu? Masuk</p>
              </button>
            ) : (
              ""
            )}
            <button
              onClick={() => {
                if (step === 4) {
                  setStep(2);
                  return;
                }
                if (step === 5) {
                  setStep(4);
                  return;
                }
                if (step === 6) {
                  setStep(5);
                  return;
                }
                if (step === 7) {
                  setStep(6);
                  setValues({ ...values, newPassword: "" });
                  return;
                }
                if (step === 8) {
                  setOpenLogin(false);
                  setStep(0);
                  return;
                }
              }}
              className={`back ${step === 8 && " eights-cta"}`}
            >
              {step !== 8 ? (
                "Kembali"
              ) : (
                <>
                  <img src="/assets/images/e_cross.svg" alt="cross" />
                  <p>Nanti Aja</p>
                </>
              )}
            </button>
          </div>
        ) : (
          ""
        )}
      </section>
    </div>
  );
}

export default withRouter(PopupAuthEvent);
