import axios from "axios";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";

function LoginEvent(props) {
  const {
    tenantId,
    setStep,
    setOpenLogin,
    setIdl,
    mode,
    setIsRegisterSchedule,
    setRegisteredMode,
  } = props;
  const [form, setForm] = useState({
    email: "",
    name: "",
    password: "",
    phone: "",
    tenantId: tenantId,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const changeText = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    setIsLoading(true);
    await axios
      .post(process.env.REACT_APP_FETCH_URL + "/partner_login", {
        email: form?.email,
        password: form?.password,
        tenantId: form?.tenantId,
      })
      .then((res) => {
        if (res?.data && res?.data?.isAuthenticate) {
          const redirectUrl = localStorage.getItem("c892072");
          localStorage.clear();
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("name", res.data.name);
          localStorage.setItem("a01c12", form.tenantId);

          if (redirectUrl) {
            window.location.href = redirectUrl;
            return;
          }

          if (mode === "event") {
            setOpenLogin(false);
            setIsRegisterSchedule(true);
            setRegisteredMode("Akun Fammi");
            return;
          }

          if (
            window.location.href.indexOf("931812c3a2642304d0b620205c35fc08=") >
            -1
          ) {
            window.location.href = window.location.href.split(
              "931812c3a2642304d0b620205c35fc08="
            )[1];
            return;
          }

          window.location.reload();

          setOpenLogin(false);
          setIdl(0);
          return;
        }

        setIsLoading(false);
        setLoginError(true);
      })
      .catch((err) => {
        if (err) {
          setIsLoading(false);
          setLoginError(true);
        }
      });
  };
  return (
    <div className="form-register">
      <div className="form-item">
        <label>Email yang Terdaftar</label>
        <div
          style={{
            borderColor: loginError && "#E85050",
          }}
        >
          <input
            name="email"
            onChange={(e) => changeText(e)}
            type="email"
            value={form.email}
            placeholder="Masukkan email"
          />
        </div>
        {loginError && (
          <p
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: 12,
              lineHeight: "14px",
              color: "#E85050",
              margin: "unset",
              marginTop: 8,
            }}
          >
            {loginError}
          </p>
        )}
      </div>
      <div className="form-item">
        <label>Password</label>
        <div
          style={{
            borderColor: loginError && "#E85050",
          }}
        >
          <input
            name="password"
            onChange={(e) => changeText(e)}
            type={showPassword ? "text" : "password"}
            value={form.password}
            placeholder="Masukkan password"
          />
          <img
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            src={
              showPassword
                ? "/assets/images/eye-off.svg"
                : "/assets/images/eyes.svg"
            }
            alt="password"
          />
        </div>
        {loginError && (
          <p
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: 12,
              lineHeight: "14px",
              color: "#E85050",
              margin: "unset",
              marginTop: 8,
            }}
          >
            {loginError}
          </p>
        )}
      </div>
      <div className="kebijakan">
        <p>
          Dengan membuat akun FAMMI, Anda telah menyetujui{" "}
          <a href="/ketentuan">Ketentuan Layanan</a> dan{" "}
          <a href="/kebijakan">Kebijakan Data</a>
        </p>
      </div>
      <button
        disabled={
          !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
            form?.email
          ) ||
          form?.email === "" ||
          form?.password === ""
        }
        style={{
          backgroundColor:
            !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
              form?.email
            ) ||
            form?.email === "" ||
            form?.password === ""
              ? "#999"
              : "#6C60E0",
        }}
        onClick={handleLogin}
        className="btn-daftar"
      >
        {isLoading ? "Loading..." : "Masuk"}
      </button>
      <button onClick={() => setStep(1)} className="btn-back">
        Kembali
      </button>
    </div>
  );
}
export default withRouter(LoginEvent);
