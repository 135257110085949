import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { AudioPlayerNewLayout } from "../../components";
import formatDuration from "../../helpers/format-duration";
import writeLogIntoStorage from "../../helpers/write-log-into-local";
import Clock from "./assets/clock.svg";
import Close from "./assets/close.svg";
import Down from "./assets/down.svg";
import Unrate from "./assets/not-rate.svg";
import Pause from "./assets/pause.svg";
import Play from "./assets/play.svg";
import Rated from "./assets/rated.svg";
import StarOff from "./assets/star-off.svg";
import StarOn from "./assets/star-on.svg";
import Up from "./assets/up.svg";
import Wa from "./assets/wa.svg";
import "./PopupAudioEvent.css";
import ReasonBox from "./ReasonBox";

function PopupAudioEvent(props) {
  const {
    audioId,
    setAudioId,
    titleAudio,
    isPrograms,
    simpleMode,
    isWebinar,
    isNewLayout,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loads, setLoads] = useState(false);
  const [audio, setAudio] = useState({});
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlay, setIsPlay] = useState(false);
  const [clickedTime, setClickedTime] = useState(null);
  const [rate, setRate] = useState(0);
  const [currentRate, setCurrentRate] = useState(0);
  const [reason, setReason] = useState("");
  const [canAdd, setCanAdd] = useState(false);
  const [openRate, setOpenRate] = useState(false);
  const token = localStorage.getItem("token");
  const [shortUrl, setShortUrl] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    const audios = document.getElementById("audio");
    const setAudioData = () => {
      setDuration(audios.duration);
      setCurrentTime(audios.currentTime);
      setIsPlay(audios.paused ? false : true);
    };

    const setAudioTime = () => setCurrentTime(audio.currentTime);
    audios.src = audio?.file;
    audios.addEventListener("loadeddata", setAudioData);
    audios.addEventListener("timeupdate", setAudioTime);
  }, [audio?.file]);
  useEffect(() => {
    async function fetchAPI() {
      if (audioId) {
        const resAudio = await axios.get(
          "https://cc6bb196.fammi.ly/" +
            "audio_materials/" +
            audioId +
            "/" +
            token
        );
        await axios
          .post(
            process.env.REACT_APP_MODULE_DETAIL + "audio_materials/shorten_url",
            {
              url: window.location.href,
            }
          )
          .then(
            (result) => {
              if (result && result.data) {
                setShortUrl(result.data.data.url);
              }
            },
            (error) => {
              console.log(error);
            }
          );
        if (resAudio?.data) {
          const audioData = resAudio?.data?.data[0];
          setAudio(audioData);
          setCanAdd(resAudio?.data?.data?.[0]?.can_add_rate == "true");
          setRate(resAudio?.data?.data?.[0]?.score);
          setTimeout(() => {
            const audios = document.getElementById("audio");
            const audioSrc = document.getElementById("audioSrc");
            audioSrc.src = audioData?.file;
            audios.src = audioData?.file;
            audios.load();
          }, 100);
          setIsLoading(false);
          if (isPrograms) {
            setIsOpen(isPrograms);
          }
        }
      }
    }
    fetchAPI();
  }, [audioId, isPrograms]);

  useEffect(() => {
    const audios = document.getElementById("audio");
    if (clickedTime && clickedTime !== currentTime) {
      audios.currentTime = clickedTime;
      setClickedTime(null);
    }
  }, [clickedTime]);

  useEffect(() => {
    const audios = document.getElementById("audio");
    if (currentTime >= duration) {
      setIsPlay(false);
      setCurrentTime(0);
      audios.currentTime = 0;
      audios.pause();
      return;
    }
    setCurrentTime(audios.currentTime);
  }, [currentTime, duration]);

  const isProgramsAndDesktopModeStyles = useMemo(() => {
    if (isPrograms && !isMobile)
      return {
        container: {
          position: "unset",
          height: "auto",
          background: "unset",
          marginTop: "16px",
        },
        section: {
          boxShadow: "unset",
          borderRadius: 12,
          border: "1px solid #eaeaea",
        },
      };
    return {};
  }, [isPrograms, isMobile]);

  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX;
    const bar = document.querySelector(".audio-play");

    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  }
  const onTimeUpdate = (time) => setClickedTime(time);

  function handleTimeDrag(e) {
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = (eMove) => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener("mousemove", updateTimeOnMove);

    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", updateTimeOnMove);
    });
  }

  const curPercentage = (currentTime / duration) * 100;
  const durationMessage =
    audio.duration < 15000
      ? ", hanya " + parseInt(audio.duration / 1000) + " menit"
      : "";
  const message = `Dengerin langsung yuk! tentang: *${
    titleAudio ? titleAudio : audio.titles
  }* ${durationMessage}%0aBersama narasumber *${
    audio.speakers?.map((i) => i.name)[0]
  }*%0a%0aKlik link di bawah ini:%0ahttps://${
    window.location.hostname
  }/r/${shortUrl}`;

  const sendFeedback = async () => {
    setLoads(true);
    const postedBody = JSON.stringify({
      audioId: audioId,
      memberId: token,
      reason: reason,
      score: rate,
    });
    await axios
      .post(process.env.REACT_APP_FETCH_URL + "/feedback", postedBody, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res) {
          setTimeout(async () => {
            const a = await axios.get(
              "https://cc6bb196.fammi.ly/" +
                "audio_materials/" +
                audioId +
                "/" +
                token
            );
            setReason("");
            setCanAdd(a?.data?.data?.[0]?.can_add_rate == "true");
            setCurrentRate(a?.data?.data?.[0]?.rate);
            setIsOpen(false);
            setLoads(false);
          });
        }
      }, 1000);
  };

  const sendFeedbackBox = async () => {
    setLoads(true);
    const postedBody = JSON.stringify({
      audioId: audioId,
      memberId: token,
      reason: reason,
      score: rate,
    });
    await axios
      .post(process.env.REACT_APP_FETCH_URL + "/feedback", postedBody, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res) {
          setTimeout(async () => {
            const a = await axios.get(
              "https://cc6bb196.fammi.ly/" +
                "audio_materials/" +
                audioId +
                "/" +
                token
            );
            setReason("");
            setCanAdd(a?.data?.data?.[0]?.can_add_rate == "true");
            setCurrentRate(a?.data?.data?.[0]?.rate);
            setIsOpen(false);
            setLoads(false);
            setOpenRate(false);
          });
        }
      }, 1000);
  };

  const handlePlayAudio = () => {
    const audios = document.getElementById("audio");

    if (!isPlay) {
      audios.play();
      setIsPlay(true);
      const itemlog = {
        memberId: token,
        audioId: audioId,
        startTime: null,
        endTime: null,
      };
      writeLogIntoStorage("play", itemlog);
      return;
    }
    setIsPlay(false);
    audios.pause();
    writeLogIntoStorage("pause", null);
  };

  if (isNewLayout) {
    return (
      <AudioPlayerNewLayout
        audio={audio}
        titleAudio={titleAudio}
        isLoading={isLoading}
        isPlay={isPlay}
        handlePlayAudio={handlePlayAudio}
        handleTimeDrag={handleTimeDrag}
        currentTime={currentTime}
        duration={duration}
        onClose={() => setAudioId(null)}
      />
    );
  }

  return (
    <React.Fragment>
      <audio id="audio">
        <source id="audioSrc" src={audio?.file} />
      </audio>
      {!isOpen ? (
        <div
          className="parent-aud"
          style={isProgramsAndDesktopModeStyles.container}
        >
          <section style={isProgramsAndDesktopModeStyles.section}>
            {isLoading ? (
              <div className="loads">
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <>
                <div className="content">
                  <img
                    className="up"
                    onClick={() => setIsOpen(!isOpen)}
                    src={Up}
                    alt="up"
                  />
                  <div
                    className={`sectione-ones ${simpleMode ? "simple" : ""}`}
                  >
                    <img
                      onClick={handlePlayAudio}
                      src={isPlay ? Pause : Play}
                      alt="play"
                      className="play-desktop"
                    />
                    <div>
                      <h5>{titleAudio ? titleAudio : audio.titles}</h5>
                      {audio.speakers[0]?.name && (
                        <p>{audio.speakers[0]?.name}</p>
                      )}
                    </div>
                  </div>
                  <div
                    className={`sectione-twos ${simpleMode ? "simple" : ""}`}
                  >
                    <div
                      className="three"
                      style={{ display: simpleMode ? "none" : "inherit" }}
                    >
                      <div>
                        <img src={Clock} alt="clock" />
                        <p>{Math.floor(duration / 60)} Menit</p>
                      </div>
                      <div>
                        <img
                          src={
                            currentRate ? Rated : audio?.rate ? Rated : Unrate
                          }
                          alt="clock"
                        />
                        <p>
                          {currentRate
                            ? currentRate
                            : audio?.rate
                            ? audio?.rate
                            : "Belum Ada"}
                          {currentRate || audio?.rate ? "/5" : ""}
                        </p>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.open(
                            `https://api.whatsapp.com/send?text=${message}`,
                            "_blank"
                          );
                        }}
                      >
                        <img src={Wa} alt="clock" />
                        <p>Bagikan</p>
                      </div>
                    </div>
                    <div className="two">
                      <div
                        onMouseDown={(e) => handleTimeDrag(e)}
                        className="parent-indicator audio-play"
                      >
                        <div
                          style={{ width: `${curPercentage}%` }}
                          className="indicator"
                          onMouseDown={(e) => handleTimeDrag(e)}
                        />
                        <div
                          className="circle"
                          onMouseDown={(e) => handleTimeDrag(e)}
                        />
                      </div>
                      <div className="parent-time">
                        <p>{formatDuration(currentTime)}</p>
                        <p>{formatDuration(duration)}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`sectione-threes ${simpleMode ? "simple" : ""}`}
                  >
                    {!simpleMode && (
                      <div className="four">
                        <p>
                          {isWebinar
                            ? `Berikan penilaian untuk materi`
                            : `Puas dengan jawaban narasumber?`}
                        </p>
                        <div className="rate">
                          {!loads ? (
                            [1, 2, 3, 4, 5].map((item, index) => (
                              <img
                                onClick={() => {
                                  if (canAdd) {
                                    setRate(item);
                                    sendFeedback();
                                    setOpenRate(true);
                                    return;
                                  }
                                  alert("Anda Sudah Memberikan Nilai!");
                                }}
                                src={rate >= item ? StarOn : StarOff}
                                alt={item}
                                key={index}
                              />
                            ))
                          ) : (
                            <p>Loading...</p>
                          )}
                        </div>
                      </div>
                    )}
                    {simpleMode && (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setAudioId(null);
                        }}
                      >
                        <img src={"/assets/images/uil_times.svg"} />
                      </div>
                    )}
                  </div>
                  <img
                    onClick={() => setAudioId(null)}
                    src={Close}
                    alt="close"
                    className="close-im"
                  />
                  <img
                    onClick={() => {
                      const audios = document.getElementById("audio");

                      if (!isPlay) {
                        audios.play();
                        setIsPlay(true);
                        const itemlog = {
                          memberId: token,
                          audioId: audioId,
                          startTime: null,
                          endTime: null,
                        };
                        writeLogIntoStorage("play", itemlog);
                        return;
                      }
                      setIsPlay(false);
                      audios.pause();
                      writeLogIntoStorage("pause", null);
                    }}
                    src={isPlay ? Pause : Play}
                    alt="play"
                    className="play-mobiles"
                  />
                </div>
                <div className="parent-indicator">
                  <div
                    style={{ width: `${curPercentage}%` }}
                    className="indicator"
                  />
                </div>
              </>
            )}
          </section>
        </div>
      ) : (
        <div
          className="audios-event"
          style={isProgramsAndDesktopModeStyles.container}
        >
          <section style={isProgramsAndDesktopModeStyles.section}>
            <div className="head">
              {!isPrograms ? (
                <>
                  <img
                    onClick={() => setIsOpen(!isOpen)}
                    src={Down}
                    alt="down"
                  />
                </>
              ) : (
                <div className="minutesec">
                  <img src="/assets/programs/clock.svg" alt="clockicon" />
                  <p>
                    Hanya{" "}
                    {duration < 60
                      ? `${Math.round(duration)} Detik`
                      : `${Math.floor(duration / 60)} Menit`}{" "}
                  </p>
                </div>
              )}
              <img onClick={() => setAudioId(null)} src={Close} alt="close" />
            </div>
            <div className="content">
              <div className="one">
                <div>
                  <h5>{titleAudio ? titleAudio : audio.titles}</h5>
                  {audio.speakers[0]?.name && <p>{audio.speakers[0]?.name}</p>}
                </div>
                <img
                  onClick={() => {
                    const audios = document.getElementById("audio");

                    if (!isPlay) {
                      audios.play();
                      setIsPlay(true);
                      const itemlog = {
                        memberId: token,
                        audioId: audioId,
                        startTime: null,
                        endTime: null,
                      };
                      writeLogIntoStorage("play", itemlog);
                      return;
                    }
                    setIsPlay(false);
                    audios.pause();
                    writeLogIntoStorage("pause", null);
                  }}
                  src={isPlay ? Pause : Play}
                  alt="play"
                />
              </div>
              <div className="two">
                <div
                  onMouseDown={(e) => handleTimeDrag(e)}
                  className="parent-indicator audio-play"
                >
                  <div
                    style={{ width: `${curPercentage}%` }}
                    className="indicator"
                    onMouseDown={(e) => handleTimeDrag(e)}
                  />
                  <div
                    className="circle"
                    onMouseDown={(e) => handleTimeDrag(e)}
                  />
                </div>
                <div className="parent-time">
                  <p>{formatDuration(currentTime)}</p>
                  <p>{formatDuration(duration)}</p>
                </div>
              </div>
              {!isPrograms && (
                <>
                  <div className="three">
                    <div>
                      <img src={Clock} alt="clock" />
                      <p>{Math.floor(duration / 60)} Menit</p>
                    </div>
                    <div>
                      <img
                        src={currentRate ? Rated : audio?.rate ? Rated : Unrate}
                        alt="clock"
                      />
                      <p>
                        {currentRate
                          ? currentRate
                          : audio?.rate
                          ? audio?.rate
                          : "Belum Ada"}
                        {currentRate || audio?.rate ? "/5" : ""}
                      </p>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          `https://api.whatsapp.com/send?text=${message}`,
                          "_blank"
                        );
                      }}
                    >
                      <img src={Wa} alt="clock" />
                      <p>Bagikan</p>
                    </div>
                  </div>
                  {!loads ? (
                    <>
                      <div className="four">
                        <p>
                          {isWebinar
                            ? `Berikan penilaian untuk materi`
                            : `Puas dengan jawaban narasumber?`}
                        </p>
                        <div className="rate">
                          {[1, 2, 3, 4, 5].map((item, index) => (
                            <img
                              onClick={() => {
                                if (canAdd) {
                                  setRate(item);
                                  setOpenRate(true);
                                  return;
                                }
                                alert("Anda Sudah Memberikan Nilai!");
                              }}
                              src={rate >= item ? StarOn : StarOff}
                              alt={item}
                              key={index}
                            />
                          ))}
                        </div>
                      </div>
                      {rate !== 0 && (
                        <div className="five">
                          <p>Isi alasan kamu memberikan nilai tersebut ya!</p>
                          <textarea
                            onChange={(e) => setReason(e.target.value)}
                            value={reason}
                            placeholder="Tulis alasannya di sini"
                          />
                          <button
                            disabled={!reason || reason === ""}
                            style={{
                              background:
                                !reason || reason === "" ? "#999" : "#7d61d6",
                            }}
                            onClick={sendFeedback}
                          >
                            Kirim Ulasan
                          </button>
                        </div>
                      )}
                    </>
                  ) : (
                    <p>Loading...</p>
                  )}
                </>
              )}
            </div>
          </section>
        </div>
      )}
      <div className="popup-dekstops">
        {openRate && (
          <ReasonBox
            rate={rate}
            changeText={(e) => setReason(e.target.value)}
            close={() => setOpenRate(false)}
            text={reason}
            send={sendFeedbackBox}
            isWebinar={isWebinar}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default PopupAudioEvent;
