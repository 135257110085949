import React from "react";
import moment from "moment";
import arrowDown from "../../../images/arrow-down.svg";
import "./DetailInvoice.css";
import {
  DetailSection,
  PaymentFixedButton,
  PaymentInfo,
  SectionContent,
} from "./DetailSection";
import { PaymentInstructions } from "./PaymentInstructions/PaymentInstructions";

export const DetailInvoice = (props) => {
  const { transaction } = props;
  const isVirtualAccount = ['gopay', 'shopeepay'].indexOf(transaction?.paymentMethod) > -1 ? false : true;
  const bankName = {
    "mandiri": "VA Bank Mandiri",
    "bca": "VA Bank BCA",
    "bri": "VA Bank BRI",
    "permata": "VA Bank Permata",
    "bni": "VA Bank BNI",
    "gopay": "Gopay",
    "shopeepay": "Shopeepay",
  }
  return (
    <div
      id="detail_invoice"
      style={{
        marginBottom: "100px",
        background: "#FFF"
      }}
    >
      <PaymentInfo />
      <DetailSection title="Detail Tagihan">
        <SectionContent
          label="Biaya Program"
          value={`Rp.${(transaction?.total * 1)?.toLocaleString("id-ID")},-`}
          valueCopied={transaction?.total}
          btnLabel="Salin"
        />
        <hr />
        {isVirtualAccount && (
          <>
            <SectionContent
              label="Nomor Virtual Account"
              value={transaction?.vaNumber.replace(" 70012", "")}
              valueCopied={transaction?.vaNumber.replace(" 70012", "")}
              btnLabel="Salin"
            />
            <hr />
          </>
        )}
        <SectionContent
          label="Batas Akhir Pembayaran"
          value={`${moment(transaction?.created).add(1, "days").format("DD MMM YYYY HH:mm:SS")} WIB`}
          btnLabel="24 jam"
          isHour
        />
      </DetailSection>

      <DetailSection title="Lihat Cara Bayar" rightImg={transaction?.paymentMethod}>
        <PaymentInstructions isEWallet={!isVirtualAccount} transaction={transaction} bankName={bankName}/>
      </DetailSection>

      <DetailSection title="Status Pembayaran">
        <div className="payment_status" style={{ padding: 16 }}>
          <p style={{ color: "#F9A33F" }}>Belum Dibayar</p>
          <p 
            style={{ color: "#6C60E0" }}
            onClick={() => {
              window.location.reload(true);
            }}
          >
            Cek Status
          </p>
        </div>
      </DetailSection>
      <DetailSection title="Catatan Pembelian">
        <div className="ps_notes">
        Terima kasih telah mengisi formulir ini. Kami akan segera menghubungi Anda dengan informasi lebih lanjut mengenai Program Fammi Sahabat Sekolah. Jika Anda memiliki pertanyaan lebih lanjut, jangan ragu untuk menghubungi kami di nomor WhatsApp berikut: 0812-2255-4838 (Nadya) atau melalui akun Instagram @fammiforschool.
        </div>
      </DetailSection>
      {
        !isVirtualAccount &&
        <div style={{height: "100px", background: "#FFF"}}>
          &nbsp;
        </div>
      }
      {!isVirtualAccount && (
        <PaymentFixedButton
          label={`Bayar di aplikasi ${bankName[transaction?.paymentMethod]}`}
          onClick={() => {
            window.open(transaction?.vaNumber, "_blank");
          }}
        />
      )}
    </div>
  );
};