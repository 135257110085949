import React from 'react'
import data from '../data/registrasi.json'
import Images from '../ImagesComponent/Images';
import PinInput from "react-pin-input";

class LengkapiProfil extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            index: 1,
            dataLengkapi: data.registrasi ? data.registrasi : [],
            month: data.month ? data.month : [],
            provinsi: data.provinsi ? data.provinsi : [],
            kota: data.kota ? data.kota : [],
            year: data.year ? data.year : [],
            date: data.date ? data.date : [],
            step: data.steps ? data.steps : [],
            message: '',
            theSecond: 60,
            showVerificationForm:false
        }
    }

    decreaseTimer = () => {
        let time = this.state.theSecond;
        if (time > -1) {
            setTimeout( () => {
                time -= 1;
                this.setState({
                    theSecond: time
                });

                this.decreaseTimer();
            }, 1000);
        }

    }

    handleOTP = () => {
        let url = process.env.REACT_APP_FETCH_URL+'/mc4zntyzodmyoda4odm0odmwmw';
        let postedBody = JSON.stringify({
            phone: this.state.phone,
            name: this.props.profile.name,
            email: this.props.profile.email,
        });
                fetch(url,{
                    method : 'POST',
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    body: postedBody
                })
                .then(res => res.text())
                .then(
                    (result) => {
                        url = process.env.REACT_APP_FETCH_URL+'/sendotp';
                        const postedBodyOtp = JSON.stringify({
                            phone: this.state.phone
                        });

                        this.setState({
                            theSecond: 60
                        });
                        this.decreaseTimer();

                                fetch(url,{
                                    method : 'POST',
                                    headers:{
                                        'Content-Type': 'application/json'
                                    },
                                    body: postedBodyOtp
                                })
                                .then(res => res.text())
                                .then(
                                    (result) => {
                                        if (result!=="false") {
                                            this.setState({
                                                otpSent: true,
                                                otpMessage: result,
                                            });
                                        }
                                    },
                                    (error) => {
                                        console.log(error)
                                    }
                                );
                    },
                    (error) => {
                        console.log(error)
                    }
                );
    }


    saveTheName = () => {
        const lengkapiVisible = this.state.lengkapiVisible;
        this.setState({
            lengkapiVisible: !lengkapiVisible
        });
    }

    handlePhone = (e) => {
        this.setState({
            phone : e.target.value
        });
    }

    setOtp = (e) => {
        this.setState({
            otp : e
        });
    }

    checkOtp = () => {
        const url = process.env.REACT_APP_FETCH_URL+'/checkotp';
        const postedBody = JSON.stringify({
            otpCode: this.state.otp
        });

                fetch(url,{
                    method : 'POST',
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    body: postedBody
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result && result.isAuthenticate) {
                            this.handleStep('next');
                        } else {
                            this.setState({
                                'message' : 'OTP tidak sesuai'
                            })
                        }
                    },
                    (error) => {
                        console.log(error)
                    }
                );
    }

    componentDidMount() {
        this.getData();
    }

    handleStep = (type) => {
        const { index, dataLengkapi, phone ,showVerificationForm } = this.state;

        if (dataLengkapi[index].style == 'notelp no-wa' && phone && phone.length >8 && !showVerificationForm) {
            this.handleOTP();
            this.setState({
                showVerificationForm:true
            })
            return;
        }

        this.setState({
            showVerificationForm: false
        });

        if (type === "prev") {
            if (index > 1) return this.setState({ index: (index - 1) })
        } else {
            if (dataLengkapi.length !== (index + 1)) return this.setState({ index: (index + 1) })
            else return this.props.onClose()
        }
    }

    getData() {
        let currentYear = new Date().getFullYear()
        let date = Object.assign([], this.state.date)
        let year = Object.assign([], this.state.year)
        for (let i = 1; i < 31; i++) date.push(i)
        for (let y = 1920; y <= currentYear; y++) year.push(y)
        this.setState({ date, year })
    }

    renderTTL() {
        const { date, month, year } = this.state
        return (
            <div className="dateselect">
                <p>
                <div className="arrow-select">
                    <select name="tanggal">
                        {date.map(item => {
                            return (<option value={item}>{item}</option>)
                        })}
                    </select>
                </div>
                </p>
                <p>
                <div className="arrow-select">
                <select name="bulan">
                    {month.map(item => {
                        return (<option value={item}>{item}</option>)
                    })}
                </select>
                </div>
                </p>
                <p>
                <div className="arrow-select">
                    <select name="tahun">
                        {year.map(item => {
                            return (<option value={item}>{item}</option>)
                        })}
                    </select>
                </div>
                </p>
            </div>
        )
    }

    renderKota() {
        const { kota, provinsi } = this.state
        return (
            <div className="dateselect kota">
                <p>
                <div className="arrow-select">
                <select name="provinsi">
                    {provinsi.map(item => {
                        return (<option value={item}>{item}</option>)
                    })}
                </select>
                </div></p>
                <p>
                <div className="arrow-select">
                <select name="kota">
                    {kota.map(item => {
                        return (<option value={item}>{item}</option>)
                    })}
                </select>
                </div></p>
            </div>
        )
    }

    render() {
        const { dataLengkapi, index, step, otpSent, otpMessage, theSecond,  showVerificationForm } = this.state

        return (
            <div className="container-fluid">
                <div id="formlogin">
                    <div className="daftar-page">
                    <form id="myForm" action="">
                        <div id="signup-stepnav">
                            {step.map(item => {
                                return (
                                    <span className={(index + 1) === item.label ? "step active" : "step"} >{item.label}</span>
                                )
                            })}
                        </div>
                        <div className="lengkapi-form" id="form-utama">
                            {
                                showVerificationForm == false &&
                                <h4>Yuk Lengkapi Profile Kamu</h4>
                            }
                            {
                                        showVerificationForm &&
                                        <div className="wrap-login">
                                            <Images src="images/verified.svg"/>
                                            <h4 className="text-center">Masukkan Kode Verifikasi</h4>
                                            <p className="otp-style">
                                                Kode verifikasi telah dikirimkan melalui {otpMessage}
                                            </p>
                                            <h5>
                                                Kode verifikasi
                                            </h5>
                                            <div style={{position:'relative', margin:'24px auto 50px auto', textAlign:'center'}}>
                                                <PinInput length={6} type="numeric" inputStyle={{width:'31px', height:'44px', marginRight:'8px', padding:'10px', background:'#EAEAEA', borderRadius:'6px', border:'none'}} onComplete={(value, index) => this.setOtp(value) } />
                                            </div>
                                            <button type="button" className="btn btn-default btn-lg btn-block" style={{ backgroundColor: "#7D61D6", color: "#fff", borderRadius: "12px",marginLeft:'0px' }} onClick={this.checkOtp}>Verifikasi</button>
                                            {
                                                theSecond > -1 &&
                                                <p className="counting text-center" style={{marginTop:'24px'}}>
                                                    Mohon menunggu <b>{theSecond} detik</b> untuk mengirim ulang
                                                </p>
                                            }

                                                <p className="counting text-center" style={{marginTop:'24px', display: (theSecond < 0) ? 'block': 'none' }}>
                                                    Tidak menerima kode verifikasi?<br/>
                                                    <a href="#" style={{color:'#7D61D6', cursor:'pointer', fontFamily:'Rubik', fontWeight:'500'}} onClick={ () => this.handleOTP() }>Kirim Ulang</a>
                                                </p>
                                        </div>
                            }
                            {
                                !showVerificationForm &&
                                <div className={dataLengkapi[index].style}>
                                    <Images alt="" src={dataLengkapi[index].image} style={{margin:'50px auto'}}/>
                                    <div style={{fontFamily:'Rubik', fontSize:'14px', fontWeight:'500'}}>{dataLengkapi[index].label}</div>
                                    <div className="no-country hide-codearea" style={{marginTop:'8px'}}>
                                        {dataLengkapi[index].name && <input placeholder={dataLengkapi[index].placeholder} name="fname" required type="text" />}

                                        {dataLengkapi[index].phone && <>
                                            <div class="arrow-select justflag">
                                                <select name="codearea" className="codearea">
                                                    <option value="ind">+62</option>
                                                    <option value="usa">+44</option>
                                                </select>
                                            </div>
                                            <input placeholder={dataLengkapi[index].placeholder} name="phone" onChange={ (e) => this.handlePhone(e) } required style={{borderRadius:'12px'}} />
                                        </>}

                                        {dataLengkapi[index].ttl && this.renderTTL()}

                                        {dataLengkapi[index].kota && this.renderKota()}
                                        <div className="signup-button-nav">
                                            {index > 0 && <div className={"col-xs-5"}>
                                                <button type="button" className="previous" onClick={() => this.handleStep("prev")}> Sebelumnya </button>
                                            </div>}
                                            <div className={index > 0 ? "col-xs-7" : "col-md-12"}>
                                                <button type="button" className="next" onClick={() => this.handleStep("next")}> {(index + 1) === dataLengkapi.length ? "Selesai" : "Selanjutnya"} </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                            showVerificationForm &&
                                            theSecond > -1 &&
                                                <div style={{marginTop:'24px'}}>
                                                    Mohon menunggu {theSecond} detik untuk mengirim ulang
                                                </div>
                                        }
                                        {
                                            showVerificationForm &&
                                            theSecond < 0 &&
                                            <div style={{marginTop:'24px', display: (theSecond < 0) ? 'block': 'none' }}>
                                                Tidak menerima kode verifikasi?<br/>
                                                <a href="#" style={{color:'#7D61D6', cursor:'pointer'}} onClick={ () => this.handleOTP() }>Kirim Ulang</a>
                                            </div>
                                        }
                            <div className="clearing"></div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default LengkapiProfil