import React from "react";
import { enroll } from "../../services/packages";
import "./DialogEnroll.css";
import moment from "moment";

function DialogEnroll(props) {
  const { open, handleClose, eventData, program, token, limitTime } = props;
  return (
    <>
      {open && (
        <div id="dialog-enroll">
          <div className="box-dialog">
            <div className="heads">
              <img src="/assets/programs/shield.svg" alt="shields" />
              <img
                src="/assets/images/e_close.svg"
                style={{ cursor: "pointer" }}
                onClick={handleClose}
                alt="shields"
              />
            </div>
            <h1>
              {
                !limitTime ? "Oops.. Kamu belum gabung program!" : "Oops.. Materi belum bisa diakses"
              }
            </h1>
            <p>
             {
                !limitTime ? "Klik tombol Gabung di bawah ini untuk mengikuti program!" : `Materi bisa kamu akses mulai tanggal ${moment(limitTime).add("1", "day").format("DD MMM YYYY")}`
              }</p>
          {
            !limitTime &&
            <div className="cta-enrolls">
              <button onClick={async ()=>{
                await enroll({
                    "activityId": new Date().getTime(),
                    "packageId": program.packageId,
                    "memberId": token,
                    "eventId": eventData.eventId
                });

                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }}>
                Gabung Program Sekarang
              </button>
            </div>
          }
          {
            limitTime && 
            <div className="cta-enrolls">
              <button onClick={handleClose}>
                Ok
              </button>
            </div>
          }
          </div>
        </div>
      )}
    </>
  );
}

export default DialogEnroll;
