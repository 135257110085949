import React from "react";
import axios from "axios";
import "moment/locale/id";
import megaphone from "../images/u_megaphone.svg";
import coverAds from "../images/cover-ads.png";
import coverAdsMini from "../images/cover-ads-2.png";

export default function CardAds(props) {
  const { data, eventCode, mini, getAdvertisingLog } = props;

  if (!data?.adsType) {
    return <></>;
  }

  return data?.adsType === "mobile-floating" ? (
    <div
      style={{
        bottom: 0,
        position: "fixed",
        left: "-16px",
        width: "104%",
        height: "50px",
        cursor: "pointer",
      }}
      onClick={() => {
        // getAdvertisingLog('left-sidebar');
        axios.post(process.env.REACT_APP_FETCH_URL + "/advertising_log", {
          adsId: data?.adsId,
          memberId: localStorage.getItem("token")
            ? localStorage.getItem("token")
            : null,
          type: data?.adsType,
          eventType: "clicked"
        });
        window.open(`${data?.cta}`, "_blank");
      }}
    >
      <img
        alt={""}
        style={{ width: "100%", height: "50px" }}
        src={
          "https://fammi.ly/uploads/ads/images/" +
          data?.cover +
          "?v=" +
          Math.random()
        }
      />
    </div>
  ) : (
    <div className="card-events">
      <div
        onClick={() => {
          // getAdvertisingLog('left-sidebar');
          axios.post(process.env.REACT_APP_FETCH_URL + "/advertising_log", {
            adsId: data?.adsId,
            memberId: localStorage.getItem("token")
              ? localStorage.getItem("token")
              : null,
            type: data?.adsType,
            eventType: "clicked"
          });
          window.open(`${data?.cta}`, "_blank");
        }}
        style={{
          width: "100%",
          height:
            data?.adsType === "left-sidebar"
              ? "98.06px"
              : data?.adsType === "right-sidebar"
              ? "154.9px"
              : "unset",
          overflow: "hidden",
          borderRadius: "12px 12px 0 0",
          cursor: "pointer",
        }}
      >
        {/* <div> */}
        {mini && (
          <img
            alt={""}
            style={{ width: "100%", height: "auto" }}
            src={
              data?.cover
                ? "https://fammi.ly/uploads/ads/images/" +
                  data?.cover +
                  "?v=" +
                  Math.random()
                : coverAdsMini
            }
          />
        )}
        {!mini && (
          <img
            alt={""}
            style={{ width: "100%", height: "auto" }}
            src={
              "https://fammi.ly/uploads/ads/images/" +
              data?.cover +
              "?v=" +
              Math.random()
            }
          />
        )}
      </div>
      {!mini && data?.descriptions && (
        <div
          className="schedule"
          style={{cursor:"pointer"}}
          onClick={() => {
            // getAdvertisingLog('left-sidebar');
            axios.post(process.env.REACT_APP_FETCH_URL + "/advertising_log", {
              adsId: data?.adsId,
              memberId: localStorage.getItem("token")
                ? localStorage.getItem("token")
                : null,
              type: data?.adsType,
              eventType: "clicked"
            });
            window.open(`${data?.cta}`, "_blank");
          }}
        >
          <p style={{ fontSize: 14 }}>{data?.descriptions}</p>
        </div>
      )}
      <div className="status">
        <div>
          <img src={megaphone} alt="schedule" />
          <p style={{ color: "#999999" }}>{data?.title}</p>
        </div>
        <div
          onClick={() => {
            // getAdvertisingLog('left-sidebar');
            axios.post(process.env.REACT_APP_FETCH_URL + "/advertising_log", {
              adsId: data?.adsId,
              memberId: localStorage.getItem("token")
                ? localStorage.getItem("token")
                : null,
              type: data?.adsType,
              eventType: "clicked"
            });
            window.open(`${data?.cta}`, "_blank");
          }}
          style={{ justifyContent: "flex-end", cursor: "pointer" }}
        >
          <p style={{ color: "#6C60E0", fontWeight: "bold" }}>Selengkapnya</p>
        </div>
      </div>
    </div>
  );
}
