import axios from "axios";

export const getPrograms = (eventId, token) => {
  return axios.get(
      process.env.REACT_APP_FETCH_URL + "/packages/" + eventId + "/" +token
  );
} 

export const getProgram = (packageId) => {
  return axios.get(
      process.env.REACT_APP_FETCH_URL + "/package/" + packageId
  );
}

export const getProgramDetailSummary = (packageId, token) => {
  return axios.get(
      process.env.REACT_APP_FETCH_URL + "/package/" + packageId + "/" + token
  );
}

export const getProgramDetail = (packageId, token) => {
  return axios.get(
    process.env.REACT_APP_FETCH_URL + "/package_detail/" + packageId + "/" + token
  )
}

export const getMaterialDetail = (packageId, token, detailId) => {
  return axios.get(
    process.env.REACT_APP_FETCH_URL + "/package_detail_materials/" + packageId + "/" + token + "/" +detailId
  )
}

export const enroll = (p) => {
  return axios.post(
    process.env.REACT_APP_FETCH_URL + "/enroll", 
    p
  )
}

export const updateState = (listId) => {
  return axios.post(
    process.env.REACT_APP_FETCH_URL + "/a203b3e624b7cc1affa9d3bb918b18e4", 
    {
      memberId: localStorage.getItem("token"),
      listId: listId,
      state: "done"
    }
  )
}