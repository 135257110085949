import React, { useMemo, useState } from "react";
import arrowDown from "../../../../images/arrow-down.svg";
import arrowUp from "../../../../images/arrow-up.svg";
import bankInstruction from "./bank-instruction.json";
import eWalletInstruction from "./e-wallet-instruction.json";
import "./PaymentInstructions.css";

export const PaymentInstructions = ({ isEWallet, transaction, bankName }) => {
  const [openInstruction, setOpenInstruction] = useState(false);
  const [instruction, setInstruction] = useState([""]);

  const data = useMemo(() => {
    if (isEWallet) {
      return eWalletInstruction.data.filter((d) => d.id === transaction?.paymentMethod);
    }
    return bankInstruction.data.filter((d) => {
      return  d.id === transaction?.paymentMethod
    });
  }, [isEWallet, transaction]);

  return (
    <div id="payment_instructions">
      <div
        className="pi_head"
        style={{
          borderBottom: openInstruction ? "1px solid #EAEAEA" : "none",
        }}
      >
        <p>{bankName?.[transaction?.paymentMethod]}</p>
        <img
          src={openInstruction ? arrowUp : arrowDown}
          alt="down"
          onClick={() => setOpenInstruction(!openInstruction)}
        />
      </div>

      {openInstruction &&
        data.length > 0 &&
        (isEWallet ? (
          <div className="pi_instruction" style={{ padding: "16px" }}>
            {data[0]?.instructions.map((i, idx) => (
              <div key={idx}>
                <p>{idx + 1 + "."}&nbsp;</p>
                <p>{i}</p>
              </div>
            ))}
          </div>
        ) : (
          data[0]?.methods.map((d) => {
            const lastItem =
              data[0].methods[data[0].methods.length - 1].id === d.id;
            return (
              <div
                key={d.id}
                style={{
                  borderBottom: lastItem ? "" : "1px solid #EAEAEA",
                }}
              >
                <div className="pi_content">
                  <p>{d.title}</p>
                  <button
                    onClick={() => {
                      const idx = instruction.findIndex((i) => i === d.id);

                      if (idx > -1) {
                        const f = instruction.filter((i) => i !== d.id);
                        setInstruction(f);
                      } else {
                        setInstruction([...instruction, d.id]);
                      }
                    }}
                  >
                    <p>{instruction.includes(d.id) ? "Tutup" : "Lihat"}</p>
                  </button>
                </div>
                {instruction.includes(d.id) && (
                  <div className="pi_instruction">
                    {d.instructions.map((i, idx) => (
                      <div key={idx}>
                        <p>{idx + 1 + "."}&nbsp;</p>
                        <p>{i}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })
        ))}
    </div>
  );
};
