import moment from "moment";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { withRouter } from "react-router-dom";
import CallCenter from "./CallCenter/CallCenter";
import { ProfileMenuList } from "./ProfileMenu/ProfileMenuList";
import { useTranslation } from "react-i18next";

function MobileHeader(props) {
  const {
    token,
    names,
    memberType,
    tenants,
    activePackages,
    eventCode,
    setOpenLogin,
    email,
    forceDisplayed,
    eventData,
    setPremiumDialog,
    expired,
    setExpired
  } = props;
  const [openMenu, setOpenMenu] = React.useState(false);
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const { t } = useTranslation();
  const handleClickMyRaport = () => {
    const isFinished = moment(eventData.endDate).isBefore();
    // if (expired) return setPremiumDialog(true);

    window.open(
      `https://report.fammi.ly/digital-raport/${token}/${tenants?.tenantId}/${eventData?.eventId}`,
      "_blank"
    );
  };
  return (
    <div className={`parent-header-e ${forceDisplayed ? "forced" : ""}`}>
      <div
        className="event-header"
        style={{ maxWidth: forceDisplayed && isDesktop ? "360px" : "448px" }}
      >
        <div>
          <img
            src="/assets/images/e_menu_bar.svg"
            onClick={() => setOpenMenu(!openMenu)}
            className="menu-mob"
            alt="menu-mob"
          />
          <img
            src="https://fammi.ly/images/fammi-logo.svg"
            className="event-logo"
            alt="logo"
          />
        </div>
      </div>
      {openMenu && (
        <div className="menu-mobiles">
          <div className="headers">
            <img
              src="https://fammi.ly/images/fammi-logo.svg"
              alt="logo"
              style={{ height: 32 }}
            />
            <img
              src="/assets/images/newclose.svg"
              className="menu-mob"
              alt="menu-mob"
              onClick={() => setOpenMenu(!openMenu)}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "16px",
              padding: "16px",
            }}
          >
            {token && (
              <ProfileMenuList
                eventCode={eventCode}
                eventData={eventData}
                name={names}
                email={email}
                setExpired={setExpired}
                memberType={memberType}
                expired={expired}
                setPremiumDialog={setPremiumDialog}
                activePackages={activePackages}
                isSidebarMode
              />
            )}
            <CallCenter />
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(MobileHeader);
