import firebase from "firebase";
import moment from "moment";
import React, { Suspense } from "react";
import TagManager from "react-gtm-module";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ArticleProgram from "./Container/ArticleProgram";
import AudioMaterial from "./Container/AudioMaterial";
import Home from "./Container/Home";
import Loading from "./Container/Loading";
import LoginPartner from "./Container/LoginPartner";
import Payment from "./Container/Payment";
import WebinarMalaysia from "./Container/WebinarMalaysia";
import WebinarMalaysiaSuccess from "./Container/WebinarMalaysiaSuccess";
import WebinarProfileGuruSuccess from "./Container/WebinarProfileGuruSuccess";
import WebinarSiapMasukSDPayment from "./Container/WebinarSiapMasukSDPayment";
import WebinarFammiSahabatSekolahPayment from "./Container/WebinarFammiSahabatSekolahPayment";

const tagManagerArgs = {
  gtmId: "GTM-W77QXPW",
};

TagManager.initialize(tagManagerArgs);

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER,
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }

  sendLog = () => {
    let audioLogs = JSON.parse(localStorage.getItem("logs"));
    if (audioLogs === null) {
      return;
    }

    audioLogs = audioLogs.filter((log) => {
      return typeof log.endTime !== "undefined" && log.endTime !== null;
    });

    if (audioLogs.length < 1) {
      return;
    }

    audioLogs.map((log) => {
      log["status"] = "done";
    });

    fetch(process.env.REACT_APP_FETCH_URL + "/audio_logs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(audioLogs),
    })
      .then((res) => res.text())
      .then((res) => {
        if (res === "true") {
          let newAudioLogs = [];
          newAudioLogs = audioLogs.filter((log) => {
            return typeof log.status === "undefined" || log.status !== "done";
          });
          localStorage.setItem("logs", JSON.stringify(newAudioLogs));
        }
      });
  };

  componentDidMount() {
    this.setState({
      loading: false,
    });
    if (
      window.location.pathname !== "/subscription" ||
      window.location.pathname !== "/subscriptions"
    ) {
      localStorage.removeItem("affiliateCode");
    }
    const filterPath = window.location.pathname.match("/am");
    if (filterPath && filterPath?.length !== 0) {
      setTimeout(() => {
        this.setState({
          loading: true,
        });
      }, 500);
      const getCodeAff = window.location.pathname.replace("/am/", "");
      if (getCodeAff) {
        localStorage.setItem("affiliateCode", getCodeAff);
        window.location.href = "/subscription";
      }
    }

    window.addEventListener("beforeunload", () => {
      if (localStorage.getItem("logs") === null) {
        return;
      }

      let currentLogs = JSON.parse(localStorage.getItem("logs"));

      currentLogs.map((log) => {
        if (log.endTime === null) {
          log.endTime = moment().format("YYYY-MM-DD HH:mm:ss");
        }
      });

      localStorage.setItem("logs", JSON.stringify(currentLogs));

      this.sendLog();
    });

    setInterval(() => {
      this.sendLog();
    }, 20000);
  }

  render() {
    let { loading } = this.state;

    const InteractiveBooksViewer = React.lazy(() =>
      import("./Container/InteractiveBooksViewer")
    );
    const KelasDetail = React.lazy(() => import("./Container/KelasDetail"));
    const Kelas = React.lazy(() => import("./Container/Kelas"));
    const Mitra = React.lazy(() => import("./Container/Mitra"));
    const MitraDetail = React.lazy(() => import("./Container/MitraDetail"));
    const MitraCourses = React.lazy(() => import("./Container/MitraCourses"));
    const KelasUser = React.lazy(() => import("./Container/KelasUser"));
    const KelasUserSearch = React.lazy(() =>
      import("./Container/KelasUserSearch")
    );
    const TransaksiList = React.lazy(() => import("./Container/TransaksiList"));
    const KeranjangList = React.lazy(() => import("./Container/KeranjangList"));
    const MasukKelas = React.lazy(() => import("./Container/MasukKelas"));
    const Affiliate = React.lazy(() => import("./Container/Affiliate"));
    const Users = React.lazy(() => import("./Container/Users"));
    const KelasDetailAffiliate = React.lazy(() =>
      import("./Container/KelasDetailAffiliate")
    );
    const InteractiveBooks = React.lazy(() =>
      import("./Container/InteractiveBooks")
    );
    const AssignAffiliate = React.lazy(() =>
      import("./Container/AssignAffiliate")
    );
    const Books = React.lazy(() => import("./Container/Books"));
    const BooksDetail = React.lazy(() => import("./Container/BooksDetail"));
    const BookUser = React.lazy(() => import("./Container/BookUser"));
    const RegisterAffiliate = React.lazy(() =>
      import("./Form/RegisterAffiliate")
    );
    const AffiliateDetail = React.lazy(() =>
      import("./Container/AffiliateDetail")
    );
    const BooksPreview = React.lazy(() => import("./Container/BooksPreview"));
    const Konsultasi = React.lazy(() => import("./Container/Konsultasi"));
    const KonsultasiDetail = React.lazy(() =>
      import("./Container/KonsultasiDetail")
    );
    const Login = React.lazy(() => import("./Container/Login"));
    const Register = React.lazy(() => import("./Container/Register"));
    const LoginOriginalSeries = React.lazy(() =>
      import("./Container/LoginOriginalSeries")
    );
    const RegisterOriginalSeries = React.lazy(() =>
      import("./Container/RegisterOriginalSeries")
    );
    const KerjaSama = React.lazy(() => import("./Container/KerjaSama"));
    const Profile = React.lazy(() => import("./Container/Profil"));
    const MasukKelasDetail = React.lazy(() =>
      import("./Container/MasukKelasDetail")
    );
    const MetodePembayaran = React.lazy(() =>
      import("./Container/MetodePembayaran")
    );
    const TransaksiDetail = React.lazy(() =>
      import("./Container/TransaksiDetail")
    );
    const Tentang = React.lazy(() => import("./Container/Tentang"));
    const Ketentuan = React.lazy(() => import("./Container/Ketentuan"));
    const Kebijakan = React.lazy(() => import("./Container/Kebijakan"));
    const RegisterMitra = React.lazy(() => import("./Form/RegisterMitra"));
    const RegisterNarasumber = React.lazy(() =>
      import("./Form/RegisterNarasumber")
    );
    const MasukKelasDetailMateri = React.lazy(() =>
      import("./Container/MasukKelasDetailMateri")
    );
    const Panduan = React.lazy(() => import("./Container/Panduan"));
    const Articles = React.lazy(() => import("./Container/Articles"));
    const ArticleForm = React.lazy(() => import("./Articles/ArticleForm"));
    const AffiliatePage = React.lazy(() => import("./Container/AffiliatePage"));
    const SubscriptionPage = React.lazy(() =>
      import("./Container/Subscription")
    );
    const ReviewPembelian = React.lazy(() =>
      import("./Container/ReviewPembelian")
    );
    const ForgotPassword = React.lazy(() =>
      import("./Container/ForgotPassword")
    );
    const RegisterCompany = React.lazy(() =>
      import("./Container/RegisterCompany")
    );
    const DashboardReport = React.lazy(() =>
      import("./Container/DashboardReport")
    );
    const Episode = React.lazy(() => import("./Container/Episode"));
    const ProfilNarasumber = React.lazy(() =>
      import("./Container/ProfilNarasumber")
    );

    const DashboardSpeaker = React.lazy(() =>
      import("./Container/DashboardSpeaker")
    );
    const NewSearchPage = React.lazy(() => import("./Container/NewSearchPage"));
    const NewEpisodes = React.lazy(() => import("./Container/NewEpisode"));
    const SpeakerPage = React.lazy(() => import("./Container/SpeakerPage"));
    const DetailQna = React.lazy(() => import("./Container/DetailQna"));
    const CheckUrl = React.lazy(() => import("./Container/CheckUrl"));
    const WebinarHome = React.lazy(() => import("./Container/WebinarHome"));
    const WebinarSchedule = React.lazy(() =>
      import("./Container/WebinarSchedule")
    );
    const WebinarQna = React.lazy(() => import("./Container/WebinarQna"));
    const WebinarMyQna = React.lazy(() => import("./Container/WebinarMyQna"));
    const WebinarCertificate = React.lazy(() =>
      import("./Container/WebinarCertificate")
    );
    const WebinarRecord = React.lazy(() => import("./Container/WebinarRecord"));
    const WebinarMaterials = React.lazy(() =>
      import("./Container/WebinarMaterials")
    );
    const WebinarArticle = React.lazy(() =>
      import("./Container/WebinarArticle")
    );
    const DetailWebinarArticle = React.lazy(() =>
      import("./Container/DetailWebinarArticle")
    );
    const Certificate = React.lazy(() => import("./Container/Certificate"));
    const EventTestimonials = React.lazy(() =>
      import("./Container/EventTestimonials")
    );
    const DetailSchedule = React.lazy(() =>
      import("./Container/DetailSchedule")
    );
    const RegisterSchedule = React.lazy(() =>
      import("./Container/RegisterSchedule")
    );
    const LoginSchedule = React.lazy(() => import("./Container/LoginSchedule"));
    const WebinarQuiz = React.lazy(() => import("./Container/WebinarQuiz"));
    const DetailQuiz = React.lazy(() => import("./Container/DetailQuiz"));
    const ResultQuiz = React.lazy(() => import("./Container/ResultQuiz"));
    const DetailResultQuiz = React.lazy(() =>
      import("./Container/DetailResultQuiz")
    );
    const WebinarPrograms = React.lazy(() =>
      import("./Container/WebinarPrograms")
    );
    const DetailPrograms = React.lazy(() =>
      import("./Container/DetailPrograms")
    );
    const LandingPage = React.lazy(() => import("./Container/LandingPage"));
    const InspirativeExperience = React.lazy(() =>
      import("./Container/InspirativeExperience")
    );
    const UserRanking = React.lazy(() => import("./Container/UserRanking"));
    const WebinarProfile = React.lazy(() =>
      import("./Container/WebinarProfile")
    );
    const WebinarProfilePaud = React.lazy(() =>
      import("./Container/WebinarProfilePaud")
    );
    const WebinarProfilePaudPayment = React.lazy(() =>
      import("./Container/WebinarProfilePaudPayment")
    );
    const WebinarProfilePaudSuccess = React.lazy(() =>
      import("./Container/WebinarProfilePaudSuccess")
    );
    const NewHomePage = React.lazy(() => import("./Container/NewHomePage"));

    const WebinarSiapMasukSD = React.lazy(() =>
      import("./Container/WebinarSiapMasukSD")
    );
    const WebinarScreening = React.lazy(() =>
      import("./Container/WebinarScreening")
    );
    const WebinarProfileGuruTerampil = React.lazy(() =>
      import("./Container/WebinarProfileGuruTerampil")
    );
    const ScreeningTestResult = React.lazy(() =>
      import("./Container/ScreeningTestResult")
    );
    const DetailVideoMaterial = React.lazy(() =>
      import("./Container/DetailVideoMaterial")
    );
    const TanyaAhliHomePage = React.lazy(() =>
      import("./Container/TanyaAhliHomePage")
    );
    const PaymentPackage = React.lazy(() =>
      import("./Container/PaymentPackage")
    );

    const VideoMaterial = React.lazy(() => import("./Container/VideoMaterial"));
    const ModulMaterial = React.lazy(() => import("./Container/ModulMaterial"));

    const WebinarSurveyPijar = React.lazy(() =>
      import("./Container/WebinarSurveyPijar")
    );

    const WebinarSurveyPijarSuccess = React.lazy(() =>
      import("./Container/WebinarSurveyPijarSuccess")
    );
    const WebinarSpeakerSchedule = React.lazy(() =>
      import("./Container/WebinarSpeakerSchedule")
    );
    const WebinarDetailQna = React.lazy(() =>
      import("./Container/WebinarDetailQna")
    );

    const SurveySingapore = React.lazy(() =>
      import("./Container/SurveySingapore")
    );
    const SurveySingaporeSuccess = React.lazy(() =>
      import("./Container/SurveySingaporeSuccess")
    );
    const WebinarFammiSahabatSekolah = React.lazy(() =>
      import("./Container/WebinarFammiSahabatSekolah")
    );
    const preservedSubdomain =
      process?.env?.REACT_APP_SUBDOMAIN.split(", ") ?? "false";
    const hostName = window?.location?.host?.replace("www.", "").split(".");
    const subDomain = hostName.length > 0 ? hostName[0] : "";

    if (!loading) {
      return (
        <Router>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path="/">
                {preservedSubdomain?.indexOf(subDomain) > -1 ? (
                  <WebinarHome eventCode={subDomain} />
                ) : subDomain.indexOf("about") > -1 ? (
                  <NewHomePage />
                ) : subDomain.indexOf("daftarparentingsekolahjuara") > -1 ? (
                  <WebinarFammiSahabatSekolah />
                ) : subDomain.indexOf("daftarsiapmasuksd") > -1 ? (
                  <WebinarSiapMasukSD />
                ) : subDomain.indexOf("surveyberpijar") > -1 ? (
                  <WebinarSurveyPijar />
                ) : subDomain.indexOf("daftarguruterampil") > -1 ? (
                  <WebinarProfileGuruTerampil />
                ) : subDomain.indexOf("malaysia") > -1 ? (
                  <WebinarMalaysia />
                ) : subDomain.indexOf("singapore") > -1 ? (
                  <SurveySingapore />
                ) : subDomain.indexOf("vietnam") > -1 ? (
                  <SurveySingapore />
                ) : subDomain.indexOf("philippines") > -1 ? (
                  <SurveySingapore />
                ) : subDomain.indexOf("sekolah") > -1 ? (
                  <LandingPage />
                ) : (
                  <Home />
                )}
              </Route>
              <Route path="/a/:id">
                <AssignAffiliate />
              </Route>
              <Route path="/r/:id">
                <CheckUrl />
              </Route>
              <Route path="/masuk-kelas/:id">
                <MasukKelas />
              </Route>
              <Route path="/kelas/:title/:id/:affiliate">
                <KelasDetailAffiliate />
              </Route>
              <Route path="/kelas/:title/:id">
                <KelasDetail />
              </Route>
              <Route path="/kelas">
                <Kelas />
              </Route>
              <Route path="/konsultasi/:title/:id">
                <KonsultasiDetail />
              </Route>
              <Route path="/konsultasi">
                <Konsultasi />
              </Route>
              <Route path="/mitra/content/:id">
                <MitraCourses />
              </Route>
              <Route path="/mitra/:title/:id">
                <MitraDetail />
              </Route>
              <Route path="/mitra">
                <Mitra />
              </Route>
              <Route path="/login-originalseries">
                <LoginOriginalSeries />
              </Route>
              <Route path="/register-originalseries">
                <RegisterOriginalSeries />
              </Route>
              <Route path="/masuk-member">
                <LoginOriginalSeries />
              </Route>
              <Route path="/daftar-member">
                <RegisterOriginalSeries />
              </Route>
              <Route exact path="/login/:id">
                <LoginPartner />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route exact path="/register/:id">
                <RegisterCompany />
              </Route>
              <Route path="/register">
                <Register />
              </Route>
              <Route path="/register-narasumber">
                <RegisterNarasumber />
              </Route>
              <Route path="/register-mitra">
                <RegisterMitra />
              </Route>
              <Route path="/join">
                <KerjaSama />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/kelasuser/search">
                <KelasUserSearch />
              </Route>
              <Route path="/kelasuser">
                <KelasUser />
              </Route>
              <Route path="/keranjang/search">
                <KeranjangList />
              </Route>
              <Route path="/keranjang">
                <KeranjangList />
              </Route>
              <Route path="/transaksi/search">
                <TransaksiList />
              </Route>
              <Route path="/transaksi">
                <TransaksiList />
              </Route>
              <Route path="/load_module/:courseid/:type/:moduleid">
                <MasukKelasDetail />
              </Route>
              <Route path="/materials/:courseid/:type/:moduleid">
                <MasukKelasDetailMateri />
              </Route>
              <Route path="/metode-pembayaran">
                <MetodePembayaran />
              </Route>
              <Route path="/transaksi-detail/:transaksiid">
                <TransaksiDetail />
              </Route>
              <Route path="/tentang">
                <Tentang />
              </Route>
              <Route path="/ketentuan">
                <Ketentuan />
              </Route>
              <Route path="/kebijakan">
                <Kebijakan />
              </Route>
              <Route path="/panduan">
                <Panduan />
              </Route>
              <Route path="/affiliate-page">
                <AffiliatePage />
              </Route>
              <Route path="/affiliate">
                <Affiliate />
              </Route>
              <Route path="/report/:id">
                <Users />
              </Route>
              <Route path="/interactive-books">
                <InteractiveBooks />
              </Route>
              <Route path="/interactive-playbook/:id">
                <InteractiveBooksViewer />
              </Route>
              <Route path="/articles">
                <Articles />
              </Route>
              <Route path="/article-playbook">
                <ArticleForm />
              </Route>
              <Route path="/books-preview/:title/:id">
                <BooksPreview />
              </Route>
              <Route path="/books/:title/:id">
                <BooksDetail />
              </Route>
              <Route path="/books">
                <Books />
              </Route>
              <Route path="/book-user">
                <BookUser />
              </Route>
              <Route path="/register-affiliate">
                <RegisterAffiliate />
              </Route>
              <Route path="/affiliate-detail/:courseid">
                <AffiliateDetail />
              </Route>
              <Route path="/subscription">
                <SubscriptionPage />
              </Route>
              <Route path="/subscriptions">
                <SubscriptionPage />
              </Route>
              <Route path="/order-reviews/:id">
                <ReviewPembelian />
              </Route>
              <Route path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route exact path="/login-report">
                <DashboardReport />
              </Route>
              <Route exact path="/episode/:id">
                <Episode />
              </Route>

              <Route path="/u/:id">
                <SpeakerPage />
              </Route>
              <Route exact path="/playlist/:mode/:id">
                <NewEpisodes />
              </Route>
              <Route exact path="/playlist/:id">
                <NewEpisodes />
              </Route>
              <Route path="/dashboard-speaker/:id">
                <DashboardSpeaker />
              </Route>
              <Route path="/qna/:id">
                <DetailQna />
              </Route>
              <Route path="/tanya/:shortenedUrl">
                <DetailQna />
              </Route>
              <Route exact path="/search">
                <NewSearchPage />
              </Route>
              <Route exact path="/abdi-paud-hiber">
                <WebinarProfilePaud />
              </Route>
              <Route exact path="/abdi-paud-hiber/payment">
                <WebinarProfilePaudPayment />
              </Route>
              <Route exact path="/abdi-paud-hiber/payment/:transaction_id">
                <WebinarProfilePaudPayment />
              </Route>
              <Route exact path="/siap-masuk-sd">
                <WebinarSiapMasukSD />
              </Route>
              <Route exact path="/siap-masuk-sd/payment/:transaction_id">
                <WebinarSiapMasukSDPayment />
              </Route>
              <Route exact path="/abdi-paud-hiber-success">
                <WebinarProfilePaudSuccess />
              </Route>
              <Route exact path="/guru-terampil">
                <WebinarProfileGuruTerampil />
              </Route>
              <Route exact path="/guru-terampil-success">
                <WebinarProfileGuruSuccess />
              </Route>
              <Route exact path="/survey-malaysia">
                <WebinarMalaysia />
              </Route>
              <Route exact path="/survey-malaysia-success">
                <WebinarMalaysiaSuccess />
              </Route>
              <Route exact path="/survey-pijar">
                <WebinarSurveyPijar />
              </Route>
              <Route exact path="/survey-singapore">
                <SurveySingapore />
              </Route>
              <Route exact path="/survey-singapore-success">
                <SurveySingaporeSuccess />
              </Route>
              <Route exact path="/survey-pijar-success">
                <WebinarSurveyPijarSuccess />
              </Route>
              <Route exact path="/fammi-sahabat-sekolah">
                <WebinarFammiSahabatSekolah />
              </Route>
              <Route exact path="/fammi-sahabat-sekolah/payment/:transaction_id">
                <WebinarFammiSahabatSekolahPayment />
              </Route>
              <Route exact path="/e/:event_code/profile">
                <WebinarProfile />
              </Route>
              <Route exact path="/e/:event_code/">
                <WebinarHome />
              </Route>
              <Route exact path="/e/:event_code/schedule">
                <WebinarSchedule />
              </Route>
              <Route exact path="/e/:event_code/speaker-schedule">
                <WebinarSpeakerSchedule />
              </Route>
              <Route exact path="/e/:event_code/schedule/:id">
                <DetailSchedule />
              </Route>
              {/* <Route exact path="/e/:event_code/schedule/:id/register">
                <RegisterSchedule />
              </Route> */}
              <Route
                exact
                path="/e/:event_code/schedule/:id/:join/:registeredMode/:registeredEmail"
              >
                <LoginSchedule />
              </Route>
              <Route exact path="/e/:event_code/schedule/:id/:join">
                <LoginSchedule />
              </Route>
              <Route exact path="/e/:event_code/record">
                <WebinarRecord />
              </Route>
              <Route exact path="/e/:event_code/audio">
                <AudioMaterial />
              </Route>
              <Route exact path="/e/:event_code/qna">
                <WebinarQna hideTitle={true} />
              </Route>
              <Route exact path="/e/:event_code/qna/:id">
                <WebinarDetailQna />
              </Route>
              <Route exact path="/e/:event_code/certificate">
                <WebinarCertificate />
              </Route>
              <Route exact path="/e/:event_code/materials_event">
                <WebinarMaterials />
              </Route>
              <Route exact path="/e/:event_code/quiz">
                <WebinarQuiz />
              </Route>
              <Route
                exact
                path="/e/:event_code/:package_id/inspirative-experience"
              >
                <InspirativeExperience />
              </Route>
              <Route exact path="/e/:event_code/user-ranking/:packageId">
                <UserRanking />
              </Route>
              <Route exact path="/e/:event_code/programs">
                <WebinarPrograms />
              </Route>
              <Route exact path="/e/:event_code/programs/:id">
                <DetailPrograms />
              </Route>
              <Route exact path="/e/:event_code/article/:id/:article_id">
                <ArticleProgram isPanduan={false} />
              </Route>
              <Route
                exact
                path="/e/:event_code/article/:id/:article_id/panduan"
              >
                <ArticleProgram isPanduan={true} />
              </Route>
              <Route exact path="/e/:event_code/read-article/:article_id">
                <ArticleProgram isPanduan={false} />
              </Route>
              <Route exact path="/e/:event_code/quiz/:id">
                <DetailQuiz />
              </Route>
              <Route exact path="/e/:event_code/quiz/:id/activity/:activity_id">
                <DetailQuiz />
              </Route>
              <Route
                exact
                path="/e/:event_code/quiz/:id/activity/:activity_id/:package_id"
              >
                <DetailQuiz source={"activity"} />
              </Route>
              <Route exact path="/e/:event_code/quiz/:id/result">
                <ResultQuiz />
              </Route>
              <Route exact path="/e/:event_code/quiz/:id/result/:ids">
                <DetailResultQuiz />
              </Route>
              <Route exact path="/e/:event_code/screening-result/:id">
                <ScreeningTestResult />
              </Route>
              <Route exact path="/e/:event_code/pertanyaansaya">
                <WebinarMyQna />
              </Route>
              <Route exact path="/e/:event_code/screening">
                <WebinarScreening />
              </Route>
              <Route exact path="/e/:event_code/survey">
                <WebinarScreening isSurvey={true} />
              </Route>
              <Route exact path="/e/:event_code/article">
                <WebinarArticle />
              </Route>
              <Route exact path="/e/:event_code/article/:id">
                <DetailWebinarArticle />
              </Route>
              <Route exact path="/e/:event_code/payment-package">
                <PaymentPackage />
              </Route>
              <Route exact path="/payment/:transaction_id">
                <Payment />
              </Route>
              <Route exact path="/certificate/:schedule_id/:token/:event_code">
                <Certificate />
              </Route>
              <Route exact path="/event-testimonials/:eventCode/:scheduleId">
                <EventTestimonials />
              </Route>
              <Route exact path="/new-home-page">
                <NewHomePage />
              </Route>
              <Route exact path="/landing-page">
                <LandingPage />
              </Route>
              <Route exact path="/tanya-ahli-homepage">
                <TanyaAhliHomePage />
              </Route>
              <Route exact path="/:id">
                <MitraDetail />
              </Route>
              <Route exact path="/e/:event_code/video/:videoId">
                <DetailVideoMaterial />
              </Route>
              <Route exact path="/e/:event_code/material/video/:parentId">
                <VideoMaterial />
              </Route>
              <Route exact path="/e/:event_code/material/video">
                <VideoMaterial />
              </Route>
              <Route exact path="/e/:event_code/material/modul">
                <ModulMaterial />
              </Route>
              <Route exact path="/e/:event_code/material/modul/:parentId">
                <ModulMaterial />
              </Route>
            </Switch>
          </Suspense>
        </Router>
      );
    } else {
      return <Loading />;
    }
  }
}

export default App;
