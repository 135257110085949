import React from "react";

function DescriptionsSuccess() {
  return (
    <React.Fragment>
      <div style={{fontFamily:"Rubik", fontWeight: 500, fontSize: "22px", lineHeight: "26px", display: "flex"}}>
                  <div style={{marginRight: "12px"}}>
                    <img src={'/assets/images/hiber1-success-icon.svg'} />
                  </div>
                  <div style={{fontSize: "16px"}}>
                  Survey successfully completed! Please share this survey link to other parents
                  </div>
                </div>
                <div style={{marginTop: "24px", fontFamily:"Rubik", fontWeight: 400, fontSize: "14px", lineHeight: "24px"}}>
                  Thank you for taking the time to fill out our survey about parenting questions and issues related to children's behavior. But hold on, don't keep this fantastic experience to yourself. Let's spread the word and invite other super-parents to participate in this survey. 
                  <br/><br/>
                  The more the merrier, right? Because together, we can create an incredible platform that will provide even more help to parents like us. With our collective efforts, let's make the journey of parenting smoother, easier, and more fulfilling than ever before. We can't thank you enough for your amazing support!
                </div>
                <div style={{marginTop: "32px"}}>
                  <img src={'/assets/images/malaysia-cover.png'} style={{width:"100%"}}/>
                </div>
                <div style={{ fontFamily:"Rubik", fontWeight: 500, fontSize: "14px", lineHeight: "16.59px", color:"#6C60E0", background: "#F5F0FF", borderRadius: "12px", padding: "16px", textAlign: "center", margin: "32px 0"}}>
                COMING SOON<br/>
                We will contact you via email as soon as our website is launched. Stay tuned for updates on how we can support your parenting journey!
                </div>
                <div style={{height: "100px"}}>

                </div>
    </React.Fragment>
  );
}

export default DescriptionsSuccess;
