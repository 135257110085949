import React from "react";
import "./PopupEndQna.css";
import CloseIcon from "./assets/close.svg";
import CloseMob from "./assets/close-mob.svg";
import { withRouter } from "react-router-dom";

function PopupEndQna(props) {
  const { onClose, onLangganans } = props;
  return (
    <div id="end-credits" className="parent-voice-over">
      <div className="box-popups">
        <div className="image-popups">
          <div className="parent-closeM">
            <img onClick={onClose} src={CloseMob} alt="close-mob" />
          </div>
        </div>
        <div className="content-popups">
          <div className="close-popups">
            <img onClick={onClose} src={CloseIcon} alt="close-icon" />
          </div>
          <div className="main-popups">
            <div className="parent-item">
              <div className="item-one">
                <div className="head-one">
                  <h1 id="titlenyo">Fammi Gratis</h1>
                  <div className="active-package">
                    <p>Paket Saat Ini</p>
                  </div>
                </div>
                <div className="descone">
                  <div>
                    <img src="/assets/images/checked.svg" alt="checked" />
                    <p>Kirim 4 kali pertanyaan ke narasumber</p>
                  </div>
                  <div>
                    <img src="/assets/images/checked.svg" alt="checked" />
                    <p>Dengarkan 2 audio jawaban per hari</p>
                  </div>
                </div>
              </div>
              <div className="item-two">
                <div className="head-two">
                  <h1 id="titlenyo">Fammi Premium</h1>
                </div>
                <div className="descone">
                  <div>
                    <img
                      src="/assets/images/checked-colored.svg"
                      alt="checked"
                    />
                    <p>Kirim pertanyaan tanpa batas kuota</p>
                  </div>
                  <div>
                    <img
                      src="/assets/images/checked-colored.svg"
                      alt="checked"
                    />
                    <p>Dengarkan audio jawaban tanpa batas</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="options-popup">
              <div>
                <button onClick={onLangganans} className="langganan">
                  <p className="desktops">Langganan Sekarang</p>
                  <p className="mobiles">Langganan</p>
                </button>
                <button onClick={onClose} className="facebook">
                  <p>Nanti Saja</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(PopupEndQna);
