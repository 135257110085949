import React from "react";
import { withRouter } from "react-router";
import "./PopupOver.css";

function PopupOver(props) {
  const { audioPlayer, berlangganan } = props;
  const token = localStorage.getItem("token");
  return (
    <React.Fragment>
      <div
        onClick={props.click}
        className="parent-voice-over"
        style={{ alignItems: berlangganan && "center" }}
      >
        {audioPlayer && (
          <div id="box-over" className="box-voice-over">
            <div className="header-voice-over" />
            <div className="parent-content-over">
              <p className="text-title-over">
                Yuk dengarkan lebih lanjut dengan berlangganan Fammi Original
                Series
              </p>
              <p className="text-description-over">
                Dapatkan potongan harga khusus untuk Anda di hari ini dengan
                mendaftar di Fammi
              </p>
              <div className="parent-btn-over">
                {!token ? (
                  <>
                    <button
                      onClick={() =>
                        props.history.push("/masuk-member")
                      }
                      className="btn-masuk-over"
                    >
                      <p>Masuk</p>
                    </button>
                    <button
                      onClick={() =>
                        props.history.push("/daftar-member")
                      }
                      className="btn-daftar-over"
                    >
                      <p>Daftar</p>
                    </button>
                  </>
                ) : (
                  <button
                    style={{ width: "100%" }}
                    onClick={() => props.history.push("/subscription")}
                    className="btn-daftar-over"
                  >
                    <p>Berlangganan</p>
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {berlangganan && (
          <div
            id="box-over"
            className="box-voice-over"
            style={{
              background: "transparent",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/assets/images/popup-berlangganan.svg"
              alt="Popup berlangganan"
            />
            <button
              onClick={props.click}
              className="btn-daftar-over"
              style={{ background: "#F7AA36", marginTop: 24, width: 204 }}
            >
              <p>Tutup</p>
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
export default withRouter(PopupOver);
