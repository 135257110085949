import React from "react";
import "./PopupNotLogin.css";
import CloseIcon from "./assets/close.svg";
import CloseMob from "./assets/close-mob.svg";
import { withRouter } from "react-router-dom";
import { handleGoogleAuth, handleFacebookAuth } from "../../Container/Login";

function PopupNotLogin(props) {
  const { onClose } = props;
  return (
    <div id="logins" className="parent-voice-over">
      <div className="box-popups">
        <div className="image-popups">
          <div className="parent-closeM">
            <img onClick={onClose} src={CloseMob} alt="close-mob" />
          </div>
        </div>
        <div className="content-popups">
          <div className="close-popups">
            <img onClick={onClose} src={CloseIcon} alt="close-icon" />
          </div>
          <div className="main-popups">
            <h1>Daftar Fammi Gratis</h1>
            <div className="descone">
              <div>
                <img src="/assets/images/checked.svg" alt="checked" />
                <p>Kirim 4 kali pertanyaan ke narasumber</p>
              </div>
              <div>
                <img src="/assets/images/checked.svg" alt="checked" />
                <p>Dengarkan 2 audio jawaban per hari</p>
              </div>
            </div>
            <div className="options-popups">
              <div>
                <button
                  className="google"
                  onClick={async () => {
                    const theGoogle = await handleGoogleAuth();
                    window.location.reload(true);
                  }}
                >
                  <img src="/assets/images/google.svg" alt="google" />
                  <p>Google</p>
                </button>
                <button className="facebook">
                  <img src="/assets/images/facebook.svg" alt="google" />
                  <p>Facebook</p>
                </button>
              </div>
              <button
                onClick={() => {
                  props.history.push("/login");
                }}
              >
                Sudah Punya Akun? Masuk
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(PopupNotLogin);
