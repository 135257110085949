import React from "react";

export const GameSection = ({ t }) => {
  return (
    <div style={{ background: "#FFF" }}>
      <div
        className="game-section flex justify-between mo-flex-col"
        style={{ maxWidth: "1200px", margin: "auto" }}
      >
        <img
          src="/assets/new-home/game-vector.png"
          alt="game-vector"
          className="mo-mb-6"
        />
        <div className="w-50-percent mo-w-full game-desc">
          <h1>{t("access-educational")}</h1>
          <img
            src="/assets/new-home/titik-pintar.png"
            alt="titik-pintar"
            className="mt-9 mb-9 img-game mo-mt-6 mo-mb-6"
          />
          <div className="flex items-center">
            <div className="indicator-border mo-none" />
            <p>{t("motivate-children")}</p>
          </div>
          <button
            className="bg-main rounded border-none text-white font-bold text-center btn-look mt-13 mo-w-full mo-mt-6 reverse"
            onClick={() => {
              window.open("https://titikpintar.id/", "_blank");
            }}
          >
            {t("view-more")}
          </button>
        </div>
      </div>
    </div>
  );
};
