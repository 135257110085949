import axios from "axios";

export const logAdvertising = async (type, mode, adsId) => {
  const token = localStorage.getItem("token");
  let placement = window.location.pathname.slice(
    1,
    window.location.pathname.length
  );

  let payload = {
    adsId: adsId,
    type: mode ? mode : type,
    eventType: "loaded",
  };

  if (token !== "" || token !== null) {
    payload = {
      memberId: token,
      ...payload
    };
  }

  if (!adsId) {
    return;
  }

  await axios
    .post(process.env.REACT_APP_FETCH_URL + "/advertising_log", payload)
    .then(async (res) => {
      // if (res?.data) {
        // console.log("log =>", res);
      // }
    });
};