import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { PremiumDialog } from "./PremiumDialog/PremiumDialog";
import { ProfileMenu } from "./ProfileMenu/ProfileMenu";

function DesktopRight(props) {
  const {
    activeTabs,
    token,
    setOpenLogin,
    eventCode,
    leftChildren,
    rightChildren,
    isSchedules,
    customTitles,
    goBackAction,
    eventData,
    gotoTypeForm,
    expired,
    profileName,
    profileEmail,
    memberType,
    setExpired,
    activePackages
  } = props;
  const enableScreening = eventData?.isScreening === "true";
  const [premiumDialog, setPremiumDialog] = useState(false);
  const { t } = useTranslation();

  const MENUTABS = [
    {
      id: 0,
      title: t("home"),
      name: "homes",
      path: "/",
    },
    {
      id: 1,
      title: t("ask-expert"),
      name: "qnas",
      path: "/qna",
    },
    {
      id: 2,
      title: eventData?.isAudioMaterial === "true" ? "Podcast" : t("record"),
      name: "podcast",
      path: eventData?.isAudioMaterial === "true" ? "/audio" : "/record",
    },
    {
      id: 3,
      title: t("practices"),
      name: "praktek",
      path: "/programs",
    },
    // {
    //   id: 4,
    //   title: "Jadwal",
    //   name: "schedules",
    //   path: "/schedule",
    // },
  ];
  return (
    <React.Fragment>
      <div className="two" style={{ height: "auto", minHeight: "100%" }}>
        <div className="app-bars">
          <div className="content-appbars">
            <img
              src="https://fammi.ly/images/fammi-logo.svg"
              alt="logo"
              className="the-logos"
            />
            <div className="sectiones">
              <div className="parent-tabs">
                {MENUTABS?.map((item, index) => (
                  <div
                    className="item-tabs"
                    key={index}
                    onClick={async () => {
                      if (token && token !== null) {
                        // if (item.id === 3) {
                          // if (expired) return setPremiumDialog(true);
                        // }

                        props.history.push(`/e/${eventCode + item.path}`);
                        return;
                      }
                      setOpenLogin(true);
                    }}
                  >
                    <div className="head-tabs">
                      <img
                        src={`/assets/images/e_${item?.name}${
                          index === activeTabs ? "_on" : "_off"
                        }.svg`}
                        alt={item.name}
                      />
                      <p style={{ color: index === activeTabs && "#6C60E0" }}>
                        {item.title}
                      </p>
                    </div>
                    <div
                      className={`${
                        index === activeTabs ? "active-" : ""
                      }tab-indicator`}
                    />
                  </div>
                ))}
              </div>
            </div>
            <ProfileMenu
              eventCode={eventCode}
              eventData={eventData}
              name={profileName}
              email={profileEmail}
              memberType={memberType}
              setExpired={setExpired}
              expired={expired}
              setPremiumDialog={setPremiumDialog}
              activePackages={activePackages}
            />
          </div>
        </div>

        {isSchedules && (
          <div className="esch_titles">
            <button
              onClick={() => {
                if (goBackAction) {
                  goBackAction();
                  return;
                }
                props.history.push(`/e/${eventCode}/`);
              }}
            >
              <img src="/assets/images/e_backs.svg" alt="backs" />
              <p>Kembali</p>
            </button>
            <div>
              <p>{customTitles}</p>
            </div>
          </div>
        )}
        {rightChildren && (
          <div className="sectioner">
            <div
              className="two-left"
              style={{ height: "auto", overflow: "initial" }}
            >
              {leftChildren}
            </div>
            <div className="two-right">{rightChildren}</div>
          </div>
        )}

        {!rightChildren && (
          <div className="sectioner">
            <div className="two-left" style={{ width: "100%" }}>
              {leftChildren}
            </div>
          </div>
        )}
      </div>
      {premiumDialog && (
        <PremiumDialog
          endDate={eventData?.endDate}
          onClose={() => setPremiumDialog(false)}
          history={props.history}
          eventCode={eventCode}
        />
      )}
    </React.Fragment>
  );
}

export default withRouter(DesktopRight);
