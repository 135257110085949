import React from "react";
import { withRouter } from "react-router-dom";
import Carousels from "../Carousels";
import CardPlaylist from "./CardPlaylist";
import "./Playlist.css";

function Playlist(props) {
  const { data, isLogin, setOpenLogin } = props;
  return (
    <div className="container-topepisode">
      <Carousels
        numberOfShow={6}
        showDots
        title="Kumpulan Pertanyaan"
        path="/episode/homeplaylist"
      >
        {data?.map((item, index) => (
          <CardPlaylist
            push={() => {
              if (!isLogin) {
                setOpenLogin(true);
                return;
              }
              props.history.push("/playlist/" + item?.playlistId);
            }}
            data={item}
            key={index}
          />
        ))}
      </Carousels>
    </div>
  );
}

export default withRouter(Playlist);
